import { DialogNotification as OmniDialogNotification } from '@omni/dialog';
import React from 'react';

interface Props {
  open: boolean;
  messages: string[];
  buttonAction: () => void;
  buttonText: string;
  buttonIcon: string;
}

const DialogNotification = ({
  open,
  messages,
  buttonAction,
  buttonText,
  buttonIcon,
}: Props) => (
  <OmniDialogNotification
    open={open}
    messages={messages}
    buttonAction={buttonAction}
    buttonText={buttonText}
    buttonIcon={buttonIcon}
    dialogStyle={{
      width: '80%',
      maxHeight: '16em',
      maxWidth: '31.25em',
    }}
    overlayStyle={{ background: '#494949', opacity: '0.85' }}
  />
);

export default DialogNotification;
