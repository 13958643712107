import { useEffect, useCallback } from 'react';

export const useRefresh = (search: string) => {
  const handleRefresh = useCallback(
    (event: any) => {
      // This query param is to supress unload popup in certain contexts.
      // For example, reloading the page on device error
      if (search.includes('?sredirect')) {
        return false;
      }
      event.returnValue = '';
      return event;
    },
    [search],
  );
  useEffect(() => {
    window.addEventListener('beforeunload', handleRefresh);
    return () => {
      window.removeEventListener('beforeunload', handleRefresh);
    };
  }, [handleRefresh, search]);
};
