export const CX_HEADER = 'Need manual verification?';

export const CX_DESCRIPTION = `
  Please contact your company administrator or your ANZ representative 
  for assistance.
`;

export const CX_RESOURCES_HEADER = 'Helpful Resources';

export const CX_RESOURCES_LINKS = [
  {
    linkText: 'Online Resources',
    url: 'https://www.anz.com/onlineresources',
    dataTestId: 'onlineResourcesLink',
  },
  {
    linkText: 'Contact us',
    url: 'https://www.anz.com/servicecentres',
    dataTestId: 'contactUsLink',
  },
];
