/* eslint-disable react/jsx-filename-extension */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import styles from './styles.module.css';

const Step = ({ active, completed, stepNumber, text, ariaLabel }) => {
  const stepClass = classNames({
    [styles['steps__step--is-current']]: active,
    [styles['steps__step--is-complete']]: completed,
  });

  return (
    <li className={`${styles.steps__step} ${stepClass}`}>
      <div className={`${styles.steps__num}`} aria-hidden="true">
        {completed ? (
          <i className="fa fa-check" aria-hidden="true" />
        ) : (
          stepNumber
        )}
      </div>
      <div aria-label={ariaLabel} className={`${styles.steps__text}`}>
        <span className={`${styles.steps__hidden}`}>
          {active && 'Current'}
          {completed && 'Completed'}
        </span>
        {text}
      </div>
    </li>
  );
};

Step.propTypes = {
  /** text of the step  */
  text: PropTypes.string.isRequired,
  /** Step is active or not  */
  active: PropTypes.bool,
  /** Step is completed or not  */
  completed: PropTypes.bool,
  /** Step number  */
  stepNumber: PropTypes.number,
  /** aria label */
  ariaLabel: PropTypes.string,
};

Step.defaultProps = {
  active: false,
  completed: false,
  stepNumber: 1,
  ariaLabel: '',
};

export default memo(Step);
