import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { defaultError, ErrorPayload, initialError } from '../../types/errors';
import { isClientErrorStatus } from '../../utils/helper';

export interface IdentityPayload {
  firstName?: string;
  ekycDocuments?: EkycDocument[];
  error: ErrorPayload;
}

export interface EkycDocument {
  name: string;
  type?: string[];
  subSelectionsTitle: string;
  subSelections?: SubSelection[];
}

export interface SubSelection {
  code: string;
  name: string;
  type: string[];
}

const initialState: IdentityPayload = { error: initialError };

export const user = createAsyncThunk<any, void, { rejectValue: any }>(
  'identity/user',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`v1/user`);
      return {
        data: response.data,
        expirationTime: parseInt(response.headers['expiration-time'], 10),
      };
    } catch (error) {
      if (
        error instanceof AxiosError &&
        isClientErrorStatus(error?.response?.status)
      ) {
        return rejectWithValue(error?.response?.data);
      }
      return rejectWithValue(defaultError);
    }
  },
);
const identityUploadSlice = createSlice({
  name: 'identityUpload',
  initialState,
  reducers: {
    resetCountryInstructionsState: (state) => ({
      ...state,
      ekycDocuments: initialState.ekycDocuments,
      error: initialState.error,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(user.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.firstName = payload.data.firstName;
        state.ekycDocuments = payload.data.ekycDocuments;
      })
      .addCase(user.rejected, (state, action: any) => {
        state.error = action.payload;
      });
  },
});

export const { resetCountryInstructionsState } = identityUploadSlice.actions;
export default identityUploadSlice.reducer;
