/* eslint-disable import/no-webpack-loader-syntax */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/extensions */
import { DialogConfirmation } from '@omni/dialog';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './Banner.module.css';
import { useAppSelector } from '../../../app/hooks';

/* 
  FIXME -- SVG import fails with normal import syntax due to bug https://github.com/facebook/create-react-app/issues/11770
  We will rely on file-loader till it resolves
*/
import anzLogo from '!file-loader!./images/anzLogo.svg';
import contact from '!file-loader!./images/contact.svg';
import exit from '!file-loader!./images/exit.svg';
import help from '!file-loader!./images/help.svg';

const Banner = () => {
  const navigate = useNavigate();
  const [isExitClicked, setIsExitClicked] = useState(false);
  const isLoggedIn = useAppSelector((state) => state.session.isLoggedIn);
  const handleYesButtonClick = () => {
    setIsExitClicked(false);
    navigate('/', { replace: true });
  };
  const handleNoButtonClick = () => {
    setIsExitClicked(false);
  };
  const handleExit = () => {
    setIsExitClicked(true);
  };
  return (
    <header role="banner" aria-label="E K Y C banner">
      <div className={styles.anz__header}>
        <div className={styles.anz__header__section}>
          <div className={styles.anz__logo__container}>
            <img src={anzLogo} alt="ANZ logo" className={styles.anz__logo} />
          </div>
          <a
            id="eKYCBannerLink"
            href="https://anz.com/ekyc"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.ekyc__text}
            data-testid="eKYCBannerLink"
            aria-label="E K Y C"
          >
            eKYC
          </a>

          <div className={styles.icons__container}>
            <div className={styles.icon__container}>
              <a
                href="https://www.anz.com/institutional/digital-services/ekyc/faq"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="helpLink"
              >
                <img src={help} alt="" className={styles.icon__help} />
                <span className={styles.icon__caption}>Help</span>
              </a>
            </div>
            <div className={styles.icon__container}>
              <a
                href="https://www.anz.com/servicecentres"
                target="_blank"
                rel="noopener noreferrer"
                data-testid="contactLink"
              >
                <img src={contact} alt="" className={styles.icon__support} />
                <span className={styles.icon__caption}>Support</span>
              </a>
            </div>
            {isLoggedIn ? (
              <div className={styles.icon__container}>
                <button
                  type="button"
                  id="exitButton"
                  data-testid="exitButton"
                  onClick={handleExit}
                >
                  <img src={exit} alt="" className={styles.icon__exit} />
                  <span className={styles.icon__caption}>Exit</span>
                </button>
              </div>
            ) : null}
          </div>
        </div>
        <div className={styles.blue__crowbar} />
        <DialogConfirmation
          open={isExitClicked}
          size="normal"
          onConfirm={handleYesButtonClick}
          onCancel={handleNoButtonClick}
          confirmText="Yes"
          cancelText="No"
          messages={[
            'Are you sure you wish to exit identity verification with ANZ?',
            'Your progress will not be saved.',
          ]}
          overlayStyle={{ background: '#494949', opacity: '0.85' }}
          dialogStyle={{ width: '90%', maxWidth: '50em' }}
        />
      </div>
    </header>
  );
};

export default Banner;
