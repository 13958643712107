// Errors relating to Scanning the ID
// Note These error values MUST be consistent with the backend
// service/resource/error_resource.go

export const ERROR_DOC_EXTRACT_INVALID_REQ =
  'error.ekyc.downstream.ocr.imaging.invalid.request';
export const ERROR_DOC_EXTRACT_INVALID_AUTH =
  'error.ekyc.downstream.ocr.imaging.invalid.auth';
export const ERROR_DOC_EXTRACT_PRECONDITION_FAILED =
  'error.ekyc.downstream.ocr.imaging.preCondition.failed';
export const ERROR_DOC_EXTRACT_STATUSEXPECTATION_FAILED =
  'error.ekyc.downstream.ocr.imaging.statusExpectation.failed';
export const ERROR_DOWNSTREAM_INTERNAL_ERROR =
  'error.ekyc.downstream.internalerror';
export const ERROR_DOWNSTREAM_SERVICE_UNAVAILABLE =
  'error.ekyc.downstream.service.unavailable';
export const ERROR_DOC_EXTRACT_MIME_TYPE =
  'error.ekyc.doc.extract.mimetype.invalid';
export const ERROR_DOC_EXTRACT_IMAGE_SIZE = 'error.ekyc.doc.extract.imagesize';
export const ERROR_DOC_EXTRACT_ID_TYPE = 'error.ekyc.doc.extract.type';
export const ERROR_DOC_EXTRACT_ID_BELOW_THRESHOLD =
  'error.ekyc.doc.extract.below.threshold';
export const ERROR_DOC_EXTRACT_CROP_FAILED =
  'error.ekyc.doc.extract.crop.failed';
export const ERROR_DOC_EXTRACT_IMAGEQC_FAILED =
  'error.ekyc.doc.extract.imageqc.failed';
export const ERROR_DOC_EXTRACT_IMAGE_QC_BELOW_THRESHOLD =
  'error.ekyc.doc.extract.imageqc.below.threshold';
export const ERROR_DEFAULT = 'error.ekyc.doc.downstream';

export const API_ERRORS = {
  [ERROR_DEFAULT]:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_DOC_EXTRACT_INVALID_REQ]:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_DOC_EXTRACT_INVALID_AUTH]:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_DOC_EXTRACT_PRECONDITION_FAILED]:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_DOWNSTREAM_INTERNAL_ERROR]:
    'Unable to complete request. Please try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_DOWNSTREAM_SERVICE_UNAVAILABLE]:
    'Unable to complete request. Please try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_DOC_EXTRACT_MIME_TYPE]:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_DOC_EXTRACT_IMAGE_SIZE]:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_DOC_EXTRACT_CROP_FAILED]:
    'Unable to process the identification photo on your document. Please try again.',
  [ERROR_DOC_EXTRACT_ID_BELOW_THRESHOLD]:
    'Unable to capture your document details as it looks like you are using a different document to the one selected. Please try again.',
  [ERROR_DOC_EXTRACT_IMAGE_QC_BELOW_THRESHOLD]:
    'Unable to process the identification photo on your document. Please try again.',
  [ERROR_DOC_EXTRACT_STATUSEXPECTATION_FAILED]:
    'Unable to process the identification photo on your document. Please try again.',
};
