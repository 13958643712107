/* eslint-disable arrow-body-style */
const getSelectStyle = () => ({
  control: (styles, { isDisabled, isFocused }) => {
    return {
      ...styles,
      fontSize: '1.4rem',
      backgroundColor: isDisabled ? '#f5f5f5' : '#fff',
      borderColor: isDisabled ? '#cdcdcd' : isFocused ? '#007dba' : '#cdcdcd',
      boxShadow: isFocused ? `0 0 0 0.142em rgba(1, 125, 186, .25)` : null,
      borderRadius: 2,
      ':hover': {
        ...styles[':hover'],
        borderColor: isFocused ? '#007dba' : '#cdcdcd',
      },
    };
  },
  placeholder: (styles) => ({
    ...styles,
    color: '#a4a4a4',
    fontSize: '1.4rem',
  }),
  menu: (styles) => ({
    ...styles,
    borderRadius: '4px',
    border: '1px solid #cdcdcd',
    borderTop: '0',
    boxShadow: '0px 5px 15px -5px rgba(0, 0, 0, 0.5);',
    marginTop: 1,
    zIndex: 2,
  }),
  menuList: (styles) => ({
    ...styles,
    padding: '0',
    maxHeight: '175px',
    overflow: 'auto',
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      padding: '10px',
      borderBottom: '1px solid #E6E6E6',
      backgroundColor: isFocused ? '#f5f5f5' : isSelected ? '#E6E6E6' : '#fff',
      color: '#484848',
      fontSize: '1.4rem',
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
      },
      ':hover': {
        ...styles[':hover'],
        backgroundColor: isSelected ? '#CDCDCD' : '#f5f5f5',
      },
      ':last-of-type': {
        borderBottom: '0',
        borderRadius: '0 0 4px 4px',
      },
      ':first-of-type': {
        borderTop: '0',
        borderRadius: '4px 4px 0 0',
      },
    };
  },
  input: (styles) => ({
    ...styles,
    '.Select__input input': {
      minHeight: '25px',
      color: '#484848',
      boxShadow: 'none !important',
      borderColor: 'transparent !important',
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '2px 10px',
    minHeight: '33px',
  }),
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? '#A4A4A4' : '#484848',
    fontSize: '1.5rem',
  }),
  multiValue: (styles, { isDisabled }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'transparent' : '#E5F2F8',
    border: '1px solid #B3D8EA',
    borderRadius: '2px',
    margin: '2px 5px 2px 0',
    fontSize: '1.4rem',
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#007dba',
    marginRight: '4px',
    fontSize: '90%',
  }),
  multiValueRemove: (styles, { isDisabled }) => ({
    ...styles,
    color: '#007dba',
    borderLeft: '1px solid #B3D8EA',
    ':hover': {
      ...styles[':hover'],
      backgroundColor: '#CCE5F1',
      color: '#007dba',
      cursor: 'pointer',
    },
    display: isDisabled ? 'none' : 'flex',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    backgroundColor: 'unset',
    marginBottom: 0,
    marginTop: 0,
    borderRight: '1px solid #cdcdcd',
  }),
  clearIndicator: (styles) => ({
    ...styles,
    padding: '0 10px',
    color: '#757575',
    fontSize: '10px !important',
    background: '#fff',
    height: '100%',
    alignItems: 'center',
    ':hover': {
      ...styles[':hover'],
      color: '#757575',
      cursor: 'pointer',
    },
  }),
  dropdownIndicator: (styles, { isDisabled }) => ({
    ...styles,
    padding: '0 10px',
    color: isDisabled ? '#56A7CE' : '#007dba',
    fontSize: '10px',
    backgroundColor: '#f5f5f5',
    height: '100%',
    alignItems: 'center',
    ':hover': {
      color: '#007dba',
    },
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    color: '#a4a4a4',
    textAlign: 'left',
    padding: '8px 10px',
    fontSize: '1.4rem',
  }),
  loadingMessage: (styles) => ({
    ...styles,
    color: '#a4a4a4',
    textAlign: 'left',
    padding: '8px 10px',
    fontSize: '1.4rem',
  }),
});

const getSelectTheme = (theme) => ({
  ...theme,
  borderRadius: 2,
  colors: {
    ...theme.colors,
  },
  spacing: {
    baseUnit: 2,
    controlHeight: 35,
    menuGutter: 0,
  },
});

export { getSelectStyle, getSelectTheme };
