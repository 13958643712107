import { DialogConfirmation } from '@omni/dialog';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  API_ERRORS,
  ERROR_DEFAULT,
  ERROR_DOC_EXTRACT_CROP_FAILED,
  ERROR_DOC_EXTRACT_ID_BELOW_THRESHOLD,
  ERROR_DOC_EXTRACT_IMAGE_QC_BELOW_THRESHOLD,
  ERROR_DOC_EXTRACT_IMAGE_SIZE,
  ERROR_DOC_EXTRACT_MIME_TYPE,
  ERROR_DOC_EXTRACT_INVALID_REQ,
  ERROR_DOC_EXTRACT_INVALID_AUTH,
  ERROR_DOC_EXTRACT_PRECONDITION_FAILED,
  ERROR_DOWNSTREAM_INTERNAL_ERROR,
  ERROR_DOWNSTREAM_SERVICE_UNAVAILABLE,
  ERROR_DOC_EXTRACT_STATUSEXPECTATION_FAILED,
} from './constants';
import styles from './ScanDocument.module.css';
import { updateImage } from './scanDocumentSlice';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { Camera, Context } from '../../../common/components/Camera';
import DialogNotification from '../../../common/components/Dialog/DialogNotification';
import {
  extractDocument,
  resetErrorState,
} from '../VerifyDetailsForm/verifyDetailsFormSlice';

interface Props {
  onContinue: () => void;
  onBackNavigation: () => void;
  onStatusChange: (status: string) => void;
  isInTestMode: boolean;
  isStepperShown: boolean;
}
export const ScanDocument = ({
  onContinue,
  onBackNavigation,
  onStatusChange,
  isInTestMode,
  isStepperShown,
}: Props) => {
  const navigate = useNavigate();
  // e.g documentType = [DL_NSW_FRONT, DL_NSW_BACK]
  const { documentName, documentType, subSelection } = useAppSelector(
    (state) => state.document,
  );
  const error = useAppSelector((state) => state.verifyDetails.error);

  const [scanStep, setScanStep] = useState(0);
  // Workaround, for force re-rendering component by using `key` attribute
  // In future we will need to see how to uplift the Camera component
  // to support re-render
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [forceRenderCount, setForceRenderCount] = useState(2);

  const dispatch = useAppDispatch();

  const handleSubmit = async (image: any) => {
    dispatch(
      updateImage({
        type: documentType[scanStep],
        imageBase64: image,
        countryCode: getCountryCode(),
      }),
    );

    const resultAction = await dispatch(
      extractDocument({
        type: documentType[scanStep],
        imageBase64: image,
        countryCode: getCountryCode(),
      }),
    );

    if (extractDocument.fulfilled.match(resultAction)) {
      if (scanStep < documentType.length - 1) {
        setScanStep(scanStep + 1);
      } else {
        onContinue();
      }
    }
  };

  const handleDialogExit = () => {
    navigate('/');
  };

  const handleRetryExtraction = () => {
    // Remove error dialog
    dispatch(resetErrorState());
    setForceRenderCount((prev) => prev + 10);
  };

  const handleExit = () => {
    navigate('/?sredirect');
    setTimeout(() => window.location.assign('/'), 100);
  };

  const getDocumentTitle = () => {
    if (subSelection) {
      return `${documentName} (${subSelection})`;
    }
    return documentName;
  };

  const getDocumentDescription = () => {
    // For future documents, extend to use a better supporting data structure
    if (documentType[scanStep].includes('FRONT')) {
      return '-Front- of Licence';
    }
    if (documentType[scanStep].includes('BACK')) {
      return '-Back- of Licence';
    }
    return '-Photo page- of Passport';
  };

  const containerClassName = isStepperShown
    ? styles.camera__container_preview
    : styles.camera__container;

  const renderErrorDialog = () => {
    switch (error.code) {
      case ERROR_DOC_EXTRACT_MIME_TYPE:
      case ERROR_DOC_EXTRACT_IMAGE_SIZE:
      case ERROR_DOC_EXTRACT_INVALID_REQ:
      case ERROR_DOC_EXTRACT_INVALID_AUTH:
      case ERROR_DOC_EXTRACT_PRECONDITION_FAILED:
        return (
          <DialogNotification
            open
            messages={[API_ERRORS[error.code]]}
            buttonAction={handleDialogExit}
            buttonText="Exit"
            buttonIcon="fa-check"
          />
        );
      case ERROR_DOC_EXTRACT_ID_BELOW_THRESHOLD:
      case ERROR_DOC_EXTRACT_CROP_FAILED:
      case ERROR_DOC_EXTRACT_IMAGE_QC_BELOW_THRESHOLD:
      case ERROR_DOC_EXTRACT_STATUSEXPECTATION_FAILED:
        return (
          <DialogNotification
            open
            messages={[API_ERRORS[error.code]]}
            buttonAction={handleRetryExtraction}
            buttonText="Try Again"
            buttonIcon="fa-rotate-right"
          />
        );
      case ERROR_DOWNSTREAM_INTERNAL_ERROR:
      case ERROR_DOWNSTREAM_SERVICE_UNAVAILABLE:
        return (
          <DialogConfirmation
            open
            size="normal"
            onConfirm={handleRetryExtraction}
            onCancel={handleDialogExit}
            confirmText="Try Again"
            confirmIcon="fa-rotate-right"
            cancelText="Exit"
            messages={[API_ERRORS[error.code]]}
            overlayStyle={{ background: '#494949', opacity: '0.85' }}
            dialogStyle={{
              width: '80%',
              maxWidth: '31.25em',
              maxHeight: '16em',
            }}
          />
        );
      default:
        return (
          <DialogNotification
            open
            messages={[API_ERRORS[ERROR_DEFAULT]]}
            buttonAction={handleDialogExit}
            buttonText="Exit"
            buttonIcon="fa-check"
          />
        );
    }
  };

  const getCountryCode = () =>
    documentName.indexOf('Australia') !== -1 ? 'AUSTRALIA' : 'NZ';

  return (
    <>
      <Camera
        isActive
        width={1440}
        height={1080}
        context={Context.document}
        documentTitle={getDocumentTitle()}
        documentDescription={getDocumentDescription()}
        selfieTimer={5}
        isDeviceErrorToBeShown
        isErrorToBeShown
        isConstraintsWarningToBeShown={false}
        isOrientationWarningToBeShown={false}
        containerClassName={containerClassName}
        onBackNavigation={onBackNavigation}
        isBackNavigationToBeShown={scanStep === 0}
        onRetake={() => console.log('Recapturing image')}
        onSubmit={handleSubmit}
        onExit={handleExit}
        key={scanStep + forceRenderCount}
        onStatusChange={onStatusChange}
        isInTestMode={isInTestMode}
      />
      {error.code && renderErrorDialog()}
    </>
  );
};

export default ScanDocument;
