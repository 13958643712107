export interface ErrorPayload {
  code: string;
  message: string;
}

export const initialError: ErrorPayload = {
  code: '',
  message: '',
};

export const defaultErrorCode = 'error.ekyc.default';

// 4XX errors will have message returned from backend
// Whilst non 4XX errors will be UI driven

/* Default error for
 /session/start
*/
export const defaultStartSessionError: ErrorPayload = {
  code: 'error.ekyc.session.start',
  message:
    'Unable to complete request. Please try again. If this error persists, please contact ANZ for further assistance.',
};

/* Default error for
 /session/refresh
 /session/clean
 /user
*/
export const defaultError: ErrorPayload = {
  code: defaultErrorCode,
  message:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
};

/* Default error for
  /document/extract
  /document
*/
export const defaultGovtScanError: ErrorPayload = {
  code: defaultErrorCode,
  message:
    'Unable to complete request. Please try again. If this error persists, please contact ANZ for further assistance.',
};

/* Default error for
  /liveness
*/
export const defaultLivenessError: ErrorPayload = {
  code: defaultErrorCode,
  message:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
};
