// Errors relating to liveness
// Note these error values MUST be consistent with the backend
// service/resource/error_resource.go

export const ERROR_LIVENESS_INVALID_REQ =
  'error.ekyc.downstream.ocr.imaging.invalid.request';
export const ERROR_LIVENESS_INVALID_AUTH =
  'error.ekyc.downstream.ocr.imaging.invalid.auth';
export const ERROR_LIVENESS_PRECONDITION_FAILED =
  'error.ekyc.downstream.ocr.imaging.preCondition.failed';
export const ERROR_DOWNSTREAM_INTERNAL_ERROR =
  'error.ekyc.downstream.internalerror';
export const ERROR_DOWNSTREAM_SERVICE_UNAVAILABLE =
  'error.ekyc.downstream.service.unavailable';
export const ERROR_PASSIVELIVENESS_SCORE_BELOW_THRESHOLD =
  'error.ekyc.liveness.passiveliveness.score.below.threshold';
export const ERROR_LIVENESS_IMAGE_QC_BELOW_THRESHOLD =
  'error.ekyc.liveness.imageqc.below.threshold';
export const ERROR_LIVENESS_IMAGEQC_FAILED =
  'error.ekyc.liveness.image.validate.failed';
export const ERROR_BIOMETRICMATCH_MORE_ATTEMPTS =
  'error.ekyc.liveness.biometricmatch.more.attempts';
export const ERROR_COBRA_PROFILE_USER_STATUS_INVALID =
  'error.ekyc.cobra.profile.user.status.invalid';
export const ERROR_COBRA_PROFILE_EKYC_REFERENCE_INVALID =
  'error.ekyc.cobra.profile.reference.invalid';
export const ERROR_DOWNSTREAM_FRANKIEONE_RETRYABLE =
  'error.ekyc.frankie.one.downstream.retryable';
export const ERROR_DOWNSTREAM_FRANKIEONE_UNRETRYABLE =
  'error.ekyc.frankie.one.downstream.unretryable';
export const ERROR_DOCUMENT_VERIFICATION_NZ_NOT_AVAILABLE =
  'error.document.verification.nz.not.available';

export const ERROR_DEFAULT = 'error.ekyc.doc.downstream';

export const API_ERRORS = {
  [ERROR_DEFAULT]:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_LIVENESS_INVALID_REQ]:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_LIVENESS_INVALID_AUTH]:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_LIVENESS_PRECONDITION_FAILED]:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_DOWNSTREAM_INTERNAL_ERROR]:
    'Unable to complete request. Please try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_DOWNSTREAM_SERVICE_UNAVAILABLE]:
    'Unable to complete request. Please try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_LIVENESS_IMAGE_QC_BELOW_THRESHOLD]: `Unable to process photo. Please try again. Please don't have anything covering your face and ensure you have adequate lighting.`,
  [ERROR_BIOMETRICMATCH_MORE_ATTEMPTS]:
    'Unable to match your photo with the identification photo on your document. Please try again.',
  [ERROR_PASSIVELIVENESS_SCORE_BELOW_THRESHOLD]: `Unable to process photo. Please try again. Please don't have anything covering your face and ensure you have adequate lighting.`,
  [ERROR_LIVENESS_IMAGEQC_FAILED]:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_COBRA_PROFILE_USER_STATUS_INVALID]:
    'Your request cannot be processed. Please contact ANZ for further assistance.',
  [ERROR_COBRA_PROFILE_EKYC_REFERENCE_INVALID]:
    'Your request cannot be processed. Please contact ANZ for further assistance.',
  [ERROR_DOWNSTREAM_FRANKIEONE_RETRYABLE]:
    'Unable to complete request. Please try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_DOWNSTREAM_FRANKIEONE_UNRETRYABLE]:
    'Unable to complete request. Please exit and try again. If this error persists, please contact ANZ for further assistance.',
  [ERROR_DOCUMENT_VERIFICATION_NZ_NOT_AVAILABLE]:
    'Document verification is currently unavailable for New Zealand eKYC requests.',
};
