/* eslint-disable no-return-assign */
import { ButtonBar } from '@omni/button';
import { DialogConfirmation } from '@omni/dialog';
import { Checkbox, CheckboxField } from '@omni/form';
import { FormValidator } from '@omni/validator';
import { isEmpty, mapValues } from 'lodash';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  CUSTOMER_DETAILS_INFO,
  CUSTOMER_AGREEMENT,
  GENDER_LIST,
  OCCUPATION_LIST,
  COUNTRY_LIST,
  RESIDENTIAL_COUNTRY_LIST,
  CUSTOMER_NAME_CHECK,
  DLCN_IMAGE_DETAILS,
  ALERT_NOTIFICATION_1,
  ALERT_NOTIFICATION_2,
  RESULT_NOTIFICATION,
  OCV_CONFIDENCE_TOO_MANY_MATCHES,
  OCV_CONFIDENCE_NO_MATCHES,
  RESIDENTIAL_COUNTRY_LIST_AU_ONLY,
} from './constants';
import styles from './VerifyDetailsForm.module.css';
import {
  documentSubmit,
  resetErrorState,
  resetOcrState,
} from './verifyDetailsFormSlice';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import warningAlert from '../../../common/components/Banner/images/warningAlert.svg';
import { DateInput } from '../../../common/components/DatePicker';
import { DialogNotification, Dialog } from '../../../common/components/Dialog';
import { InputField } from '../../../common/components/InputField';
import { AsyncAutoCompleteField } from '../../../common/components/Omni/autocomplete';
import { Button } from '../../../common/components/Omni/Button';
import { SelectField } from '../../../common/components/Omni/Form';
import {
  resetSessionDialogState,
  refreshSession,
} from '../../../common/components/Session/sessionSlice';
import { defaultErrorCode } from '../../../types/errors';
import { validateOnScroll } from '../../../utils/helper';
import { patterns } from '../../../utils/validations/patterns';
import {
  validateAge,
  validateDateExpiry,
  validateDate,
  validateLicenceCardNumberMandatory,
  validateMultiStringInput,
  validateVerifyDetailsMandatory,
  validateMiddleNameCheck,
  validateDateMandatory,
} from '../../../utils/validations/validations';
import { ocvAddressList } from '../../IdentityUpload/VerifyDetailsForm/verifyDetailsFormSlice';
import { resetScanState } from '../ScanDocument/scanDocumentSlice';

interface Props {
  onBackNavigation: () => void;
  validatorContext: any;
}

interface InputRefs {
  firstName?: any;
  lastName?: any;
  residentialAddress?: any;
  residentialCountry?: any;
  documentNumber?: any;
  documentExpiry?: any;
  licenceCardNumber?: any;
  gender?: any;
  nationality?: any;
  occupation?: any;
}
type ImageDetails = {
  [key: string]: {
    url: string;
    header: string;
    description: string;
  };
};

const VerifyDetailsForm = ({ onBackNavigation, validatorContext }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const scan = useAppSelector((state) => state.scan);
  const error = useAppSelector((state) => state.verifyDetails.error);
  const {
    type,
    firstName,
    middleName,
    lastName,
    dateOfBirth,
    residentialAddress,
    residentialCountry,
    documentNumber,
    documentExpiry,
    licenceCardNumber,
    licenceVersionNumber,
    gender,
    nationality,
    documentIssuedPlace,
  } = useAppSelector((state) => state.verifyDetails);
  const { documentName } = useAppSelector((state) => state.document);
  const { ekycJurisdictions } = useAppSelector((state) => state.session);

  const [inputRefs, setInputRefs] = useState<InputRefs>({});
  const [isValidForm, setIsValidForm] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isBackClicked, setIsBackClicked] = useState(false);
  const [isNameChecked, setIsNameChecked] = useState(false);
  const [isManualAddressClicked, setIsManualAddressClicked] = useState(false);
  const [isAddressSearched, setIsAddressSearched] = useState(false);
  const [isDLCNClicked, setIsDLCNClicked] = useState(false);
  const isOCVError = useAppSelector((state) => state.verifyDetails.ocvError);
  const isProcessing = useAppSelector((state) => state.session.loading);
  const ocvConfidence = useAppSelector(
    (state) => state.verifyDetails.ocvConfidence,
  );
  const isSessionDialog = useAppSelector(
    (state) => state.session.isSessionDialogOpen,
  );
  const initialForm = {
    type,
    firstName,
    middleName,
    lastName,
    residentialAddress: residentialAddress
      ? {
          label: residentialAddress,
        }
      : null,
    dateOfBirth,
    birthDay: '',
    birthMonth: '',
    birthYear: '',
    documentNumber,
    documentExpiry,
    docExpDay: '',
    docExpMonth: '',
    docExpYear: '',
    licenceCardNumber,
    gender,
    nationality,
    residentialCountry,
    documentIssuedPlace,
    licenceVersionNumber,
    // Non OCR extract fields
    occupation: '',
  };

  const [form, setForm] = useState<{ [key: string]: any }>(initialForm);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(
    () => () => {
      dispatch(resetOcrState());
    },
    [],
  );
  useEffect(() => {
    if (dateOfBirth) {
      const [year, month, day] = dateOfBirth.split('-');
      setForm((prevForm) => ({
        ...prevForm,
        birthYear: year,
        birthMonth: month,
        birthDay: day,
      }));
    }
  }, [dateOfBirth]);

  useLayoutEffect(() => {
    const { birthDay, birthMonth, birthYear } = form;
    if (birthYear && birthMonth && birthDay) {
      const updatedDateOfBirth = `${birthYear}-${birthMonth}-${birthDay}`;
      setForm((prevForm) => ({
        ...prevForm,
        dateOfBirth: updatedDateOfBirth,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.birthYear, form.birthMonth, form.birthDay]);
  useEffect(() => {
    if (documentExpiry) {
      const [year, month, day] = documentExpiry.split('-');
      setForm((prevForm) => ({
        ...prevForm,
        docExpYear: year,
        docExpMonth: month,
        docExpDay: day,
      }));
    }
  }, [documentExpiry]);

  useLayoutEffect(() => {
    const { docExpDay, docExpMonth, docExpYear } = form;
    if (docExpYear && docExpMonth && docExpDay) {
      const updatedDocumentExpiry = `${docExpYear}-${docExpMonth}-${docExpDay}`;
      setForm((prevForm) => ({
        ...prevForm,
        documentExpiry: updatedDocumentExpiry,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.docExpYear, form.docExpMonth, form.docExpDay]);

  const handleChange = (name: string, value: string) => {
    if (
      (name === 'documentNumber' && !patterns.alphaNumeric.test(value)) ||
      (name === 'licenceCardNumber' && !patterns.alphaNumeric.test(value))
    ) {
      return;
    }
    if (
      (name === 'birthDay' && !patterns.digit.test(value)) ||
      (name === 'birthYear' && !patterns.digit.test(value)) ||
      (name === 'docExpDay' && !patterns.digit.test(value)) ||
      (name === 'docExpYear' && !patterns.digit.test(value))
    ) {
      return;
    }
    if (name === 'licenceVersionNumber' && !patterns.digit.test(value)) {
      return;
    }

    setForm({ ...form, ...{ [name]: value } });
  };

  const handleChecked = () => {
    setIsChecked(!isChecked);
  };

  const handleNameChecked = () => {
    setIsNameChecked(!isNameChecked);
    form.middleName = '';
  };

  const handleYesButtonClick = () => {
    dispatch(resetScanState());
    dispatch(refreshSession());
    onBackNavigation();
  };
  const handleNoButtonClick = () => {
    setIsBackClicked(false);
  };

  const handleBackButtonClick = () => {
    setIsBackClicked(true);
  };

  const handleExit = () => {
    navigate('/', { replace: true });
  };

  const trimAndCleanForm = (keysToRemove: string[]) => {
    const updatedForm = { ...form };
    if (updatedForm.residentialAddress) {
      updatedForm.residentialAddress = updatedForm.residentialAddress.label;
    } else {
      updatedForm.residentialAddress = '';
    }

    keysToRemove.forEach((key: any) => {
      delete updatedForm[key];
    });

    const trimmedAndCleanedForm = mapValues(updatedForm, (field) => {
      if (typeof field === 'string') {
        const trimmedValue = field.trim();
        return trimmedValue === '' ? undefined : trimmedValue;
      }
      return field;
    });
    return trimmedAndCleanedForm;
  };

  const submitForm = async (formToSubmit: any) => {
    const resultAction = await dispatch(
      documentSubmit({ details: { ...formToSubmit }, ...scan }),
    );
    if (documentSubmit.fulfilled.match(resultAction)) {
      navigate('/selfie', { replace: true });
    }
  };

  const handleRetry = async () => {
    dispatch(resetErrorState());
    const formToSubmit = trimAndCleanForm([]);
    submitForm(formToSubmit);
  };
  const handleBlur = () => {
    setIsAddressSearched(false);
  };

  useEffect(() => {
    const { fieldValidators, clientErrors = {} } = validatorContext;
    validateOnScroll(inputRefs, fieldValidators, clientErrors, {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidForm, validatorContext]);

  const handleConfirm = async () => {
    const keysToRemove = [
      'birthDay',
      'birthMonth',
      'birthYear',
      'docExpDay',
      'docExpMonth',
      'docExpYear',
    ];
    const formToSubmit = trimAndCleanForm(keysToRemove);
    if (!validateForm(formToSubmit)) {
      setIsValidForm(!isValidForm);
    } else {
      submitForm(formToSubmit);
    }
  };

  const validateForm = (formToSubmit: any) => {
    const { validate } = validatorContext;
    return validate(formToSubmit);
  };

  const getDocValue = (documentType: string): string => {
    let licenceState;
    if (documentType.includes('DL')) {
      [, licenceState] = documentType.split('_');
    }
    const docValue =
      documentType === 'PASSPORT' || documentName.indexOf('New Zealand') !== -1
        ? documentName
        : `${documentName} (${licenceState})`;
    return docValue;
  };
  const onManualAddressClick = async () => {
    setIsManualAddressClicked(true);
    form.residentialAddress = null;
  };

  const onSearchAddressLinkClick = async () => {
    setIsManualAddressClicked(false);
    setIsAddressSearched(false);
    form.residentialAddress1 = '';
    form.residentialAddress2 = '';
    form.city = '';
    form.state = '';
    form.postcode = '';
  };

  const onFindDLCNClick = () => {
    dispatch(resetSessionDialogState());
    setIsDLCNClicked(true);
  };
  const handleDLCNClose = () => {
    setIsDLCNClicked(false);
  };

  const getImagesByDocType = (
    documentType: string,
    imageDetailsMap: ImageDetails,
  ): object => {
    let licenceState: string;
    if (documentType.includes('DL')) {
      [, licenceState] = documentType.split('_');
    }
    const matchedImages: {
      [key: string]: { url: string; header: string; description: string };
    } = {};
    Object.keys(imageDetailsMap).forEach((key) => {
      if (key.includes(licenceState)) {
        matchedImages[key] = imageDetailsMap[key];
      }
    });
    return matchedImages;
  };
  const DLCNImages = getImagesByDocType(type, DLCN_IMAGE_DETAILS);
  const countryIsoMap: { [key: string]: string } = {
    AU: 'AUS',
    NZ: 'NZL',
  };
  const loadOptions: (address: string) => Promise<any[]> = async (address) => {
    setIsAddressSearched(address.length > 3);

    // call api only when user enters min 4 alphanumeric chars having atleast 3 letters
    if (patterns.containsMin4AlphaNumeric.test(address)) {
      try {
        // Dispatch the async action
        const resultAction = await dispatch(
          ocvAddressList({
            address,
            countryIso: countryIsoMap[form.residentialCountry],
          }),
        );

        // Check if the action was fulfilled
        if (ocvAddressList.fulfilled.match(resultAction)) {
          // Extract the list of options from the payload
          const list = resultAction.payload.data.addresses || [];
          return list.map((index: any, addressValue: any) => ({
            label: index,
            value: addressValue,
          }));
        }
        return [];
      } catch (loadError) {
        // Handle errors here
        console.error(loadError);
      }
    }
    return [];
  };
  const getMaxFieldLength = () => {
    switch (documentName) {
      case 'Australian Passport':
        return 9;
      case `Australian Driver's Licence`:
        return 10;
      case 'New Zealand Passport':
      case `New Zealand Driver's Licence`:
        return 8;
      default:
        return 0;
    }
  };

  return (
    <>
      {!isValidForm && (
        <div
          className={styles.visually__hidden}
          aria-hidden="false"
          aria-label="Form has errors, fill required mandatory inputs correctly to proceed further"
          role="alert"
          aria-live="assertive"
        />
      )}
      {isProcessing && (
        <div
          className={styles.visually__hidden}
          aria-hidden="false"
          aria-label="Application is currently interacting with downstream and processing request"
          role="alert"
          aria-live="assertive"
        />
      )}
      <div className={styles.container}>
        <h2 className={styles.header}>Customer Details:</h2>
        <div className={styles.tip}>
          <span>{CUSTOMER_DETAILS_INFO}</span>
        </div>
        <br />
        <div className={styles.form__container}>
          <InputField
            id="firstName"
            data-testid="First Name"
            label="*First Name"
            maxLength={60}
            value={form.firstName}
            onChange={handleChange}
            validate={[
              validateVerifyDetailsMandatory(form.firstName, 'First Name'),
              validateMultiStringInput(form.firstName, 'First Name'),
            ]}
            setRef={(ref: any) => {
              setInputRefs((prev) => ({ ...prev, ...{ firstName: ref } }));
            }}
          />
          <InputField
            id="middleName"
            data-testid="Middle Name"
            label="Middle Name"
            maxLength={60}
            value={form.middleName}
            mandatory={false}
            onChange={handleChange}
            validate={[
              validateMultiStringInput(form.middleName, 'Middle Name'),
            ]}
            disabled={isNameChecked}
          />
          <div className={styles.check__name}>
            <CheckboxField
              id="middleNameCheck"
              data-testid="middleNameCheck"
              checked={isNameChecked}
              onChange={handleNameChecked}
              value={[`${isNameChecked ? '1' : ''}`]}
              dataSource={[{ value: '1', label: CUSTOMER_NAME_CHECK }]}
              validate={[
                validateMiddleNameCheck(form.middleName, isNameChecked),
              ]}
            />
          </div>
          <InputField
            id="lastName"
            data-testid="Surname"
            label="*Surname"
            maxLength={60}
            value={form.lastName}
            onChange={handleChange}
            validate={[
              validateVerifyDetailsMandatory(form.lastName, 'Surname'),
              validateMultiStringInput(form.lastName, 'Surname'),
            ]}
            setRef={(ref: any) => {
              setInputRefs((prev) => ({ ...prev, ...{ lastName: ref } }));
            }}
          />
          <DateInput
            id="dateOfBirth"
            data-testid="dateOfBirth"
            name="dateOfBirth"
            childIds={['birthDay', 'birthMonth', 'birthYear']}
            label="*Date of Birth"
            value={[form.birthDay, form.birthMonth, form.birthYear]}
            validate={[
              validateDateMandatory(
                [form.birthDay, form.birthMonth, form.birthYear],
                'Date of Birth',
              ),
              validateAge(form.dateOfBirth, 18),
              validateDate(form.dateOfBirth, 'Date of Birth'),
            ]}
            onChange={handleChange}
            setRef={(ref: any) => {
              setInputRefs((prev) => ({ ...prev, ...{ dateOfBirth: ref } }));
            }}
          />
          <hr className={styles.form__divider} />
          <span hidden aria-hidden id="readOnlyField">
            Read only field
          </span>
          <InputField
            id="documentType"
            data-testid="Document Type"
            label="*Document Type"
            aria-labelledby="readOnlyField"
            value={getDocValue(type!)}
          />
          <InputField
            id="documentNumber"
            label={
              type === 'PASSPORT'
                ? '*Passport Number'
                : '*Drivers Licence Number'
            }
            maxLength={getMaxFieldLength()}
            value={form.documentNumber}
            onChange={handleChange}
            validate={[
              validateVerifyDetailsMandatory(
                form.documentNumber,
                type === 'PASSPORT'
                  ? 'Passport Number'
                  : 'Drivers Licence Number',
              ),
            ]}
            setRef={(ref: any) => {
              setInputRefs((prev) => ({ ...prev, ...{ documentNumber: ref } }));
            }}
          />
          <DateInput
            id="documentExpiry"
            childIds={['docExpDay', 'docExpMonth', 'docExpYear']}
            data-testid="Date of Expiry"
            name="documentExpiry"
            label="*Date of Expiry"
            value={[form.docExpDay, form.docExpMonth, form.docExpYear]}
            validate={[
              validateDateMandatory(
                [form.docExpDay, form.docExpMonth, form.docExpYear],
                'Date of Expiry',
              ),
              validateDateExpiry(form.documentExpiry),
              validateDate(form.documentExpiry, 'Date of Expiry'),
            ]}
            onChange={handleChange}
            setRef={(ref: any) => {
              setInputRefs((prev) => ({ ...prev, ...{ documentExpiry: ref } }));
            }}
          />
          {documentName === `Australian Driver's Licence` && (
            <>
              <InputField
                id="licenceCardNumber"
                data-testid="Licence Card Number"
                label="*Drivers Licence Card Number (DLCN)"
                maxLength={10}
                value={form.licenceCardNumber}
                validate={[
                  validateLicenceCardNumberMandatory(
                    form.licenceCardNumber,
                    'Drivers Licence Card Number (DLCN)',
                  ),
                ]}
                setRef={(ref: any) => {
                  setInputRefs((prev) => ({
                    ...prev,
                    ...{ licenceCardNumber: ref },
                  }));
                }}
                onChange={handleChange}
                fieldClassName={styles.input__DLCN}
              />
              <Button
                id="findDLCN"
                data-testid="findDLCN"
                icon={null}
                label="How do I find my DLCN?"
                type="clear"
                size="normal"
                title="How do I find my DLCN?"
                disabled={false}
                onClick={onFindDLCNClick}
                className={styles.link__button}
                iconPlacement={null}
                fieldsetType={null}
                isProcessing={null}
              />
            </>
          )}
          {documentName === `New Zealand Driver's Licence` && (
            <InputField
              id="licenceVersionNumber"
              data-testid="Licence Version Number"
              label="*Drivers Licence Version Number"
              maxLength={3}
              value={form.licenceVersionNumber}
              validate={[
                validateLicenceCardNumberMandatory(
                  form.licenceVersionNumber,
                  'Drivers Licence Version Number',
                ),
              ]}
              setRef={(ref: any) => {
                setInputRefs((prev) => ({
                  ...prev,
                  ...{ licenceVersionNumber: ref },
                }));
              }}
              onChange={handleChange}
              fieldClassName={styles.input__DLCN}
            />
          )}
          <hr className={styles.form__divider} />
          <SelectField
            id="residentialCountry"
            data-testid="Residential Country"
            className={styles.field}
            labelClassName={styles.label}
            dataSource={
              ekycJurisdictions.length === 1 && ekycJurisdictions[0] === 'AU'
                ? RESIDENTIAL_COUNTRY_LIST_AU_ONLY
                : RESIDENTIAL_COUNTRY_LIST
            }
            label="*Residential Country"
            value={form.residentialCountry}
            validate={[
              validateVerifyDetailsMandatory(
                form.residentialCountry,
                'Residential Country',
              ),
            ]}
            onChange={handleChange}
            setRef={(ref: any) => {
              setInputRefs((prev) => ({
                ...prev,
                ...{ residentialCountry: ref },
              }));
            }}
          />
          <span hidden aria-hidden id="prefixPlaceholderText">
            For an example
          </span>
          {!isManualAddressClicked ? (
            <div className={styles.address__field}>
              <AsyncAutoCompleteField
                id="residentialAddress"
                data-testid="Residential Address"
                label="*Residential Address (No PO Boxes)"
                hideLabel={false}
                labelPosition="top"
                labelClassName={styles.label}
                prefixMandatorySymbol
                disabled={false}
                placeholder="Start typing an address, e.g. 123 High..."
                maxLength={400}
                value={form.residentialAddress}
                onChange={handleChange}
                loadOptions={loadOptions}
                clearable
                handleBlur={handleBlur}
                mode="EDIT"
                // eslint-disable-next-line react/no-unstable-nested-components
                noResultsText={() =>
                  isAddressSearched &&
                  (isOCVError ||
                    ocvConfidence === OCV_CONFIDENCE_TOO_MANY_MATCHES ||
                    ocvConfidence === OCV_CONFIDENCE_NO_MATCHES) ? (
                    <div>
                      <div style={{ color: 'black' }}>
                        <img
                          style={{ paddingRight: '10px', paddingBottom: '5px' }}
                          src={warningAlert}
                          alt=""
                        />
                        <span>
                          {isOCVError && ALERT_NOTIFICATION_1}
                          {ocvConfidence === OCV_CONFIDENCE_TOO_MANY_MATCHES &&
                            ALERT_NOTIFICATION_2}
                          {ocvConfidence === OCV_CONFIDENCE_NO_MATCHES &&
                            RESULT_NOTIFICATION}
                        </span>
                      </div>
                      {ocvConfidence !== OCV_CONFIDENCE_TOO_MANY_MATCHES && (
                        <div className={styles.error__link__button}>
                          <Button
                            id="manualAddress"
                            data-testid="manualAddressInline"
                            icon={null}
                            label="Enter address manually"
                            type="clear"
                            size="normal"
                            title="Enter address manually"
                            disabled={false}
                            onClick={onManualAddressClick}
                            className={styles.link__button}
                            iconPlacement={null}
                            fieldsetType={null}
                            isProcessing={null}
                          />
                        </div>
                      )}
                    </div>
                  ) : null
                }
                loadingMessage={() => 'Searching...'}
                fieldStyle={{ paddingBottom: '0px' }}
                validate={[
                  validateVerifyDetailsMandatory(
                    form.residentialAddress,
                    'Residential Address',
                  ),
                  validateMultiStringInput(
                    form.residentialAddress,
                    'Residential Address',
                  ),
                ]}
                setRef={(ref: any) => {
                  setInputRefs((prev) => ({
                    ...prev,
                    ...{ residentialAddress: ref },
                  }));
                }}
              />
            </div>
          ) : null}

          <div className={styles.link__button}>
            {!isManualAddressClicked ? (
              <Button
                id="manualAddress"
                data-testid="manualAddress"
                icon={null}
                label="Enter address manually"
                type="clear"
                size="normal"
                title="Enter address manually"
                disabled={false}
                onClick={onManualAddressClick}
                className={styles.link__button}
                iconPlacement={null}
                fieldsetType={null}
                isProcessing={null}
              />
            ) : (
              <Button
                id="searchAddress"
                data-testid="searchAddress"
                icon={null}
                label="Search for address"
                type="clear"
                size="normal"
                title="Search for address"
                disabled={false}
                onClick={onSearchAddressLinkClick}
                className={styles.link__button}
                iconPlacement={null}
                fieldsetType={null}
                isProcessing={null}
              />
            )}
          </div>
          {isManualAddressClicked ? (
            <div>
              <InputField
                id="residentialAddress1"
                data-testid="Residential Address Line 1"
                label="*Residential Address Line 1 (No PO Boxes)"
                placeholder="e.g. 123 High Street"
                maxLength={255}
                value={form.residentialAddress1}
                onChange={handleChange}
                validate={[
                  validateVerifyDetailsMandatory(
                    form.residentialAddress1,
                    'Residential Address Line 1',
                  ),
                  validateMultiStringInput(
                    form.residentialAddress1,
                    'Residential Address Line 1',
                  ),
                ]}
                setRef={(ref: any) => {
                  setInputRefs((prev) => ({
                    ...prev,
                    ...{ residentialAddress1: ref },
                  }));
                }}
              />
              <InputField
                id="residentialAddress2"
                data-testid="Residential Address Line 2"
                label="Residential Address Line 2"
                placeholder="Unit / Apt / Flat / Suite"
                maxLength={255}
                value={form.residentialAddress2}
                onChange={handleChange}
                validate={[
                  validateMultiStringInput(
                    form.residentialAddress2,
                    'Residential Address Line 2',
                  ),
                ]}
                setRef={(ref: any) => {
                  setInputRefs((prev) => ({
                    ...prev,
                    ...{ residentialAddress2: ref },
                  }));
                }}
              />
              <InputField
                id="city"
                data-testid="Suburb / City / Town"
                label="*Suburb / City / Town"
                placeholder={
                  documentName.indexOf('New Zealand') === -1
                    ? 'e.g. Melbourne'
                    : 'e.g. Auckland Central'
                }
                maxLength={120}
                value={form.city}
                onChange={handleChange}
                validate={[
                  validateVerifyDetailsMandatory(
                    form.city,
                    'Suburb / City / Town',
                  ),
                  validateMultiStringInput(form.city, 'Suburb / City / Town'),
                ]}
                setRef={(ref: any) => {
                  setInputRefs((prev) => ({
                    ...prev,
                    ...{ city: ref },
                  }));
                }}
              />
              <InputField
                id="state"
                data-testid="State / Province / Region"
                label="*State / Province / Region"
                placeholder={
                  documentName.indexOf('New Zealand') === -1
                    ? 'e.g. VIC'
                    : 'e.g. Auckland'
                }
                maxLength={60}
                value={form.state}
                onChange={handleChange}
                validate={[
                  validateVerifyDetailsMandatory(
                    form.state,
                    'State / Province / Region',
                  ),
                  validateMultiStringInput(
                    form.state,
                    'State / Province / Region',
                  ),
                ]}
                setRef={(ref: any) => {
                  setInputRefs((prev) => ({
                    ...prev,
                    ...{ state: ref },
                  }));
                }}
              />
              <InputField
                id="postcode"
                data-testid="Postcode"
                label="*Postcode"
                placeholder={
                  documentName.indexOf('New Zealand') === -1
                    ? 'e.g. 3000'
                    : 'e.g. 1010'
                }
                maxLength={10}
                value={form.postcode}
                onChange={handleChange}
                validate={[
                  validateVerifyDetailsMandatory(form.postcode, 'Postcode'),
                  validateMultiStringInput(form.postcode, 'Postcode'),
                ]}
                setRef={(ref: any) => {
                  setInputRefs((prev) => ({
                    ...prev,
                    ...{ postcode: ref },
                  }));
                }}
              />
            </div>
          ) : null}
          <hr className={styles.form__divider} />
          <SelectField
            id="gender"
            data-testid="Gender"
            label="*Gender"
            className={styles.field}
            labelClassName={styles.label}
            dataSource={GENDER_LIST}
            value={form.gender}
            validate={[validateVerifyDetailsMandatory(form.gender, 'Gender')]}
            onChange={handleChange}
            setRef={(ref: any) => {
              setInputRefs((prev) => ({ ...prev, ...{ gender: ref } }));
            }}
          >
            <option value="">Select</option>
          </SelectField>
          <SelectField
            id="nationality"
            data-testid="Country of Nationality"
            className={styles.field}
            label="*Country of Nationality"
            labelClassName={styles.label}
            dataSource={COUNTRY_LIST}
            value={form.nationality}
            validate={[
              validateVerifyDetailsMandatory(
                form.nationality,
                'Country of Nationality',
              ),
            ]}
            onChange={handleChange}
            setRef={(ref: any) => {
              setInputRefs((prev) => ({ ...prev, ...{ nationality: ref } }));
            }}
          >
            <option value="">Select</option>
          </SelectField>
          <SelectField
            id="occupation"
            data-testid="Occupation"
            className={styles.field}
            label="*Occupation"
            labelClassName={styles.label}
            dataSource={OCCUPATION_LIST}
            value={form.occupation}
            validate={[
              validateVerifyDetailsMandatory(form.occupation, 'Occupation'),
            ]}
            onChange={handleChange}
            setRef={(ref: any) => {
              setInputRefs((prev) => ({ ...prev, ...{ occupation: ref } }));
            }}
          >
            <option value="">Select</option>
          </SelectField>
          <div className={styles.checkbox__container}>
            <Checkbox
              id="agreement"
              data-testid="agreement"
              label={CUSTOMER_AGREEMENT}
              style={{ marginRight: '0' }}
              checked={isChecked}
              onChange={handleChecked}
            />
          </div>
        </div>
        <div className={styles.button__bar__container}>
          <ButtonBar placement="right">
            <Button
              id="back"
              label="Back"
              aria-label="Back"
              onClick={handleBackButtonClick}
              icon=""
              iconPlacement="left"
              title="Back"
              fieldsetType="inline"
              type="primary"
              size="normal"
              disabled={false}
              className=""
              isProcessing={false}
            />
            <Button
              id="confirm"
              label="Confirm"
              aria-label="Confirm"
              onClick={handleConfirm}
              icon=""
              iconPlacement="left"
              title="Confirm"
              fieldsetType="inline"
              type="standard"
              size="normal"
              disabled={!isChecked}
              className={isChecked ? styles.btn__confirm : ''}
              isProcessing={false}
            />
          </ButtonBar>
        </div>
        <DialogConfirmation
          open={isBackClicked}
          size="normal"
          onConfirm={handleYesButtonClick}
          onCancel={handleNoButtonClick}
          confirmText="Yes"
          cancelText="No"
          messages={[
            'Are you sure you wish to go back and reselect your document?',
            'Your scanned document will not be saved.',
          ]}
          overlayStyle={{ background: '#494949', opacity: '0.85' }}
          dialogStyle={{ width: '90%', maxWidth: '50em' }}
        />
        {/* If error comes from default message (UI Driven, display confirmation
        else backend driven error display notification) */}
        {error.code === defaultErrorCode ? (
          <DialogConfirmation
            open={!isEmpty(error.message)}
            size="normal"
            onConfirm={handleRetry}
            onCancel={handleExit}
            confirmText="Try Again"
            confirmIcon="fa-rotate-right"
            cancelText="Exit"
            cancelIcon="fa-times"
            messages={[error.message]}
            overlayStyle={{ background: '#494949', opacity: '0.85' }}
            dialogStyle={{
              width: '80%',
              maxHeight: '16em',
              maxWidth: '31.25em',
            }}
          />
        ) : (
          <DialogNotification
            open={!isEmpty(error.message)}
            messages={[error.message]}
            buttonAction={handleExit}
            buttonText="Exit"
            buttonIcon="fa-check"
          />
        )}
        <Dialog
          open={!isSessionDialog && isDLCNClicked}
          buttonAction={handleDLCNClose}
          buttonText="Got it"
          data-testId="gotIt"
          buttonIcon=""
          imageDetails={DLCNImages}
          dialogStyle={{
            width: '80%',
            maxHeight: Object.keys(DLCNImages).length > 1 ? '520px' : '420px',
            maxWidth: '326px',
          }}
          overlayStyle={{ background: '#494949', opacity: '0.85' }}
        />
      </div>
    </>
  );
};

export default FormValidator(VerifyDetailsForm);
