import {
  createAsyncThunk,
  createSlice,
  isFulfilled,
  isPending,
  isRejected,
  PayloadAction,
} from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import {
  API_ERRORS,
  ERROR_DEFAULT,
} from '../../../screens/IdentityUpload/ScanDocument/constants';

export interface Credentials {
  email: string;
  reference: string;
}

interface SessionSuccessResponse {
  expirationTime: number;
}

interface SessionErrorResponse {
  code: string;
  message: string;
}

interface SessionState {
  session: SessionSuccessResponse;
  error: SessionErrorResponse;
  loading: boolean;
  isLoggedIn: boolean;
  isSessionDialogOpen: boolean;
  ekycJurisdictions: string[];
}

const initialSessionState = {
  // Maximum Unix Time
  expirationTime: 2147483647,
};

const initialErrorState = {
  code: '',
  message: '',
};

const initialState: SessionState = {
  session: initialSessionState,
  error: initialErrorState,
  loading: false,
  isLoggedIn: false,
  isSessionDialogOpen: false,
  ekycJurisdictions: [],
};

export const startSession = createAsyncThunk<
  SessionSuccessResponse,
  Credentials,
  { rejectValue: any }
>('session/start', async (credentials: Credentials, { rejectWithValue }) => {
  try {
    const response = await axios.post(`v1/session/start`, credentials, {
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
      },
    });
    return {
      data: response.data,
      expirationTime: parseInt(response.headers['expiration-time'], 10),
    };
  } catch (error: any) {
    if (
      error instanceof AxiosError &&
      typeof error?.response?.data === 'object'
    ) {
      return rejectWithValue({ ...error?.response?.data });
    }
    return rejectWithValue({
      code: ERROR_DEFAULT,
      message: API_ERRORS[ERROR_DEFAULT],
    });
  }
});

export const refreshSession = createAsyncThunk<
  SessionSuccessResponse,
  void,
  { rejectValue: any }
>('session/refresh', async (_, { rejectWithValue }) => {
  try {
    const response = await axios.put(`v1/session/refresh`, {});
    return {
      data: response.data,
      expirationTime: parseInt(response.headers['expiration-time'], 10),
    };
  } catch (error: any) {
    if (
      error instanceof AxiosError &&
      typeof error?.response?.data === 'object'
    ) {
      return rejectWithValue({ ...error?.response?.data });
    }
    return rejectWithValue({
      code: ERROR_DEFAULT,
      message: API_ERRORS[ERROR_DEFAULT],
    });
  }
});

export const cleanSession = createAsyncThunk<
  SessionSuccessResponse,
  void,
  { rejectValue: any }
>('session/clean', async (_, { rejectWithValue }) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
    const response = await axios.put(`v1/session/clean`, {});
    return initialSessionState;
  } catch (error: any) {
    if (
      error instanceof AxiosError &&
      typeof error?.response?.data === 'object'
    ) {
      return rejectWithValue({ ...error?.response?.data });
    }
    return rejectWithValue({
      code: ERROR_DEFAULT,
      message: API_ERRORS[ERROR_DEFAULT],
    });
  }
});

const isSessionThunkFulfilled = isFulfilled(
  startSession,
  refreshSession,
  cleanSession,
);
const isSessionThunkRejected = isRejected(
  startSession,
  refreshSession,
  // We don't want to show an error on session clean failure
  // cleanSession,
);

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionExpiredError: () => ({
      ...initialState,
      ...{
        error: {
          code: '',
          message:
            'Your session has expired. But not to worry, you can log in again anytime.',
        },
      },
    }),
    resetSessionState: () => initialState,
    resetErrorState: (state) => {
      state.error = initialErrorState;
    },
    setSessionDialogState: (state) => {
      state.isSessionDialogOpen = true;
    },
    resetSessionDialogState: (state) => {
      state.isSessionDialogOpen = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addMatcher(
        isSessionThunkFulfilled,
        (state, { payload }: PayloadAction<any>) => {
          state.session.expirationTime = payload.expirationTime;
          state.error = initialErrorState;
          state.isLoggedIn = true;
          state.ekycJurisdictions = payload.data?.ekycJurisdictions ?? [];
        },
      )
      .addMatcher(isSessionThunkRejected, (state, action: any) => {
        state.error = action.payload;
      })

      /* These reducers can be called by different actions.
        For example, the second matcher is run if either session/fulfilled or 
        user/fulfilled are dispatched. This is because the
        Session component is responsible for managing the 'session' state
        which includes expirationTime. expirationTime can be returned by
        other thunks as well. This slice is also responsible for as controlling
        whether the session is 'loading'.
      */
      .addMatcher(isPending, (state) => {
        state.loading = true;
      })
      .addMatcher(isFulfilled, (state) => {
        state.loading = false;
      })
      .addMatcher(isRejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  resetSessionState,
  setSessionExpiredError,
  resetErrorState,
  setSessionDialogState,
  resetSessionDialogState,
} = sessionSlice.actions;
export default sessionSlice.reducer;
