// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__src-common-components-Omni-Stepper-styles-module__iIZXQ {
  width: 100%;
  padding-left: 24px;
  padding-right: 24px;
}

.steps__src-common-components-Omni-Stepper-styles-module__QqWLy {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  margin: 1em 0 2em 0;
}
.steps__src-common-components-Omni-Stepper-styles-module__QqWLy > :last-child {
  justify-content: flex-end;
}
.steps__src-common-components-Omni-Stepper-styles-module__QqWLy > :first-child {
  justify-content: flex-start;
}
.steps__src-common-components-Omni-Stepper-styles-module__QqWLy > :not(:first-child):not(:last-child) {
  justify-content: center
}


.steps__step__src-common-components-Omni-Stepper-styles-module__ftPBP {
  position: relative;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: auto;
  /* Changed */
  flex-basis: 100px;
  border-bottom: 2px solid #cdcdcd;
  padding-bottom: 0.8em;
  /* white-space: nowrap; */
  /* Added */
  display: flex;
  min-width: 0;
}

.steps__hidden__src-common-components-Omni-Stepper-styles-module__X0IJH {
  font-size: 0em;
  height: 0em;
  color: transparent;
}

.steps__num__src-common-components-Omni-Stepper-styles-module__dyBn3 {
  display: inline-block;
  text-align: center;
  font-weight: 600;
  font-size: 1.2rem;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  padding-top: 4px;
  background: #484848;
  color: #fff;
  flex-shrink: 0;
}

.steps__text__src-common-components-Omni-Stepper-styles-module__euGy_ {
  font-size: 1.8rem;
  margin: 0 0.45em;
  color: #484848;
  display: inline-block;
  vertical-align: middle;
}

.steps__step--is-current__src-common-components-Omni-Stepper-styles-module__XdaQs,
.steps__step--is-complete__src-common-components-Omni-Stepper-styles-module__SzPW4 {
  border-bottom-color: #007dba;
}

.steps__step--is-current__src-common-components-Omni-Stepper-styles-module__XdaQs > .steps__num__src-common-components-Omni-Stepper-styles-module__dyBn3,
.steps__step--is-complete__src-common-components-Omni-Stepper-styles-module__SzPW4 > .steps__num__src-common-components-Omni-Stepper-styles-module__dyBn3 {
  background: #007dba;
}

.steps__step--is-current__src-common-components-Omni-Stepper-styles-module__XdaQs > .steps__text__src-common-components-Omni-Stepper-styles-module__euGy_,
.steps__step--is-complete__src-common-components-Omni-Stepper-styles-module__SzPW4 > .steps__text__src-common-components-Omni-Stepper-styles-module__euGy_ {
  color: #007dba;
}

.steps__step--is-disabled__src-common-components-Omni-Stepper-styles-module__Uih93 {
  opacity: 0.5;
}

/* IE9 CSS HACK */

@media all and (min-width: 0\\0) and (min-resolution: 0.001dpcm) {
  .steps__src-common-components-Omni-Stepper-styles-module__QqWLy {
    display: table;
    table-layout: fixed;
    white-space: nowrap;
    width: 100%;
  }
  .steps__step__src-common-components-Omni-Stepper-styles-module__ftPBP {
    display: table-cell;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__src-common-components-Omni-Stepper-styles-module__iIZXQ`,
	"steps": `steps__src-common-components-Omni-Stepper-styles-module__QqWLy`,
	"steps__step": `steps__step__src-common-components-Omni-Stepper-styles-module__ftPBP`,
	"steps__hidden": `steps__hidden__src-common-components-Omni-Stepper-styles-module__X0IJH`,
	"steps__num": `steps__num__src-common-components-Omni-Stepper-styles-module__dyBn3`,
	"steps__text": `steps__text__src-common-components-Omni-Stepper-styles-module__euGy_`,
	"steps__step--is-current": `steps__step--is-current__src-common-components-Omni-Stepper-styles-module__XdaQs`,
	"steps__step--is-complete": `steps__step--is-complete__src-common-components-Omni-Stepper-styles-module__SzPW4`,
	"steps__step--is-disabled": `steps__step--is-disabled__src-common-components-Omni-Stepper-styles-module__Uih93`
};
module.exports = ___CSS_LOADER_EXPORT___;
