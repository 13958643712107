import React from 'react';

import { CxSection } from './CxSection';
import styles from './Landing.module.css';
import { LandingForm } from './LandingForm';

const Landing = () => (
  <div className={styles.content__container}>
    <LandingForm />
    <CxSection />
  </div>
);

export default Landing;
