/* eslint-disable import/named */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/require-default-props */
import { Input } from '@omni/form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import stylePropType from 'react-style-proptype';

import { Field } from '.';

export class InputField extends PureComponent {
  focus = () => {
    this.input && this.input.focus();
  };

  render() {
    const {
      id,
      label,
      hideLabel,
      mandatory,
      prefixMandatorySymbol,
      mode,
      errors,
      disabled,
      labelPosition,
      labelIcon,
      fieldsetType,
      note,
      notePosition,
      noteInViewMode,
      labelClassName,
      fieldStyle,
      fieldClassName,
      fieldLabelClassName,
      fieldElementsClassName,
      ...props
    } = this.props;
    const hasError = !_.isEmpty(errors);
    const fieldProps = {
      id,
      label,
      hideLabel,
      mandatory,
      prefixMandatorySymbol,
      mode,
      hasError,
      disabled,
      labelPosition,
      labelIcon,
      labelClassName,
      style: fieldStyle,
      fieldClassName,
      fieldLabelClassName,
      fieldElementsClassName,
    };
    const inputProps = _.omit(props, ['nlsMessages']);
    return (
      <Field {...fieldProps} overrideShowMandatorySymbol>
        <Input
          id={id}
          mode={mode}
          setRef={(ref) => (this.input = ref)}
          fieldsetType={fieldsetType}
          disabled={disabled}
          errors={errors}
          mandatory={mandatory}
          noteInViewMode={noteInViewMode}
          note={note}
          notePosition={notePosition}
          aria-invalid={!!hasError}
          {...inputProps}
        />
      </Field>
    );
  }
}

InputField.propTypes = {
  /** Component ID   */
  id: PropTypes.string.isRequired,
  /** Used to identify the purpose of the input element */
  label: PropTypes.string.isRequired,
  /** hide label */
  hideLabel: PropTypes.bool,
  /** disables form element */
  disabled: PropTypes.bool,
  /** set the field is mandatory or not    */
  mandatory: PropTypes.bool,
  /** set mandatory '\*' symbol before or after field label */
  prefixMandatorySymbol: PropTypes.bool,
  overrideShowMandatorySymbol: PropTypes.bool,
  /** validation patterns */
  pattern: PropTypes.string,
  /** minimum number of char required */
  minlength: PropTypes.number,
  /** maximum number of char required */
  maxLength: PropTypes.number,
  /** Will decide the mode of the component ENTRY, EDIT or VIEW */
  mode: PropTypes.string,
  /**  description about the input field, will show next to the input element */
  note: PropTypes.string,
  /** Array of error messages  */
  errors: PropTypes.array,
  /** labelPosition can me one of top, inline, right and left */
  labelPosition: PropTypes.oneOf(['top', 'inline', 'right', 'left']),
  /** To arrange a group of input fields in inlinegroup or stackedgroup */
  fieldsetType: PropTypes.oneOf(['stacked', 'inline']),
  /** This dictates child components for InputField  */
  children: PropTypes.node,
  /** labelIcon in Field  */
  labelIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  /** Note Position */
  notePosition: PropTypes.oneOf(['inline', 'block']),
  /** Note in View Mode */
  noteInViewMode: PropTypes.bool,
  /** optional classname for the label element */
  labelClassName: PropTypes.string,
  /** Field level CSS style */
  fieldStyle: stylePropType,
  /** optional classname for the field div */
  fieldClassName: PropTypes.string,
  /** optional classname for the field__label div */
  fieldLabelClassName: PropTypes.string,
  /** optional classname for the field__elements div */
  fieldElementsClassName: PropTypes.string,
};
InputField.displayName = 'InputField';

export default InputField;
