/* eslint-disable spaced-comment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable arrow-body-style */
/* eslint-disable class-methods-use-this */
import AsyncCreatable from '@omni/autocomplete/lib/lib/async-creatable/dist/react-select.cjs';
import { components } from '@omni/autocomplete/lib/lib/src/index';
import defaultArrowRenderer from '@omni/autocomplete/lib/utils/defaultArrowRenderer';
import defaultValueRenderer from '@omni/autocomplete/lib/utils/defaultValueRenderer';
import { ErrorWrapper, constants } from '@omni/form';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import stylePropType from 'react-style-proptype';

import defaultOptionRenderer from './defaultOptionRenderer';
import { RefForward } from '../Form';

import { getSelectStyle, getSelectTheme } from '.';

export class AsyncAutoComplete extends PureComponent {
  onChange = (value) => {
    const { id, onChange } = this.props;
    onChange(id, value);
  };

  optionRenderer = (option) => {
    const { optionRenderer } = this.props;
    const {
      selectProps: { inputValue },
    } = option;
    return optionRenderer(option, inputValue);
  };

  setRef = (ref) => {
    if (ref) {
      // eslint-disable-next-line react/no-unused-class-component-methods
      this.input = ref.select.select.select.inputRef;
    }
  };

  ClearIndicator = (props) => {
    return (
      <components.ClearIndicator {...props}>
        <i className="fa fa-times" aria-hidden="true" />
      </components.ClearIndicator>
    );
  };

  render() {
    const {
      id,
      value,
      mode,
      wrapperClassName,
      wrapperStyle,
      valueRenderer,
      arrowRenderer,
      errors,
      multi,
      clearable,
      defaultOptions,
      noResultsText,
      disabled,
      showSearchButton,
      showDropdownArrow,
      ...props
    } = this.props;
    const isViewMode = mode === constants.VIEW;
    const wrapperClass = classNames('standard-autocomplete', {
      'standard-autocomplete--has-error': !_.isEmpty(errors),
      'standard-autocomplete--with-btn': showSearchButton,
      [wrapperClassName]: wrapperClassName,
    });
    return (
      <ErrorWrapper
        id={`${id}_wrapper`}
        className={wrapperClass}
        style={wrapperStyle}
        errors={errors}
      >
        {isViewMode && value && valueRenderer && (
          <div>{valueRenderer(value, mode)}</div>
        )}
        {!isViewMode && (
          <AsyncCreatable
            {...props}
            classNamePrefix="Select"
            inputId={id}
            isMulti={multi}
            isClearable={clearable}
            value={value}
            onChange={this.onChange}
            isDisabled={disabled}
            components={{
              ValueContainer: valueRenderer,
              DropdownIndicator: showDropdownArrow && arrowRenderer,
              ClearIndicator: this.ClearIndicator,
              Option: this.optionRenderer,
            }}
            defaultOptions={defaultOptions}
            isValidNewOption={() => false}
            noOptionsMessage={noResultsText}
            styles={getSelectStyle()}
            theme={getSelectTheme}
            ref={this.setRef}
          />
        )}
      </ErrorWrapper>
    );
  }
}

AsyncAutoComplete.propTypes = {
  /** id of the AutoComplete block */
  id: PropTypes.string.isRequired,
  /** Value selected */
  value: PropTypes.any,
  disabled: PropTypes.bool,
  /** View, Entry, Edit modes based on which the behaviour of the AutoComplete changes */
  mode: PropTypes.string,
  /** Class name of the block */
  wrapperClassName: PropTypes.string,
  /** Inline styles to the block  */
  wrapperStyle: stylePropType,
  /** Method to get all options in the list */
  optionRenderer: PropTypes.func,
  /** Method to render the selected option */
  valueRenderer: PropTypes.func,
  arrowRenderer: PropTypes.func,
  /** Method called on change of the selected option  */
  onChange: PropTypes.func.isRequired,
  /** On true can select multiple options */
  multi: PropTypes.bool,
  /** Array of error messages */
  errors: PropTypes.array,
  /** On true enables the option to clear the options selected */
  clearable: PropTypes.bool,
  /**  To be displayed when the user clicks on the select field but has not yet typed anything in*/
  noResultsText: PropTypes.func,
  /** Toggle classname to be used when search button is enabled */
  showSearchButton: PropTypes.bool,
  /** Toggle the Dropdown Arrow */
  showDropdownArrow: PropTypes.bool,
  // See react-select for more details of the props below:
  /** Custom method to filter whether an option should be displayed in the menu */
  filterOption: PropTypes.func,
  /** The default set of options to show before the user starts searching. When set to true, the results for loadOptions('') will be autoloaded. */
  defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  /** If cacheOptions is truthy, then the loaded data will be cached. The cache will remain until cacheOptions changes value. */
  cacheOptions: PropTypes.any,
  /** Function that returns a promise, which is the set of options to be used once the promise resolves. */
  loadOptions: PropTypes.func,
  /** Will cause the select to be displayed in the loading state, even if the Async select is not currently waiting for loadOptions to resolve */
  isLoading: PropTypes.bool,
  styles: PropTypes.any,
};

AsyncAutoComplete.defaultProps = {
  multi: false,
  clearable: false,
  defaultOptions: false,
  optionRenderer: defaultOptionRenderer,
  valueRenderer: defaultValueRenderer,
  arrowRenderer: defaultArrowRenderer,
  showSearchButton: false,
  showDropdownArrow: false,
};

AsyncAutoComplete.displayName = 'AsyncAutoComplete';
export default RefForward(AsyncAutoComplete);
