import _ from 'lodash';
import { scrollIntoView } from 'seamless-scroll-polyfill';

export const isClientErrorStatus = (status: number | undefined) => {
  if (status === undefined) {
    return false;
  }
  return status >= 400 && status < 500;
};

export const validateOnScroll = (
  inputRefs: any,
  fieldValidators: any,
  clientErrors: any,
  serverErrors: any,
  // eslint-disable-next-line consistent-return
) => {
  // had to put scroll logic for middleNameCheck field in particular, as input ref prop cannot be
  // assigned to this component from omni without localising it
  if (!_.isEmpty(clientErrors.middleNameCheck)) {
    const element = document.getElementById(
      'middleNameCheck-field',
    ) as HTMLElement;
    if (element) {
      scrollIntoView(element, {
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
      return false;
    }
  }
  const fields = _.keys(fieldValidators);
  fields.every((field) => {
    if (
      (!_.isEmpty(clientErrors[field]) || !_.isEmpty(serverErrors[field])) &&
      inputRefs[field]
    ) {
      scrollIntoView(inputRefs[field].input, {
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
      return false;
    }
    return true;
  });
};
