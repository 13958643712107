import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import styles from './Spinner.module.css';

interface Props {
  title?: string;
  description?: string;
}

const Spinner = ({ title = '', description = '' }: Props) => {
  const [domRef, setDomRef] = useState(document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(domRef);
    setDomRef(domRef);
  }, []);

  useEffect(
    () => () => {
      if (domRef) {
        document.body.removeChild(domRef);
      }
    },
    [],
  );

  const spinnerContainerStyle = !title && !description ? { top: '40%' } : {};

  const SvgSpinner = () => (
    <svg
      viewBox="0 0 108 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      baseProfile="full"
      className={styles.svg}
    >
      <circle
        vectorEffect="non-scaling-stroke"
        cx="54"
        cy="54"
        r="50"
        stroke="rgba(0, 47, 73, 0.2)"
        strokeWidth="6"
        className={styles.transform}
      />
      <path
        vectorEffect="non-scaling-stroke"
        d="M54 4C26.3858 4 4 26.3858 4 54C4 81.6142 26.3858 104 54 104C59.7362 103.891 68.5249 102.519 77.1193 98.3316"
        stroke="url(#path_uq4iq512u)"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={styles.transform}
      />
      <defs>
        <radialGradient
          id="path_uq4iq512u"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(54.6168 6.1856) rotate(26.9626) scale(73.2378 108.871)"
        >
          <stop stopColor="#00C6D7" />
          <stop offset="0.350656" stopColor="#037FBE" />
          <stop offset="0.665068" stopColor="#005682" />
          <stop offset="1" stopColor="#31B9FF" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );

  return ReactDOM.createPortal(
    <>
      <div
        id="spinnerOverlay"
        className={styles.spinner__overlay}
        aria-hidden="false"
        data-testid="spinnerOverlay"
      />
      <div
        id="spinnerContainer"
        className={styles.spinner__container}
        role="status"
        aria-hidden="false"
        data-testid="spinnerContainer"
        style={spinnerContainerStyle}
      >
        {title && (
          <div className={styles.spinner__title} data-testid="spinnerTitle">
            {title}
          </div>
        )}
        <SvgSpinner />
        {description && (
          <div
            className={styles.spinner__description}
            data-testid="spinnerDescription"
          >
            {description}
          </div>
        )}
      </div>
    </>,
    domRef,
  );
};

Spinner.propTypes = {
  /** title text */
  title: PropTypes.string,
  /** description text */
  description: PropTypes.string,
};

export default Spinner;
