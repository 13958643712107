import { FormValidator } from '@omni/validator';
import React, { useState } from 'react';

import styles from './SelectDocumentForm.module.css';
import {
  documentSelected,
  documentSubSelectionSelected,
} from './selectDocumentFormSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { Button } from '../../../common/components/Omni/Button';
import {
  RadioField,
  Radio,
  SelectField,
} from '../../../common/components/Omni/Form';
import {
  validateMandatoryDocumentSelect,
  validateSubSelection,
} from '../../../utils/validations/validations';

interface Props {
  ekycDocuments?: any[];
  onContinue: () => void;
  validatorContext: any;
}

const SelectDocumentForm = ({
  ekycDocuments = [],
  onContinue,
  validatorContext,
}: Props) => {
  // The current selected eKYC document details
  const { documentName, subSelection } = useAppSelector(
    (state) => state.document,
  );
  const [isValidPage, setIsValidPage] = useState(true);

  const dispatch = useAppDispatch();

  const handleDocumentSelect = (id: string, value: string) => {
    const { type } = ekycDocuments.find(({ name }) => name === value);
    dispatch(documentSelected({ documentName: value, documentType: type }));
  };

  const handleSubSelection = (id: string, value: string) => {
    const { subSelections } = ekycDocuments.find(
      ({ name }) => name === documentName,
    );
    const { type } = subSelections.find(
      ({ code }: { code: string }) => code === value,
    );
    dispatch(
      documentSubSelectionSelected({ subSelection: value, documentType: type }),
    );
  };

  const handleClick = () => {
    const { validate } = validatorContext;
    if (!validate({ documentName, subSelection })) {
      setIsValidPage(false);
      return;
    }
    onContinue();
  };

  const { subSelections, subSelectionsTitle } =
    ekycDocuments.find(({ name }) => name === documentName) || {};

  return (
    <div className={styles.container}>
      <RadioField
        id="documents"
        label="Please Select:"
        onChange={handleDocumentSelect}
        value={documentName}
        mandatory={false}
        prefixMandatorySymbol
        disabled={false}
        fieldsetType="stacked"
        validate={[validateMandatoryDocumentSelect(documentName)]}
      >
        {ekycDocuments.map(({ name }) => (
          <Radio id={name} label={name} value={name} key={name} />
        ))}
      </RadioField>
      {subSelections && (
        <SelectField
          id={subSelectionsTitle}
          className={styles.select}
          dataSource={subSelections.map(({ code }: any) => ({
            value: code,
            label: code,
          }))}
          label={subSelectionsTitle}
          onChange={handleSubSelection}
          value={subSelection}
          validate={[validateSubSelection(subSelection, subSelectionsTitle)]}
        >
          <option value="">Select</option>
        </SelectField>
      )}
      <Button
        id="continue"
        label="Continue"
        aria-label="Continue"
        onClick={handleClick}
        icon=""
        iconPlacement="left"
        title="Continue"
        fieldsetType="inline"
        type="standard"
        size="normal"
        disabled={false}
        className={styles.continue}
        isProcessing={false}
      />
      {!isValidPage && (
        <div
          className={styles.visually__hidden1}
          aria-hidden="false"
          aria-label="page has errors, fill required mandatory inputs correctly to proceed further"
          role="alert"
          aria-live="assertive"
        />
      )}
    </div>
  );
};

export default FormValidator(SelectDocumentForm);

SelectDocumentForm.defaultProps = {
  ekycDocuments: [],
};
