import { DialogConfirmation } from '@omni/dialog';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  API_ERRORS,
  ERROR_DEFAULT,
  ERROR_LIVENESS_INVALID_REQ,
  ERROR_LIVENESS_INVALID_AUTH,
  ERROR_LIVENESS_PRECONDITION_FAILED,
  ERROR_DOWNSTREAM_INTERNAL_ERROR,
  ERROR_DOWNSTREAM_SERVICE_UNAVAILABLE,
  ERROR_LIVENESS_IMAGEQC_FAILED,
  ERROR_LIVENESS_IMAGE_QC_BELOW_THRESHOLD,
  ERROR_PASSIVELIVENESS_SCORE_BELOW_THRESHOLD,
  ERROR_BIOMETRICMATCH_MORE_ATTEMPTS,
  ERROR_COBRA_PROFILE_EKYC_REFERENCE_INVALID,
  ERROR_COBRA_PROFILE_USER_STATUS_INVALID,
  ERROR_DOWNSTREAM_FRANKIEONE_RETRYABLE,
  ERROR_DOWNSTREAM_FRANKIEONE_UNRETRYABLE,
  ERROR_DOCUMENT_VERIFICATION_NZ_NOT_AVAILABLE,
} from './constants';
import hat from './images/hat.svg';
import info from './images/info.svg';
import sunglasses from './images/sunglasses.svg';
import styles from './LivenessSelfie.module.css';
import { livenessSubmit, resetLivenessState } from './livenessSelfieSlice';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { Camera, Context } from '../../common/components/Camera';
import { DialogNotification } from '../../common/components/Dialog';
import Drawer from '../../common/components/Drawer/Drawer';
import { refreshSession } from '../../common/components/Session/sessionSlice';
import { Spinner } from '../../common/components/Spinner';

export const LivenessSelfie = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const [isStreaming, setIsStreaming] = useState(false);
  const [forceRender, setForceRender] = useState(0);
  const isProcessing = useAppSelector((state) => state.session.loading);
  const error = useAppSelector((state) => state.liveness.error);
  const [selfieImage, setSelfieImage] = useState('');

  const handleSubmit = async (imageBase64: string) => {
    if (!selfieImage) {
      setSelfieImage(imageBase64);
    }
    const resultAction = await dispatch(livenessSubmit({ imageBase64 }));
    if (livenessSubmit.fulfilled.match(resultAction)) {
      navigate('/confirm', { replace: true });
    }
  };

  const handleExit = () => {
    navigate('/?sredirect');
    setTimeout(() => window.location.assign('/'), 100);
  };

  const handleRetry = async () => {
    dispatch(resetLivenessState());
    dispatch(refreshSession());
    setForceRender(forceRender + 1);
  };

  const handleDownstreamRetry = () => {
    handleSubmit(selfieImage);
  };

  const handleDrawer = (isOpen = false) => {
    setIsDrawerOpen(isOpen);
    setIsStreaming(true);
  };

  const renderErrorDialog = () => {
    switch (error.code) {
      case ERROR_LIVENESS_IMAGEQC_FAILED:
      case ERROR_LIVENESS_INVALID_REQ:
      case ERROR_LIVENESS_INVALID_AUTH:
      case ERROR_LIVENESS_PRECONDITION_FAILED:
      case ERROR_COBRA_PROFILE_USER_STATUS_INVALID:
      case ERROR_COBRA_PROFILE_EKYC_REFERENCE_INVALID:
      case ERROR_DOWNSTREAM_FRANKIEONE_UNRETRYABLE:
      case ERROR_DOCUMENT_VERIFICATION_NZ_NOT_AVAILABLE:
        return (
          <DialogNotification
            open
            messages={[API_ERRORS[error.code]]}
            buttonAction={handleExit}
            buttonText="Exit"
            buttonIcon="fa-check"
          />
        );
      case ERROR_LIVENESS_IMAGE_QC_BELOW_THRESHOLD:
      case ERROR_PASSIVELIVENESS_SCORE_BELOW_THRESHOLD:
      case ERROR_BIOMETRICMATCH_MORE_ATTEMPTS:
        return (
          <DialogNotification
            open
            messages={[API_ERRORS[error.code]]}
            buttonAction={handleRetry}
            buttonText="Try Again"
            buttonIcon="fa-rotate-right"
          />
        );
      case ERROR_DOWNSTREAM_INTERNAL_ERROR:
      case ERROR_DOWNSTREAM_SERVICE_UNAVAILABLE:
      case ERROR_DOWNSTREAM_FRANKIEONE_RETRYABLE:
        return (
          <DialogConfirmation
            open={!isProcessing}
            size="normal"
            onConfirm={handleDownstreamRetry}
            onCancel={handleExit}
            confirmText="Try Again"
            confirmIcon="fa-rotate-right"
            cancelText="Exit"
            messages={[API_ERRORS[error.code]]}
            overlayStyle={{ background: '#494949', opacity: '0.85' }}
            dialogStyle={{
              width: '80%',
              maxWidth: '31.25em',
              maxHeight: '16em',
            }}
          />
        );

      default:
        return (
          <DialogNotification
            open
            messages={[API_ERRORS[ERROR_DEFAULT]]}
            buttonAction={handleExit}
            buttonText="Exit"
            buttonIcon="fa-check"
          />
        );
    }
  };
  return (
    <>
      {isProcessing && (
        <Spinner
          title="Processing..."
          description={`We are checking the photo quality. 
        It will only take a moment.`}
        />
      )}
      <div className={styles.noscroll} aria-hidden={isDrawerOpen}>
        <Camera
          key={forceRender}
          isActive={isStreaming}
          width={1440}
          height={1080}
          context={Context.selfie}
          selfieTimer={5}
          isDeviceErrorToBeShown
          isErrorToBeShown
          isConstraintsWarningToBeShown={false}
          isOrientationWarningToBeShown={false}
          isBackNavigationToBeShown={false}
          containerClassName={styles.camera__container}
          onRetake={() => console.log('Recapturing image')}
          onSubmit={handleSubmit}
          onExit={handleExit}
          isInTestMode={window.isInTestMode}
        />
      </div>

      <Drawer
        id="livenessSelfie"
        isOpen={isDrawerOpen}
        onCloseButtonClick={() => handleDrawer(false)}
        headingText="Last step to get verified"
        subHeadingText="Take a photo of yourself."
        closeButtonText="Ok"
        contentClassName={styles.selfie_drawer__content}
      >
        <div className={styles.tips__container}>
          <img src={info} alt="" className={styles.info} />
          <span className={styles.tips__header}>Tips</span>
          <div className={styles.tips__text}>
            <div>Please don&apos;t have anything covering your face.</div>
            <div>Example: Sunglasses or Hats.</div>
          </div>
        </div>
        <div className={styles.illustrations__container}>
          <span>
            <img src={hat} alt="" />
          </span>
          <span>
            <img src={sunglasses} alt="" />
          </span>
        </div>
        <div>Please ensure you have adequate lighting.</div>
      </Drawer>
      {error.code && renderErrorDialog()}
    </>
  );
};

export default LivenessSelfie;
