/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import { FieldLabel } from '@omni/form';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import stylePropType from 'react-style-proptype';

import { VIEW, ENTRY } from './constants';
import styles from './styles.external.css';

export class Field extends PureComponent {
  render() {
    const {
      id,
      label,
      hideLabel,
      mandatory,
      prefixMandatorySymbol,
      overrideShowMandatorySymbol,
      className,
      style,
      labelClassName,
      fieldClassName,
      fieldLabelClassName,
      fieldElementsClassName,
      mode,
      hasError,
      children,
      disabled,
      labelPosition,
      labelIcon,
      labelInView,
    } = this.props;

    const showMandatorySymbol =
      mandatory && mode !== VIEW && !overrideShowMandatorySymbol;
    const fieldClass = classNames(
      styles.field,
      `field--style--global-${labelPosition}`,
      styles[`field--style-${labelPosition}`],
      { [className]: className },
      { [fieldClassName]: fieldClassName },
    );
    const fieldChildrenClass = classNames(
      styles.field__elements,
      {
        [styles.field__elements__view]:
          labelInView && labelPosition === 'top' && mode === 'VIEW',
      },
      { [fieldElementsClassName]: fieldElementsClassName },
    );
    const labelCSS = hideLabel
      ? classNames(styles.field__label, styles['field__label--hidden'])
      : classNames(styles.field__label, {
          [fieldLabelClassName]: fieldLabelClassName,
        });

    return (
      <div className={fieldClass} style={style} id={`${id}-field`}>
        {label && (
          <FieldLabel
            id={id}
            className={labelCSS}
            label={label}
            hasError={hasError}
            disabled={disabled}
            labelClassName={labelClassName}
            showMandatorySymbol={showMandatorySymbol}
            prefixMandatorySymbol={prefixMandatorySymbol}
            labelIcon={labelIcon}
          />
        )}
        <div className={fieldChildrenClass}>{children}</div>
      </div>
    );
  }
}

Field.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  mandatory: PropTypes.bool,
  prefixMandatorySymbol: PropTypes.bool,
  overrideShowMandatorySymbol: PropTypes.bool,
  className: PropTypes.string,
  fieldClassName: PropTypes.string,
  fieldLabelClassName: PropTypes.string,
  fieldElementsClassName: PropTypes.string,
  style: stylePropType,
  mode: PropTypes.string,
  hasError: PropTypes.bool,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  labelPosition: PropTypes.oneOf(['inline', 'right', 'top', 'left']),
  labelIcon: PropTypes.node,
  labelClassName: PropTypes.string,
  labelInView: PropTypes.bool,
};

Field.defaultProps = {
  mode: ENTRY,
  labelPosition: 'top',
  overrideShowMandatorySymbol: false,
  labelInView: true,
  hideLabel: false,
};

Field.displayName = 'Field';
export default Field;
