/* eslint-disable react/require-default-props */
import { injectValidate } from '@omni/validator';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './InputField.module.css';
import { InputField as OmniInputField } from '../Omni/Form/InputField';

interface Props {
  onChange: (arg0: string, arg1: string) => void;
  id: string;
  label: string;
  placeholder: string;
  value: string;
  maxLength?: number;
  validate?: any;
  setRef: any;
}

const InputField = ({
  id,
  label,
  onChange,
  placeholder,
  value,
  maxLength,
  validate,
  setRef,
  ...props
}: Props) => (
  <OmniInputField
    id={id}
    label={label}
    disabled={false}
    maxLength={maxLength}
    onChange={onChange}
    autoFocus={false}
    placeholder={placeholder}
    value={value}
    mode="ENTRY"
    mandatory
    className={styles.input}
    labelClassName={styles.label}
    fieldClassName={styles.fieldClassName}
    fieldElementsClassName={styles.fieldElements}
    prefixMandatorySymbol
    validate
    setRef={setRef}
    {...props}
  />
);

InputField.propTypes = {
  /** id for input * */
  id: PropTypes.string.isRequired,
  /** event handler for changing the input */
  onChange: PropTypes.func.isRequired,
  /** label */
  label: PropTypes.string.isRequired,
  /** placeholder */
  placeholder: PropTypes.string,
  /** value of the input */
  value: PropTypes.string,
  /** maxLength */
  maxLength: PropTypes.number,
};

InputField.defaultProps = {
  placeholder: '',
  value: '',
};

export default injectValidate(InputField);
