export const TERMS_CONTENT = `If you'd prefer not to proceed with verifying your identity electronically, we can verify your identity in person at any ANZ branch or via other methods as approved by ANZ from time to time.`;
export const TERMS_ELIGIBILITY = `    
(a) be over 18 years of age;
(b) hold a current Australian driver’s licence or Australian passport; and
(c) have a compatible mobile device.
`;
export const TERMS_PERSONAL_INFO_01 = `
(a) ANZ collects personal and sensitive information as part of this eKYC Identity Verification process.
(b) This may occur once the information is entered or provided even if the verification form is not submitted.
(c) ANZ may collect this information from you directly or via service providers who collect your personal information on ANZ’s behalf.  ANZ may also collect your personal information from independent databases, including credit reporting bodies and/or public registers, government agencies/bodies, official data record holders or other sources. 
(d) Without this information, or if this information is inaccurate or incomplete, we may not be able to verify your identity via this service and/or provide you/your organisation with the products or services requested.
`;
export const TERMS_PERSONAL_INFO_02 = `
i. To verify your identity in order to provide you or your organisation with the ANZ products or services you or your organisation has requested; 
ii. To fulfil our legal obligations (such as fulfilling the requirements of anti-money laundering and counter-terrorism financing laws) both before providing you with a product or service and on a periodic basis; and
iii. For customer due diligence, analytics, auditing and risk management purposes. 
`;
export const TERMS_PERSONAL_INFO_03 = `
i. regulatory bodies, government agencies, law enforcement bodies and courts; and
ii. other parties we are authorised or required by law or court/tribunal order to disclose information to.
`;
export const TERMS_PERSONAL_INFO_04 = `
i. Our agents, contractors and/or service providers;  
ii. independent databases, including credit reporting bodies, public registers, data sources and official record holders. For instance:
`;
export const TERMS_PERSONAL_INFO_05 = `
iii. the applicant who nominated you/your organisation, your representative or any other party you have asked us to deal with as part of your application (eg: accountant or lawyer); 
iv. Our authorised banking network including any person who introduces you to ANZ such as an ANZ mobile lender or ANZ approved originator; 
v. ANZ’s related entities;
vi. any organisation that assists ANZ to identify, prevent or investigate any fraud, unlawful activity or misconduct (or suspected fraud, unlawful activity or misconduct);
vii. our professional advisors, insurers, alliance partners;
viii. regulatory bodies, government agencies, law enforcement bodies and courts; and
ix. other parties we are authorised or required by law or court/tribunal order to disclose information to.
`;
export const TERMS_CONSENT = `
(a) You are authorised to provide the personal details that you provide to ANZ through eKYC, and you consent to the information being checked with the document issuer or official record holder via third party systems for the purpose of confirming your identity.
(b) ANZ may disclose your name, residential address and/or date of birth to Equifax Australia (a credit reporting body) for the purposes of having Equifax determine the extent to which this information matches (in whole or in part) the information that they hold in their records;
(c) For the avoidance of doubt, ANZ will not, in disclosing the above, access your credit report nor will the disclosure appear in your credit file or impact your credit rating;
(d) Equifax Australia may use your personal information in conjunction with other personal information they hold about other individuals (being their names, dates of birth and addresses) for the purpose of their assessment; and
(e) Equifax Australia will prepare and provide (via third party systems) an electronic verification report to ANZ on their findings.
`;
export const TERMS_ACKNOWLEDGEMENT_01 = `
(a) acknowledge that ANZ may suspend or stop use of eKYC at any time, but if this happens, you can verify your identity in other ways; 
(b) declare that to the best of your knowledge the information you have provided is true and correct at the date of your application; and you understand that providing a false or misleading information is an offence under the Anti-Money Laundering and Counter Terrorism Financing Act 2006 (AML/CTF Act);
(c) authorise ANZ to notify the applicant who nominated you if you have passed or failed e-verification;
(d) represent and warrant to ANZ that:
`;
export const TERMS_ACKNOWLEDGEMENT_02 = `
i. subject to clause 6(d)ii, you are the person who is required to be identified for the purposes of the application made with ANZ and you are not providing information, or making this consent, on behalf of a third party;
ii. if you are not the person who is required to be identified for the purposes of the application made to ANZ but are providing information or making this consent on behalf of a third party in an arrangement permitted by ANZ, the third party has expressly agreed to the terms of this consent; and
iii. you have the authority to give this consent and to provide any personal information that you disclose or make available to ANZ.
`;
export const ANZ_PRIVACY_POLICY_LINK = `
https://www.anz.com.au/privacy/centre/policy/`;
export const ANZ_PRIVACY_CENTRE_LINK = `
https://www.anz.com.au/privacy/centre/`;
export const ANZ_EKYC_CONDITIONS_LINK = `
https://www.anz.com/institutional/digital-services/ekyc/conditions/`;
