import React from 'react';

import {
  CX_DESCRIPTION,
  CX_HEADER,
  CX_RESOURCES_HEADER,
  CX_RESOURCES_LINKS,
} from './constants';
import styles from './CxSection.module.css';
import illustration from './images/illustration.svg';

const CxSection = () => (
  <div className={styles.cxsection__container}>
    <div className={styles.cxsummary__container}>
      <div className={styles.cxsummary__text__container}>
        <div className={styles.cxsummary__header}>
          <span>{CX_HEADER}</span>
        </div>
        <div className={styles.cxsummary__description}>
          <span>{CX_DESCRIPTION}</span>
        </div>
      </div>
      <div>
        <img src={illustration} alt="" className={styles.illustration} />
      </div>
    </div>
    <div className={styles.cxresources__container}>
      <div className={styles.cxresources__header}>{CX_RESOURCES_HEADER}</div>
      {CX_RESOURCES_LINKS.map(({ linkText, url, dataTestId }) => (
        <div
          className={styles.cxresources__link__container}
          key={linkText}
          data-testid={dataTestId}
        >
          <a
            href={url}
            className={styles.cxresources__link}
            target="_blank"
            rel="noopener noreferrer"
            key={linkText}
          >
            {linkText}
          </a>
        </div>
      ))}
    </div>
  </div>
);

export default CxSection;
