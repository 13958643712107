import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import sessionReducer from '../common/components/Session/sessionSlice';
import identityReducer from '../screens/IdentityUpload/identityUploadSlice';
import scanReducer from '../screens/IdentityUpload/ScanDocument/scanDocumentSlice';
import documentReducer from '../screens/IdentityUpload/SelectDocumentForm/selectDocumentFormSlice';
import verifyDetailsReducer from '../screens/IdentityUpload/VerifyDetailsForm/verifyDetailsFormSlice';
import livenessSelfieReducer from '../screens/LivenessSelfie/livenessSelfieSlice';

export const store = configureStore({
  reducer: {
    session: sessionReducer,
    document: documentReducer,
    identity: identityReducer,
    verifyDetails: verifyDetailsReducer,
    scan: scanReducer,
    liveness: livenessSelfieReducer,
  },
  devTools: process.env.NODE_ENV === 'development',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
