import React from 'react';

interface ArrowIconProps {
  id: string;
  className: string;
  color: string;
  direction: 'left' | 'right';
  onClick?: () => void;
}

const ArrowIcon: React.FC<ArrowIconProps> = ({
  className,
  color,
  direction,
  onClick,
  id,
}) => {
  const pathData =
    direction === 'left'
      ? 'M8.913 3.05176e-05C9.2 3.05176e-05 9.474 0.12303 9.663 0.339031C9.839 0.537031 9.928 0.794031 9.911 1.06103C9.895 1.32803 9.775 1.57203 9.575 1.74903L2.51 7.99903L9.575 14.25C9.775 14.427 9.895 14.671 9.911 14.938C9.928 15.205 9.839 15.462 9.661 15.662C9.475 15.875 9.201 16 8.913 16C8.668 16 8.431 15.909 8.248 15.745L0.338 8.74803C0.123 8.56003 0 8.28603 0 7.99903C0 7.71303 0.123 7.43903 0.339 7.25003L8.251 0.251031C8.431 0.0900305 8.667 3.05176e-05 8.913 3.05176e-05Z'
      : 'M1.00009 0C0.713086 0 0.439086 0.123 0.250086 0.339C0.0740862 0.537 -0.0149145 0.794 0.00208569 1.061C0.0180855 1.328 0.138086 1.572 0.338086 1.749L7.40309 7.999L0.338086 14.25C0.138086 14.427 0.0180855 14.671 0.00208569 14.938C-0.0149145 15.205 0.0740852 15.462 0.252086 15.662C0.438086 15.875 0.712086 16 1.00009 16C1.24509 16 1.48209 15.909 1.66509 15.745L9.57509 8.748C9.79009 8.56 9.91309 8.286 9.91309 7.999C9.91309 7.713 9.79009 7.439 9.57409 7.25L1.66209 0.251C1.48209 0.09 1.24609 0 1.00009 0Z';

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <div
      id={id}
      data-testId={id}
      className={className}
      role="button"
      tabIndex={0}
      onClick={handleClick}
      onKeyDown={(e) => e.key === 'Enter' && handleClick()}
    >
      <svg
        width="10"
        height="16"
        viewBox="0 0 10 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d={pathData} fill={color} />
      </svg>
    </div>
  );
};

ArrowIcon.defaultProps = {
  onClick: undefined,
};

export default ArrowIcon;
