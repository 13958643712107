/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
import { injectValidate } from '@omni/validator';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

import styles from './DatePicker.module.css';

interface Props {
  id: string;
  name: string;
  label: string;
  minDate?: string;
  maxDate: string;
  value: string;
  errors: string[];
  style: any;
  onChange: (name: string, value: any) => void;
  setRef?: (ref: any) => void;
  props?: any;
}

const DatePicker = ({
  id,
  name,
  label,
  errors,
  minDate,
  maxDate,
  value,
  style,
  onChange,
  setRef,
  ...props
}: Props) => {
  const inputRef = useRef<any | HTMLInputElement>(null);

  useEffect(() => {
    if (setRef) {
      setRef({ input: inputRef.current });
    }
  }, [setRef]);

  const handleDateChange = (event: any) => {
    const newValue = event.target.value;
    if (!isValidDate(newValue)) {
      onChange(event.target.name, '');
    } else {
      onChange(event.target.name, newValue);
    }
  };

  const isValidDate = (newValue: string) =>
    moment(newValue, 'YYYY-MM-DD', true).isValid();

  const datePickerClass = classNames(styles.datepicker, {
    [styles['datepicker--error']]: !isEmpty(errors),
  });

  const labelClass = classNames(styles.label, {
    [styles['label--error']]: !isEmpty(errors),
  });

  return (
    <div className={styles.datepicker__container}>
      <label htmlFor={id} className={labelClass}>
        {label}
      </label>
      <input
        className={datePickerClass}
        type="date"
        id={id}
        name={name}
        value={value}
        min={minDate}
        max={maxDate}
        style={style}
        onChange={handleDateChange}
        ref={inputRef}
        {...props}
      />
      {!isEmpty(errors) && <div className={styles.error__text}>{errors}</div>}
    </div>
  );
};

DatePicker.propTypes = {
  /** unique id for datepicker */
  id: PropTypes.string,
  /** name for datepicker */
  name: PropTypes.string,
  /** label for datepicker */
  label: PropTypes.string,
  /** minimum date to show on datepicker. */
  minDate: PropTypes.string,
  /** maximum date to show on datepicker. Validations enforced */
  maxDate: PropTypes.string,
  /** current date value of the datepicker from parent state */
  value: PropTypes.string,
  /** handler for on date chnage */
  onChange: PropTypes.func,
  /** Callback funciton to set reference */
  setRef: PropTypes.func,
};

export default injectValidate(DatePicker);
