/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-unused-expressions */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import styles from './styles.external.css';

export class Radio extends PureComponent {
  onChange = ({ target }) => {
    const { onChange } = this.props;
    onChange && onChange(target.name, target.value);
  };

  onLabelClick = () => {
    const { id, value, onChange, disabled } = this.props;
    if (!disabled) {
      onChange && onChange(id, value);
    }
  };

  render() {
    const {
      id,
      value,
      label,
      onChange,
      disabled,
      hasError,
      fieldsetType,
      ...props
    } = this.props;
    const divClass = classNames(styles['radio-element'], {
      [styles[`radio-element--in-${fieldsetType}group`]]: fieldsetType,
    });
    const labelClass = classNames(
      styles.label,
      styles['label--is-description'],
      styles['radio-element__label'],
      {
        [styles['label--has-error']]: hasError,
        [styles['label--is-disabled']]: disabled,
      },
    );
    return (
      <div className={divClass}>
        <input
          type="radio"
          name={id}
          aria-label={label}
          onChange={this.onChange}
          value={value}
          className={styles['form-control-radio']}
          disabled={disabled}
          {...props}
        />
        <label className={labelClass} onClick={this.onLabelClick} htmlFor={id}>
          {label}
        </label>
      </div>
    );
  }
}

Radio.propTypes = {
  /** Component ID  */
  id: PropTypes.string,
  /** Trigger this callback function when there is a change in radio status */
  onChange: PropTypes.func,
  /** This will add as Radio value */
  value: PropTypes.string.isRequired,
  /** This string will show as a Radio label. */
  label: PropTypes.string.isRequired,
  /** will make the Radio label red to indicate the error */
  hasError: PropTypes.bool,
  /** To arrange a group of Radio buttons in inlinegroup or stackedgroup */
  fieldsetType: PropTypes.oneOf(['stacked', 'inline']),
  /** render the radio button into READ ONLY mode */
  disabled: PropTypes.bool,
};
Radio.displayName = 'Radio';
export default Radio;
