/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
import { injectValidate } from '@omni/validator';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

import styles from './DateInput.module.css';
import { MONTHS_LIST } from '../../../screens/IdentityUpload/VerifyDetailsForm/constants';
import { SelectField, InputField } from '../Omni/Form';

interface Props {
  id: string;
  childIds: string[];
  label: string;
  value: string[];
  errors: string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setRef?: (ref: any) => void;
}

const DateInput = ({
  id,
  childIds,
  label,
  errors,
  value,
  onChange,
  setRef,
}: Props) => {
  const inputRef = useRef<any | HTMLInputElement>(null);

  useEffect(() => {
    if (setRef) {
      setRef({ input: inputRef.current.input });
    }
  }, [setRef]);

  const dateInputClass = classNames(styles.dateInput, {
    [styles['dateInput--error']]: !isEmpty(errors),
  });

  const labelClass = classNames(styles.label, {
    [styles['label--error']]: !isEmpty(errors),
  });

  return (
    <div className={styles.dateInput__container}>
      <label htmlFor={id} className={labelClass}>
        {label}
      </label>
      <div className={styles.dateFields__container}>
        <InputField
          id={childIds[0]}
          data-testid={childIds[0]}
          fieldsetType="inline"
          className={dateInputClass}
          fieldStyle={{ flex: 1, padding: 0 }}
          placeholder="DD"
          // value[0] is date of birth date value
          value={value[0]}
          onChange={onChange}
          maxLength={2}
        />
        <SelectField
          id={childIds[1]}
          data-testid={childIds[1]}
          dataSource={MONTHS_LIST}
          fieldsetType="inline"
          className={dateInputClass}
          // value[1] is date of birth month value
          value={value[1]}
          onChange={onChange}
          style={{
            width: '100%',
            marginTop: '11px',
            padding: '3px 3px',
          }}
          fieldStyle={{ flex: 4, padding: 0 }}
          setRef={(ref: any) => {
            inputRef.current = ref;
            return ref;
          }}
        >
          <option value="">Select</option>
        </SelectField>
        <InputField
          id={childIds[2]}
          data-testid={childIds[2]}
          fieldsetType="inline"
          className={dateInputClass}
          fieldStyle={{ flex: 2, padding: 0 }}
          placeholder="YYYY"
          // value[2] is date of birth year value
          value={value[2]}
          onChange={onChange}
          maxLength={4}
        />
      </div>
      {!isEmpty(errors) && (
        <div className={styles.error__text}>{errors[0]}</div>
      )}
    </div>
  );
};

DateInput.propTypes = {
  /** unique id for dateInput */
  id: PropTypes.string,
  /** label for dateInput */
  label: PropTypes.string,

  /** current date value of the dateInput from parent state */
  value: PropTypes.string,
  /** handler for on date change */
  onChange: PropTypes.func,
  /** Callback funciton to set reference */
  setRef: PropTypes.func,
};

export default injectValidate(DateInput);
