/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import { ElementWrapper } from '@omni/form';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { VIEW, READ_ONLY } from './constants';
import styles from './styles.external.css';

import { RefForward } from '.';

export class Textarea extends PureComponent {
  onChange = ({ target }) => {
    const { onChange } = this.props;
    // eslint-disable-next-line no-unused-expressions
    onChange && onChange(target.name, target.value);
  };

  render() {
    const {
      id,
      disabled,
      wrapperClassName,
      errors,
      note,
      notePosition,
      mandatory,
      mode,
      onChange,
      value,
      ...props
    } = this.props;
    const disableTextarea = !!(mode === VIEW || disabled);
    const selectedClass = classNames(styles['form-control'], {
      [styles['form-control--in-labelgroup']]: !_.isEmpty(note),
      [styles['form-control--has-error']]: !_.isEmpty(errors),
      [styles['form-control__view']]: mode === VIEW,
    });
    const ariaLabelledBy = note
      ? `${id}-label ${id}-desc ${id}-error`
      : `${id}-label ${id}-error`;

    if (mode === READ_ONLY) {
      return (
        <ElementWrapper
          id={id}
          className={wrapperClassName}
          errors={errors}
          note={note}
          notePosition={notePosition}
        >
          <textarea
            id={id}
            ref={(ref) => (this.input = ref)}
            aria-labelledby={ariaLabelledBy}
            name={id}
            value={value}
            className={selectedClass}
            onChange={this.onChange}
            aria-required={mandatory}
            readOnly
            aria-readonly
            {...props}
          />
        </ElementWrapper>
      );
    }
    return (
      <ElementWrapper
        id={id}
        className={wrapperClassName}
        errors={errors}
        note={note}
        notePosition={notePosition}
      >
        <textarea
          id={id}
          ref={(ref) => (this.input = ref)}
          aria-labelledby={ariaLabelledBy}
          name={id}
          value={value}
          className={selectedClass}
          onChange={this.onChange}
          disabled={disableTextarea}
          aria-required={mandatory}
          {...props}
        />
      </ElementWrapper>
    );
  }
}

Textarea.propTypes = {
  /** Component ID */
  id: PropTypes.string.isRequired,
  /** Option to pass the wrapper component CSS classname. */
  wrapperClassName: PropTypes.string,
  /** List of error messages for this Component  */
  errors: PropTypes.array,
  /** To show a note about the Component, will apprear on the right of the component */
  note: PropTypes.string,
  /** Used to set aria-required attribute */
  mandatory: PropTypes.bool,
  /** This will decide the mode of the component ENTRY, EDIT or VIEW */
  mode: PropTypes.string,
  /** Trigger this callback function when there is a change in radio status  */
  onChange: PropTypes.func,
  /** Note Position */
  notePosition: PropTypes.oneOf(['inline', 'block']),
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

Textarea.displayName = 'Textarea';
export default RefForward(Textarea);
