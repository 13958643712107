/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import { Label, ElementWrapper } from '@omni/form';
import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { VIEW } from './constants';
import styles from './styles.external.css';

import { RefForward } from '.';

export class Select extends PureComponent {
  onChange = ({ target }) => {
    const { onChange, multiple } = this.props;
    if (onChange) {
      if (multiple) {
        const values = Array.from(
          target.selectedOptions,
          (option) => option.value,
        );
        onChange(target.name, values);
      } else {
        onChange(target.name, target.value);
      }
    }
  };

  renderViewMode = () => {
    const {
      id,
      className,
      style,
      wrapperClassName,
      dataSource,
      value,
      children,
      errors,
      note,
      notePosition,
      noteInViewMode,
      fieldsetType,
    } = this.props;
    let selectedValue = value;
    if (dataSource && dataSource.length) {
      const selectedItem = dataSource.find((item) => item.value === value);
      selectedValue = selectedItem ? selectedItem.label : '';
      if (!selectedValue) {
        const selectedChild = React.Children.toArray(children).find(
          (child) => child.props.value === value,
        );
        selectedValue = selectedChild ? selectedChild.props.children : '';
      }
    }
    return (
      <ElementWrapper
        className={wrapperClassName}
        errors={errors}
        {...(noteInViewMode && { note, notePosition })}
      >
        <Label
          id={id}
          value={selectedValue}
          className={className}
          style={style}
          fieldsetType={fieldsetType}
        />
      </ElementWrapper>
    );
  };

  renderGroupOptions = () => {
    const { dataSource } = this.props;
    const groupItems = _.groupBy(dataSource, 'group');
    return Object.keys(groupItems).map((group) => (
      <optgroup key={group} label={group}>
        {this.renderOptions(groupItems[group])}
      </optgroup>
    ));
  };

  renderOptions = (data) =>
    data.map((item, index) => (
      <option key={index} value={item.value} disabled={item.disabled || false}>
        {item.label}
      </option>
    ));

  render() {
    const {
      id,
      mode,
      dataSource,
      wrapperClassName,
      errors,
      note,
      notePosition,
      noteInViewMode,
      grouping,
      mandatory,
      disabled,
      value,
      multiple,
      className,
      style,
      fieldsetType,
      children,
      onChange,
      ...props
    } = this.props;

    if (mode === VIEW && !multiple) {
      return this.renderViewMode();
    }

    const divClass = classNames(styles['custom-select'], {
      [styles[`custom-select--in-labelgroup`]]: note,
      [styles[`custom-select--in-${fieldsetType}group`]]: !note && fieldsetType,
      [styles['custom-select--is-disabled']]: disabled,
    });
    const selectClass = classNames(
      styles['form-control'],
      styles['custom-select__select'],
      {
        [className]: className,
        [styles['form-control--has-error']]: !_.isEmpty(errors),
      },
    );
    const labelClass = classNames({
      [styles[`label-group--in-${fieldsetType}group`]]: note && fieldsetType,
    });
    const ariaLabelledBy = note
      ? `${id}-label ${id}-desc ${id}-error`
      : `${id}-label ${id}-error`;
    const multipleViewStyle =
      mode === VIEW && multiple
        ? { marginTop: '1.5em', verticalAlign: 'middle' }
        : {};
    return (
      <ElementWrapper
        id={id}
        className={wrapperClassName}
        labelClassName={labelClass}
        errors={errors}
        note={note}
        notePosition={notePosition}
      >
        <div className={divClass} style={{ ...style, ...multipleViewStyle }}>
          <select
            id={id}
            ref={(ref) => (this.input = ref)}
            aria-labelledby={ariaLabelledBy}
            name={id}
            value={value}
            multiple={multiple}
            className={selectClass}
            onChange={this.onChange}
            disabled={mode === VIEW && multiple ? true : disabled}
            aria-required={mandatory}
            {...props}
          >
            {children}
            {grouping
              ? this.renderGroupOptions()
              : this.renderOptions(dataSource)}
          </select>
          {!multiple && (
            <div className={styles['custom-select__arrow']}>
              <i className="fa fa-chevron-down" aria-hidden="true" />
            </div>
          )}
        </div>
      </ElementWrapper>
    );
  }
}

Select.propTypes = {
  /** Component ID  */
  id: PropTypes.string.isRequired,
  /** This will decide the mode of the component ENTRY, EDIT or VIEW */
  mode: PropTypes.string,
  /**  List of options to be displayed in the dropdown menu */
  dataSource: PropTypes.array,
  /** Option to pass the wrapper component CSS classname. */
  wrapperClassName: PropTypes.string,
  /** List of error messages for this Component */
  errors: PropTypes.array,
  /** To show a note about the Component, will appear on the right of the component */
  note: PropTypes.string,
  /**  To arrange a group of input components in inlinegroup or stackedgroup */
  fieldsetType: PropTypes.oneOf(['stacked', 'inline']),
  /** allow us to create optiongroup in select options */
  grouping: PropTypes.bool,
  /** Used to set aria-required attribute */
  mandatory: PropTypes.bool,
  /** This dictates child components  */
  children: PropTypes.node,
  /** Multiple Selection */
  multiple: PropTypes.bool,
  /** Value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /** Note Position */
  notePosition: PropTypes.oneOf(['inline', 'block']),
  /** Note in View Mode */
  noteInViewMode: PropTypes.bool,
};

Select.displayName = 'Select';
Select.defaultProps = {
  noteInViewMode: false,
};
export default RefForward(Select);
