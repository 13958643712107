import licenceImage1NT from '../images/licenceImage1NT.svg';
import licenceImage1QLD from '../images/licenceImage1QLD.svg';
import licenceImage1VIC from '../images/licenceImage1VIC.svg';
import licenceImage2NT from '../images/licenceImage2NT.svg';
import licenceImage2QLD from '../images/licenceImage2QLD.svg';
import licenceImage2VIC from '../images/licenceImage2VIC.svg';
import licenceImageACT from '../images/licenceImageACT.svg';
import licenceImageNSW from '../images/licenceImageNSW.svg';
import licenceImageSA from '../images/licenceImageSA.svg';
import licenceImageTAS from '../images/licenceImageTAS.svg';
import licenceImageWA from '../images/licenceImageWA.svg';

export const CUSTOMER_DETAILS_INFO =
  'Please check your full legal name, residential address and all other details are entered and correct.';
export const CUSTOMER_AGREEMENT =
  'By submitting your details, you confirm they are your own and consent for us to share the information with the document issuer or official record holder via third party systems for the purpose of confirming your identity.';

export const CUSTOMER_NAME_CHECK = 'I do not have a middle name';

export const GENDER_LIST = [
  { value: 'F', label: 'Female' },
  { value: 'M', label: 'Male' },
  { value: 'N', label: 'Non-Binary' },
  { value: 'U', label: 'Prefer not to say' },
];

export const OCCUPATION_LIST = [
  { value: '221100', label: 'Accountants' },
  { value: '551111', label: 'Accounts Clerk' },
  {
    value: '131000',
    label: 'Advertising, Public Relations and Sales Managers',
  },
  { value: '311000', label: 'Agricultural, Medical and Science Technicians' },
  { value: '231000', label: 'Air and Marine Transport Professionals' },
  { value: '111900', label: 'Ambassador and Consulate General' },
  { value: '142112', label: 'Antique Dealer' },
  { value: '232000', label: 'Architects, Designers, Planners and Surveyors' },
  { value: '210000', label: 'Arts and Media Professionals' },
  {
    value: '221200',
    label: 'Auditors, Company Secretaries and Corporate Treasurers',
  },
  { value: '552111', label: 'Bank Worker' },
  { value: '431111', label: 'Bar Attendant' },
  { value: '271111', label: 'Barrister' },
  { value: '142113', label: 'Betting Agency Manager' },
  { value: '561100', label: 'Betting Clerks' },
  { value: '551211', label: 'Bookkeeper' },
  { value: '731211', label: 'Bus Driver' },
  { value: '132000', label: 'Business Administration Managers' },
  { value: '420000', label: 'Carers and Aides' },
  { value: '631000', label: 'Checkout Operators and Office Cashiers' },
  { value: '111111', label: 'Chief Executive or Managing Director' },
  { value: '810000', label: 'Cleaners and Laundry Workers' },
  { value: '139111', label: 'Commissioned Defence Force Officer' },
  { value: '139112', label: 'Commissioned Fire Officer' },
  { value: '139113', label: 'Commissioned Police Officer' },
  { value: '820000', label: 'Construction and Mining Labourers' },
  { value: '330000', label: 'Construction Trades Workers' },
  {
    value: '133000',
    label: 'Construction, Distribution and Production Managers',
  },
  { value: '599100', label: 'Conveyancers and Legal Executives' },
  { value: '561200', label: 'Couriers and Postal Deliverers' },
  { value: '441111', label: 'Defence Force Member - Other Ranks' },
  { value: '111212', label: 'Defence Force Senior Officer' },
  { value: '252312', label: 'Dentist' },
  { value: '241100', label: 'Early Childhood (Pre-primary School) Teacher' },
  { value: '134000', label: 'Education, Health and Welfare Services Managers' },
  {
    value: '340000',
    label: 'Electrotechnology and Telecommunications Trades Workers',
  },
  { value: '233000', label: 'Engineering Professionals' },
  { value: '830000', label: 'Factory Process Workers' },
  { value: '841000', label: 'Farm, Forestry and Garden Workers' },
  { value: '121000', label: 'Farmers and Farm Managers' },
  {
    value: '222000',
    label: 'Financial Brokers and Dealers, Investment Advisers and Insurance',
  },
  { value: '850000', label: 'Food Preparation Assistants' },
  { value: '350000', label: 'Food Trades Workers' },
  { value: '431311', label: 'Gaming Worker' },
  { value: '530000', label: 'General Clerical Workers' },
  { value: '111200', label: 'General Managers' },
  { value: '139800', label: `Gov't/ Public Service - Management` },
  { value: '323312', label: 'Gunsmith' },
  { value: '410000', label: 'Health and Welfare Support Workers' },
  { value: '251000', label: 'Health Diagnostic and Promotion Professionals' },
  { value: '99950', label: 'House wife/husband' },
  { value: '223000', label: 'Human Resource and Training Professionals' },
  { value: '261100', label: 'ICT Business and Systems Analysts' },
  { value: '224000', label: 'Information and Organisation Professionals' },
  { value: '540000', label: 'Inquiry Clerks and Receptionists' },
  { value: '399411', label: 'Jeweller' },
  { value: '212400', label: 'Journalists and Other Writers' },
  { value: '271211', label: 'Judge' },
  { value: '111399', label: 'Legislators nec' },
  { value: '111311', label: 'Local Government Legislator' },
  { value: '271212', label: 'Magistrate' },
  { value: '323000', label: 'Mechanical Engineering Trades Workers' },
  { value: '253000', label: 'Medical Practitioners' },
  { value: '111312', label: 'Member of Parliament' },
  { value: '254000', label: 'Midwifery and Nursing Professionals' },
  {
    value: '224913',
    label: 'Migration Agent (Aus) \\ Immigration Consultant (NZ)',
  },
  { value: '321200', label: 'Motor Mechanics' },
  { value: '261200', label: 'Multimedia Specialists and Web Developers' },
  { value: '234000', label: 'Natural and Physical Science Professionals' },
  { value: '561900', label: 'Other Clerical and Office Support Workers' },
  {
    value: '251900',
    label: 'Other Health Diagnostic and Promotion Professionals',
  },
  { value: '252000', label: 'Other Health Therapy Professionals' },
  { value: '431900', label: 'Other Hospitality Workers' },
  { value: '140000', label: 'Other Hospitality, Retail and Service Managers' },
  { value: '890000', label: 'Other Labourers' },
  { value: '271000', label: 'Other Legal Professionals' },
  { value: '700000', label: 'Other Machinery Operators and Drivers' },
  { value: '451800', label: 'Other Personal Service Workers' },
  { value: '139900', label: 'Other Specialist Managers' },
  { value: '390000', label: 'Other Technicians and Trades Workers' },
  { value: '551311', label: 'Payroll Clerk' },
  { value: '99900', label: 'Pensioner' },
  { value: '521000', label: 'Personal Assistants and Secretaries' },
  { value: '441312', label: 'Police Officer' },
  { value: '142115', label: 'Post Office Manager' },
  { value: '241200', label: 'Primary School Teacher' },
  { value: '249200', label: 'Private Tutors and Teachers' },
  { value: '612112', label: 'Property Manager' },
  { value: '440000', label: 'Protective Service Workers' },
  { value: '591100', label: 'Purchasing and Supply Logistics Clerks' },
  {
    value: '612113',
    label:
      'Real Estate Agency Principal (Aus) \\ Real Estate Agency Licensee (NZ)',
  },
  { value: '612114', label: 'Real Estate Agent' },
  { value: '451816', label: 'Religious Assistant' },
  { value: '621000', label: 'Sales Assistants and Salespersons' },
  { value: '611300', label: 'Sales Representatives' },
  {
    value: '225000',
    label: 'Sales, Marketing and Public Relations Professionals',
  },
  { value: '241400', label: 'Secondary School Teacher' },
  { value: '99980', label: 'Self-Employed' },
  { value: '139211', label: 'Senior Non-commissioned Defence Force Member' },
  { value: '272000', label: 'Social and Welfare Professionals' },
  { value: '261300', label: 'Software and Applications Programmers' },
  { value: '271311', label: 'Solicitor' },
  { value: '452000', label: 'Sports and Fitness Workers' },
  { value: '99910', label: 'Student' },
  { value: '731112', label: 'Taxi Driver' },
  { value: '451600', label: 'Tourism and Travel Advisers' },
  { value: '142116', label: 'Travel Agency Manager' },
  { value: '242100', label: 'University Lecturers and Tutors' },
  {
    value: '242200',
    label: 'Vocational Education Teachers (Aus) \\ Polytechnic Teachers (NZ)',
  },
  { value: '431511', label: 'Waiter' },
];

export const RESIDENTIAL_COUNTRY_LIST_AU_ONLY = [
  { value: 'AU', label: 'Australia' },
];
export const RESIDENTIAL_COUNTRY_LIST = [
  { value: 'AU', label: 'Australia' },
  { value: 'NZ', label: 'New Zealand' },
];

export const COUNTRY_LIST = [
  { value: 'AU', label: 'Australia' },
  { value: 'NZ', label: 'New Zealand' },
  { value: 'AF', label: 'Afghanistan' },
  { value: 'AX', label: 'Aland Islands' },
  { value: 'AL', label: 'Albania' },
  { value: 'DZ', label: 'Algeria' },
  { value: 'AS', label: 'American Samoa' },
  { value: 'AD', label: 'Andorra' },
  { value: 'AO', label: 'Angola' },
  { value: 'AI', label: 'Anguilla' },
  { value: 'AQ', label: 'Antarctica' },
  { value: 'AG', label: 'Antigua And Barbuda' },
  { value: 'AR', label: 'Argentina' },
  { value: 'AM', label: 'Armenia' },
  { value: 'AW', label: 'Aruba' },
  { value: 'AT', label: 'Austria' },
  { value: 'AZ', label: 'Azerbaijan' },
  { value: 'BS', label: 'Bahamas' },
  { value: 'BH', label: 'Bahrain' },
  { value: 'BD', label: 'Bangladesh' },
  { value: 'BB', label: 'Barbados' },
  { value: 'BY', label: 'Belarus' },
  { value: 'BE', label: 'Belgium' },
  { value: 'BZ', label: 'Belize' },
  { value: 'BJ', label: 'Benin' },
  { value: 'BM', label: 'Bermuda' },
  { value: 'BT', label: 'Bhutan' },
  { value: 'BO', label: 'Bolivia, Plurinational State Of' },
  { value: 'BQ', label: 'Bonaire, Sint Eustatius And Saba' },
  { value: 'BA', label: 'Bosnia And Herzegovina' },
  { value: 'BW', label: 'Botswana' },
  { value: 'BV', label: 'Bouvet Island' },
  { value: 'BR', label: 'Brazil' },
  { value: 'IO', label: 'British Indian Ocean Territory' },
  { value: 'BN', label: 'Brunei Darussalam' },
  { value: 'BG', label: 'Bulgaria' },
  { value: 'BF', label: 'Burkina Faso' },
  { value: 'BI', label: 'Burundi' },
  { value: 'KH', label: 'Cambodia' },
  { value: 'CM', label: 'Cameroon' },
  { value: 'CA', label: 'Canada' },
  { value: 'CV', label: 'Cabo Verde' },
  { value: 'KY', label: 'Cayman Islands' },
  { value: 'CF', label: 'Central African Republic' },
  { value: 'TD', label: 'Chad' },
  { value: 'CL', label: 'Chile' },
  { value: 'CN', label: 'China' },
  { value: 'CX', label: 'Christmas Island' },
  { value: 'CC', label: 'Cocos (Keeling) Islands' },
  { value: 'CO', label: 'Colombia' },
  { value: 'KM', label: 'Comoros' },
  { value: 'CG', label: 'Congo' },
  { value: 'CD', label: 'Congo,The Democratic Republic Of The' },
  { value: 'CK', label: 'Cook Islands' },
  { value: 'CR', label: 'Costa Rica' },
  { value: 'CI', label: `Cote D'Ivoire` },
  { value: 'HR', label: 'Croatia' },
  { value: 'CW', label: 'Curacao' },
  { value: 'CY', label: 'Cyprus' },
  { value: 'CZ', label: 'Czech Republic' },
  { value: 'DK', label: 'Denmark' },
  { value: 'DJ', label: 'Djibouti' },
  { value: 'DM', label: 'Dominica' },
  { value: 'DO', label: 'Dominican Republic' },
  { value: 'EC', label: 'Ecuador' },
  { value: 'EG', label: 'Egypt' },
  { value: 'SV', label: 'El Salvador' },
  { value: 'GQ', label: 'Equatorial Guinea' },
  { value: 'ER', label: 'Eritrea' },
  { value: 'EE', label: 'Estonia' },
  { value: 'SZ', label: 'Eswatini' },
  { value: 'ET', label: 'Ethiopia' },
  { value: 'FK', label: 'Falkland Islands (Malvinas)' },
  { value: 'FO', label: 'Faroe Islands' },
  { value: 'FJ', label: 'Fiji' },
  { value: 'FI', label: 'Finland' },
  { value: 'FR', label: 'France' },
  { value: 'GF', label: 'French Guiana' },
  { value: 'PF', label: 'French Polynesia' },
  { value: 'TF', label: 'French Southern Territories' },
  { value: 'GA', label: 'Gabon' },
  { value: 'GM', label: 'Gambia' },
  { value: 'GE', label: 'Georgia' },
  { value: 'DE', label: 'Germany' },
  { value: 'GH', label: 'Ghana' },
  { value: 'GI', label: 'Gibraltar' },
  { value: 'GR', label: 'Greece' },
  { value: 'GL', label: 'Greenland' },
  { value: 'GD', label: 'Grenada' },
  { value: 'GP', label: 'Guadeloupe' },
  { value: 'GU', label: 'Guam' },
  { value: 'GT', label: 'Guatemala' },
  { value: 'GG', label: 'Guernsey' },
  { value: 'GN', label: 'Guinea' },
  { value: 'GW', label: 'Guinea-Bissau' },
  { value: 'GY', label: 'Guyana' },
  { value: 'HT', label: 'Haiti' },
  { value: 'HM', label: 'Heard Island And Mcdonald Islands' },
  { value: 'VA', label: 'Holy See (Vatican City State)' },
  { value: 'HN', label: 'Honduras' },
  { value: 'HK', label: 'Hong Kong' },
  { value: 'HU', label: 'Hungary' },
  { value: 'IS', label: 'Iceland' },
  { value: 'IN', label: 'India' },
  { value: 'ID', label: 'Indonesia' },
  { value: 'IQ', label: 'Iraq' },
  { value: 'IE', label: 'Ireland' },
  { value: 'IM', label: 'Isle Of Man' },
  { value: 'IL', label: 'Israel' },
  { value: 'IT', label: 'Italy' },
  { value: 'JM', label: 'Jamaica' },
  { value: 'JP', label: 'Japan' },
  { value: 'JE', label: 'Jersey' },
  { value: 'JO', label: 'Jordan' },
  { value: 'KZ', label: 'Kazakhstan' },
  { value: 'KE', label: 'Kenya' },
  { value: 'KI', label: 'Kiribati' },
  { value: 'XK', label: 'Kosovo' },
  { value: 'KW', label: 'Kuwait' },
  { value: 'KG', label: 'Kyrgyzstan' },
  { value: 'LA', label: 'Lao Peoples Democratic Republic' },
  { value: 'LV', label: 'Latvia' },
  { value: 'LB', label: 'Lebanon' },
  { value: 'LS', label: 'Lesotho' },
  { value: 'LR', label: 'Liberia' },
  { value: 'LY', label: 'Libyan Arab Jamahiriya' },
  { value: 'LI', label: 'Liechtenstein' },
  { value: 'LT', label: 'Lithuania' },
  { value: 'LU', label: 'Luxembourg' },
  { value: 'MO', label: 'Macao' },
  { value: 'MG', label: 'Madagascar' },
  { value: 'MW', label: 'Malawi' },
  { value: 'MY', label: 'Malaysia' },
  { value: 'MV', label: 'Maldives' },
  { value: 'ML', label: 'Mali' },
  { value: 'MT', label: 'Malta' },
  { value: 'MH', label: 'Marshall Islands' },
  { value: 'MQ', label: 'Martinique' },
  { value: 'MR', label: 'Mauritania' },
  { value: 'MU', label: 'Mauritius' },
  { value: 'YT', label: 'Mayotte' },
  { value: 'MX', label: 'Mexico' },
  { value: 'FM', label: 'Micronesia,Federated States Of' },
  { value: 'MD', label: 'Moldova,Republic Of' },
  { value: 'MC', label: 'Monaco' },
  { value: 'MN', label: 'Mongolia' },
  { value: 'ME', label: 'Montenegro' },
  { value: 'MS', label: 'Montserrat' },
  { value: 'MA', label: 'Morocco' },
  { value: 'MZ', label: 'Mozambique' },
  { value: 'MM', label: 'Myanmar' },
  { value: 'NA', label: 'Namibia' },
  { value: 'NR', label: 'Nauru' },
  { value: 'NP', label: 'Nepal' },
  { value: 'NL', label: 'Netherlands' },
  { value: 'NC', label: 'New Caledonia' },
  { value: 'NI', label: 'Nicaragua' },
  { value: 'NE', label: 'Niger' },
  { value: 'NG', label: 'Nigeria' },
  { value: 'NU', label: 'Niue' },
  { value: 'NF', label: 'Norfolk Island' },
  { value: 'MK', label: 'North Macedonia' },
  { value: 'MP', label: 'Northern Mariana Islands' },
  { value: 'NO', label: 'Norway' },
  { value: 'OM', label: 'Oman' },
  { value: 'PK', label: 'Pakistan' },
  { value: 'PW', label: 'Palau' },
  { value: 'PS', label: 'Palestine, State Of' },
  { value: 'PA', label: 'Panama' },
  { value: 'PG', label: 'Papua New Guinea' },
  { value: 'PY', label: 'Paraguay' },
  { value: 'PE', label: 'Peru' },
  { value: 'PH', label: 'Philippines' },
  { value: 'PN', label: 'Pitcairn' },
  { value: 'PL', label: 'Poland' },
  { value: 'PT', label: 'Portugal' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'QA', label: 'Qatar' },
  { value: 'RE', label: 'Reunion' },
  { value: 'RO', label: 'Romania' },
  { value: 'RU', label: 'Russia' },
  { value: 'RW', label: 'Rwanda' },
  { value: 'BL', label: 'Saint Barthelemy' },
  { value: 'SH', label: 'Saint Helena, Ascension And Tristan Da Cunha' },
  { value: 'KN', label: 'Saint Kitts And Nevis' },
  { value: 'LC', label: 'Saint Lucia' },
  { value: 'MF', label: 'Saint Martin (French Part)' },
  { value: 'PM', label: 'Saint Pierre And Miquelon' },
  { value: 'VC', label: 'Saint Vincent And The Grenadines' },
  { value: 'WS', label: 'Samoa' },
  { value: 'SM', label: 'San Marino' },
  { value: 'ST', label: 'Sao Tome And Principe' },
  { value: 'SA', label: 'Saudi Arabia' },
  { value: 'SN', label: 'Senegal' },
  { value: 'RS', label: 'Serbia' },
  { value: 'SC', label: 'Seychelles' },
  { value: 'SL', label: 'Sierra Leone' },
  { value: 'SG', label: 'Singapore' },
  { value: 'SX', label: 'Sint Maarten (Dutch Part)' },
  { value: 'SK', label: 'Slovakia' },
  { value: 'SI', label: 'Slovenia' },
  { value: 'SB', label: 'Solomon Islands' },
  { value: 'SO', label: 'Somalia' },
  { value: 'ZA', label: 'South Africa' },
  { value: 'GS', label: 'South Georgia And The South Sandwich Islands' },
  { value: 'KR', label: 'South Korea' },
  { value: 'SS', label: 'South Sudan' },
  { value: 'ES', label: 'Spain' },
  { value: 'LK', label: 'Sri Lanka' },
  { value: 'SR', label: 'Suriname' },
  { value: 'SJ', label: 'Svalbard And Jan Mayen' },
  { value: 'SE', label: 'Sweden' },
  { value: 'CH', label: 'Switzerland' },
  { value: 'TW', label: 'Taiwan' },
  { value: 'TJ', label: 'Tajikistan' },
  { value: 'TZ', label: 'Tanzania,United Republic Of' },
  { value: 'TH', label: 'Thailand' },
  { value: 'TL', label: 'Timor-Leste' },
  { value: 'TG', label: 'Togo' },
  { value: 'TK', label: 'Tokelau' },
  { value: 'TO', label: 'Tonga' },
  { value: 'TT', label: 'Trinidad And Tobago' },
  { value: 'TN', label: 'Tunisia' },
  { value: 'TR', label: 'Turkey' },
  { value: 'TM', label: 'Turkmenistan' },
  { value: 'TC', label: 'Turks And Caicos Islands' },
  { value: 'TV', label: 'Tuvalu' },
  { value: 'UG', label: 'Uganda' },
  { value: 'UA', label: 'Ukraine' },
  { value: 'AE', label: 'United Arab Emirates' },
  { value: 'GB', label: 'United Kingdom' },
  { value: 'UM', label: 'United States Minor Outlying Islands' },
  { value: 'US', label: 'United States Of America' },
  { value: 'UY', label: 'Uruguay' },
  { value: 'UZ', label: 'Uzbekistan' },
  { value: 'VU', label: 'Vanuatu' },
  { value: 'VE', label: 'Venezuela, Bolivarian Republic Of' },
  { value: 'VN', label: 'Viet Nam' },
  { value: 'VG', label: 'Virgin Islands,British' },
  { value: 'VI', label: 'Virgin Islands,U.S.' },
  { value: 'WF', label: 'Wallis And Futuna' },
  { value: 'EH', label: 'Western Sahara' },
  { value: 'YE', label: 'Yemen' },
  { value: 'ZM', label: 'Zambia' },
  { value: 'ZW', label: 'Zimbabwe' },
];

export const DLCN_IMAGE_DETAILS = {
  imageACT: {
    url: licenceImageACT,
    header: '',
    description:
      'Your DLCN is found on the front of the card and runs vertically alongside the photo. It is alphanumeric (both numbers and letters) and displays 10 characters.',
  },
  imageNSW: {
    url: licenceImageNSW,
    header: '',
    description:
      'Your DLCN is found on the front of the card, in the top right-hand side above the photo. It is numeric and displays 10 digits.',
  },
  image1NT: {
    url: licenceImage1NT,
    header: 'Post November 2020',
    description:
      'Your DLCN is found on the back of the card, in the middle bottom. It is numeric and displays 6 to 8 digits.',
  },
  image2NT: {
    url: licenceImage2NT,
    header: 'Pre November 2020',
    description:
      'Your DLCN is found on the back of the card, in the bottom left-hand side. It is numeric and displays 6 to 8 digits.',
  },
  image1QLD: {
    url: licenceImage1QLD,
    header: 'Post June 2019',
    description:
      'Your DLCN is found on the front of the card, in the middle bottom. It is alphanumeric (both numbers and letters) and displays 10 characters.',
  },
  image2QLD: {
    url: licenceImage2QLD,
    header: 'Pre June 2019',
    description:
      'Your DLCN is found on the back of the card, in the bottom right-hand side. It is alphanumeric (both numbers and letters) and displays 10 characters.',
  },
  imageSA: {
    url: licenceImageSA,
    header: '',
    description:
      'Your DLCN is found on the back of the card, in the top right-hand side. It is alphanumeric (both numbers and letters) and displays 9 characters.',
  },
  imageTAS: {
    url: licenceImageTAS,
    header: '',
    description:
      'Your DLCN is found on the back of the card, in the top right-hand side. It is alphanumeric (both numbers and letters) and displays 9 characters.',
  },
  image1VIC: {
    url: licenceImage1VIC,
    header: 'Post November 2022',
    description:
      'Your DLCN is found on the back of the card, in the top right-hand side. It is alphanumeric (both numbers and letters) and displays 8 characters.',
  },
  image2VIC: {
    url: licenceImage2VIC,
    header: 'Pre November 2022',
    description:
      'Your DLCN is found on the back of the card, in the middle right-hand side. It is alphanumeric (both numbers and letters) and displays 8 characters.',
  },
  imageWA: {
    url: licenceImageWA,
    header: '',
    description:
      'Your DLCN is found on the back of the card, in the middle right-hand side. It is alphanumeric (both numbers and letters) and displays 8 to 10 characters.',
  },
};

export const MONTHS_LIST = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const ALERT_NOTIFICATION_1 = `We're unable to look up your address`;
export const ALERT_NOTIFICATION_2 =
  'Too many results found - please keep typing';
export const RESULT_NOTIFICATION = 'No results found.';
export const OCV_CONFIDENCE_TOO_MANY_MATCHES = 'Too many matches';
export const OCV_CONFIDENCE_NO_MATCHES = 'No matches';
