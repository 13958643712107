import { Dialog as OmniDialog } from '@omni/dialog';
import React, { useState } from 'react';

import styles from './Dialog.module.css';
import ArrowIcon from '../Arrow/Arrow';
import { Button } from '../Omni/Button';

interface Props {
  open: boolean;
  buttonAction: () => void;
  buttonText: string;
  buttonIcon: string;
  imageDetails: any;
  dialogStyle: Record<string, string>;
  overlayStyle: Record<string, string>;
}

const Dialog = ({
  open,
  buttonAction,
  buttonText,
  buttonIcon,
  imageDetails,
  dialogStyle,
  overlayStyle,
}: Props) => {
  const keys = Object.keys(imageDetails);
  const [selectedImage, setSelectedImage] = useState(keys[0]);
  const handleThumbnailImageClick = (key: string) => {
    setSelectedImage(key);
  };
  const onPreviousImageClick = () => {
    const currentIndex = keys.indexOf(selectedImage);
    if (currentIndex > 0) {
      setSelectedImage(keys[currentIndex - 1]);
    }
  };
  const onNextImageClick = () => {
    const currentIndex = keys.indexOf(selectedImage);
    if (currentIndex < keys.length - 1) {
      setSelectedImage(keys[currentIndex + 1]);
    }
  };
  return (
    <OmniDialog
      open={open}
      title=""
      icon=""
      onClose={buttonAction}
      showHeading={false}
      readDialogContent
      overlayStyle={overlayStyle}
      dialogStyle={dialogStyle}
    >
      <div className={styles.container}>
        {imageDetails && keys.length > 0 ? (
          <>
            <span className={styles.label}>
              {imageDetails[selectedImage].header}
            </span>
            <div className={styles.image__container}>
              {keys.length > 1 && (
                <ArrowIcon
                  id="leftArrow"
                  className={styles.arrow}
                  color={
                    keys.indexOf(selectedImage) === 0 ? '#d9d9d9' : '#0072ac'
                  }
                  direction="left"
                  onClick={onPreviousImageClick}
                />
              )}
              <img
                className={styles.main__image}
                src={imageDetails[selectedImage].url}
                alt="mainImage"
              />
              {keys.length > 1 && (
                <ArrowIcon
                  id="rightArrow"
                  className={styles.arrow}
                  color={
                    keys.indexOf(selectedImage) === keys.length - 1
                      ? '#d9d9d9'
                      : '#0072ac'
                  }
                  direction="right"
                  onClick={onNextImageClick}
                />
              )}
            </div>

            {keys.length > 1 && (
              <div className={styles.thumbnail__container}>
                {keys.map((key) => (
                  <button
                    onClick={() => handleThumbnailImageClick(key)}
                    type="button"
                  >
                    <img
                      data-testId={`${key}thumbnailImage`}
                      className={`${styles.thumbnail__image} ${
                        selectedImage === key
                          ? styles.thumbnail__image__selected
                          : ''
                      }`}
                      src={imageDetails[key].url}
                      alt="thumbnailImage"
                    />
                  </button>
                ))}
              </div>
            )}
            <span>{imageDetails[selectedImage].description}</span>
          </>
        ) : null}
        <div className={styles.button__container}>
          <Button
            id="okBtn"
            key="okAction"
            label={buttonText}
            icon={buttonIcon}
            type="secondary"
            size="normal"
            onClick={buttonAction}
            iconPlacement={undefined}
            title={undefined}
            disabled={undefined}
            className={styles.button}
            fieldsetType={undefined}
            isProcessing={undefined}
          />
        </div>
      </div>
    </OmniDialog>
  );
};

export default Dialog;
