import { createSlice } from '@reduxjs/toolkit';

export interface ImageDetails {
  type: string;
  imageBase64: string;
  countryCode: string;
}
export interface ScanPayload {
  frontDocument?: ImageDetails;
  backDocument?: ImageDetails;
  passport?: ImageDetails;
}

export const initialState: ScanPayload = {};

const scanSlice = createSlice({
  name: 'scan',
  initialState,
  reducers: {
    updateImage: (state, action) => {
      if (
        action.payload.type === 'PASSPORT' ||
        action.payload.type.includes('FRONT')
      ) {
        return {
          frontDocument: {
            type: action.payload.type,
            imageBase64: action.payload.imageBase64,
            countryCode: action.payload.countryCode,
          },
        };
      }
      if (action.payload.type.includes('BACK')) {
        return {
          ...state,
          backDocument: {
            type: action.payload.type,
            imageBase64: action.payload.imageBase64,
            countryCode: action.payload.countryCode,
          },
        };
      }
      return { ...state };
    },
    resetScanState: () => initialState,
  },
});

export const { updateImage, resetScanState } = scanSlice.actions;
export default scanSlice.reducer;
