import { ButtonBar } from '@omni/button';
import React from 'react';

import styles from './Confirm.module.css';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Button } from '../../common/components/Omni/Button';
import { cleanSession } from '../../common/components/Session/sessionSlice';

const Confirm = () => {
  const dispatch = useAppDispatch();
  const institutionalAnzLink = 'https://www.anz.com/institutional';
  const firstName = useAppSelector((state) => state.identity.firstName);
  const { reference, creditHeaderFailures } = useAppSelector(
    (state) => state.liveness,
  );
  const formatCreditHeaders = (headers: string[]): string => {
    if (headers.length === 1) {
      return headers[0];
    }
    const [lastHeader, ...rest] = [...headers].reverse();
    return `${rest.reverse().join(', ')} and ${lastHeader}`;
  };

  const handleFindMoreClick = () => {
    dispatch(cleanSession());
    window.location.assign(institutionalAnzLink);
  };

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <h1 className={styles.header}>eKYC Identity Verification Submitted</h1>
        <div className={styles.content}>
          <div>
            <div className={styles.heading}>Thank you, {firstName}</div>
            <div className={styles.large__text}>
              Your eKYC application has been submitted to ANZ.
            </div>
            {creditHeaderFailures && creditHeaderFailures.length > 0 && (
              <div>
                <br />
                <p>
                  <strong>Please note</strong> that the details you provided did
                  not match the records held on file by{' '}
                  {formatCreditHeaders(creditHeaderFailures)}, the reporting
                  {creditHeaderFailures.length > 1 ? ' agencies' : ' agency'} we
                  checked to verify yourself in accordance with the AML/CTF act.
                  This may be because you do not have a credit file in
                  Australia.
                </p>
                <br />
                <p>
                  Don&apos;t worry, this doesn&apos;t mark or affect your credit
                  history in any way, we are just letting you know.
                </p>
              </div>
            )}
          </div>
          <div>
            <div>eKYC Reference Number</div>
            <div className={styles.large__text}>{reference}</div>
          </div>
          <div>
            <div className={styles.next__heading}>What&apos;s next?</div>
            <div>
              You will hear from us shortly regarding the outcome of your
              application.
            </div>
            <br />
            <div>
              In the meantime, you can find out all about our products and
              services on{' '}
              <a href={institutionalAnzLink}>
                {institutionalAnzLink.replace('https://www.', '')}
              </a>
              .
            </div>
          </div>
        </div>
        <ButtonBar placement="right" style={{ gap: '15px' }}>
          <Button
            id="findMore"
            label="Find out more"
            aria-label="Find out more"
            onClick={handleFindMoreClick}
            icon=""
            iconPlacement="left"
            title="Find out more"
            fieldsetType="inline"
            type="primary"
            size="normal"
            disabled={false}
            className=""
            isProcessing={false}
          />
        </ButtonBar>
      </div>
    </div>
  );
};

export default Confirm;
