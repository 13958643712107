import { ButtonBar } from '@omni/button';
import { Checkbox } from '@omni/form';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  TERMS_CONTENT,
  TERMS_ELIGIBILITY,
  TERMS_PERSONAL_INFO_01,
  TERMS_PERSONAL_INFO_02,
  TERMS_PERSONAL_INFO_03,
  TERMS_PERSONAL_INFO_04,
  TERMS_PERSONAL_INFO_05,
  TERMS_CONSENT,
  TERMS_ACKNOWLEDGEMENT_01,
  TERMS_ACKNOWLEDGEMENT_02,
  ANZ_PRIVACY_POLICY_LINK,
  ANZ_PRIVACY_CENTRE_LINK,
  ANZ_EKYC_CONDITIONS_LINK,
} from './constants';
import styles from './Terms.module.css';
import { Button } from '../../common/components/Omni/Button';

const Terms = () => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const handleChecked = () => {
    setIsChecked(!isChecked);
  };

  const handleAgreeClick = () => {
    navigate('/identity-upload', { replace: true });
  };

  const handleDisagreeClick = () => {
    navigate('/', { replace: true });
  };
  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <h1 className={styles.header}>Terms & Conditions</h1>
        <div className={styles.content}>
          <p className={styles.content__header}>eKYC Privacy Consent</p>
          <p className={styles.content__header}>
            Australia and New Zealand Banking Group Limited
          </p>
          <p>
            Before ANZ can provide the financial products or services requested
            by you/your company, the law (for instance, the Anti-Money
            Laundering and Counter-Terrorism Financing Act) requires ANZ to
            collect your personal information to verify your identity.
          </p>
          <p>
            With your consent, we can quickly and conveniently verify your
            identity digitally via our eKYC Identity Verification process (
            <span className={styles.sub__header}>{`"eKYC"`}</span>).
          </p>
          <p>
            Before proceeding to use eKYC to verify your identity, please read
            and agree to the eKYC terms and conditions (
            <span
              className={styles.sub__header}
            >{`"Terms and Conditions"`}</span>
            ) below.
          </p>
          <div className={styles.sub__header}>{TERMS_CONTENT}</div>
          <div className={styles.sub__header}>
            1. About these terms
            <p className={styles.content__child}>
              1.1 Your use of eKYC constitutes your agreement to be bound by the
              Terms and Conditions and your consent to the collection and
              handling of your personal and sensitive information in accordance
              with the terms below.
            </p>
            <p className={styles.content__child}>
              1.2 In these terms,
              <span className={styles.sub__header}> ANZ, we, us,</span> or
              <span className={styles.sub__header}> our </span>
              refers to Australia and New Zealand Banking Group Limited, and any
              companies owned by, or related to, us. And
              <span className={styles.sub__header}> you </span>and
              <span className={styles.sub__header}> your </span>refers to the
              person using eKYC.
            </p>
            <p className={styles.content__child}>
              1.3 The laws of Victoria, Australia apply to these terms.
            </p>
          </div>
          <div className={styles.sub__header}>
            2. You must meet our eligibility criteria to use eKYC
            <div className={styles.content__child}>
              2.1 To use eKYC you must:
              <div className={styles.content__super__child}>
                {TERMS_ELIGIBILITY}
              </div>
              2.2 If you don’t meet the above eligibility criteria for digital
              identity verification, we cannot confirm your identity using eKYC
              and we will need to verify your identity in branch or via other
              methods as approved by ANZ from time to time.
            </div>
          </div>
          <div className={styles.sub__header}>
            3. Personal information
            <div className={styles.content__child}>
              <span className={styles.sub__header}>
                3.1 Collecting your personal and sensitive information
              </span>
              <div className={styles.content__super__child}>
                {TERMS_PERSONAL_INFO_01}
              </div>
              <span className={styles.sub__header}>
                3.2 How ANZ may use and disclose your personal and sensitive
                information
              </span>
              <div className={styles.content__child}>
                (a) We may use and disclose your personal and sensitive
                information:
                <div className={styles.content__super__child}>
                  {TERMS_PERSONAL_INFO_02}
                </div>
              </div>
              <span className={styles.sub__header}>
                3.3 Who ANZ may share your personal and sensitive information
                with
              </span>
              <div className={styles.content__child}>
                (a) ANZ will not generally disclose your sensitive information
                (such as images you submit of yourself) to any third parties
                however, in some instances, ANZ may disclose this information
                to:
                <div className={styles.content__super__child}>
                  {TERMS_PERSONAL_INFO_03}
                </div>
                (b) Otherwise, ANZ may disclose your personal information (such
                as your name, the government issued documents you have provided
                and/or the fact that ANZ has verified/failed to verify your
                identity) to:
                <div className={styles.content__super__child}>
                  {TERMS_PERSONAL_INFO_04}
                  <p className={styles.content__child}>
                    • ANZ may verify any government issued documents you provide
                    (such as your driver’s licence or passport) with the issuer
                    of the document or official record holder. ANZ may disclose
                    your documents (either directly or via a third party) to
                    validate the documents by matching the content of these
                    documents with existing information held by the issuer of
                    the document or the official record holder. This may be done
                    using third party systems and services; and
                  </p>
                  <p className={styles.content__child}>
                    • ANZ may also disclose your personal information to Equifax
                    Australia to confirm the existence (or non-existence) of
                    your credit report. For the avoidance of doubt, ANZ will not
                    access your credit report. Please refer to section 4 below
                    for more information.
                  </p>
                  {TERMS_PERSONAL_INFO_05}
                </div>
                <p>
                  Some of the ANZ entities we may disclose your personal
                  information to may be located overseas. You can find details
                  about the location of these recipients in ANZ’s Privacy Policy
                  at
                  <a
                    className={styles.web__link}
                    href={ANZ_PRIVACY_POLICY_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {ANZ_PRIVACY_POLICY_LINK}
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className={styles.sub__header}>
            4. Consent to disclose your personal information
            <div className={styles.content__child}>
              4.1 By agreeing to these Terms and Conditions, you acknowledge and
              agree that to help verify your identity:
              <div className={styles.content__super__child}>
                {TERMS_CONSENT}
              </div>
            </div>
          </div>
          <div className={styles.sub__header}>
            5. Further information
            <p className={styles.content__child}>
              5.1 ANZ’s{' '}
              <a
                className={styles.web__link}
                href={ANZ_PRIVACY_CENTRE_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
              contains information about further laws that may require or
              authorise ANZ to collect certain information from you, further
              details on when ANZ may collect your information from other
              sources (including from third parties), how to access the personal
              information ANZ hold about you, how you can seek to correct it and
              how you can raise concerns if you believe that ANZ has breached
              the Privacy Act or an applicable Code and how ANZ will deal with
              these matters.
            </p>
            <p className={styles.content__child}>
              5.2 You can also find further information on eKYC, including the
              details of any applicable credit reporting bodies, at{' '}
              <a
                className={styles.web__link}
                href={ANZ_EKYC_CONDITIONS_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                anz.com/ekyc-conditions
              </a>
              .
            </p>
          </div>
          <div className={styles.sub__header}>
            6. General Acknowledgements and Warranties
            <div className={styles.content__child}>
              6.1 You:
              <div className={styles.content__super__child}>
                {TERMS_ACKNOWLEDGEMENT_01}
                <div className={styles.content__super__child}>
                  {TERMS_ACKNOWLEDGEMENT_02}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.checkbox__container}>
          <Checkbox
            id="agreement"
            checked={isChecked}
            onChange={handleChecked}
            label="I agree to the above Terms and Conditions"
            style={{ marginRight: '0' }}
          />
        </div>
        <ButtonBar placement="right" style={{ gap: '15px' }}>
          <Button
            id="disagree"
            label="Disagree"
            aria-label="Disagree"
            onClick={handleDisagreeClick}
            icon=""
            iconPlacement="left"
            title="Disagree"
            fieldsetType="inline"
            type="primary"
            size="normal"
            disabled={false}
            className=""
            isProcessing={false}
          />
          <Button
            id="agree"
            label="Agree"
            aria-label="Agree"
            onClick={handleAgreeClick}
            icon=""
            iconPlacement="left"
            title="Agree"
            fieldsetType="inline"
            type="standard"
            size="normal"
            disabled={!isChecked}
            className=""
            isProcessing={false}
          />
        </ButtonBar>
      </div>
    </div>
  );
};

export default Terms;
