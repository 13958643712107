/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/jsx-filename-extension */
import PropTypes from 'prop-types';
import React, { memo } from 'react';

import styles from './styles.module.css';

const Stepper = ({ id, activeStep, label, children }) => {
  const renderChildren = React.Children.map(
    React.Children.toArray(children),
    (child, index) =>
      React.cloneElement(child, {
        active: index === activeStep,
        completed: index < activeStep,
        stepNumber: index + 1,
        ...child.props,
      }),
  );

  return (
    <div className={styles.container}>
      <ol id={id} className={styles.steps} aria-label={label}>
        {renderChildren}
      </ol>
    </div>
  );
};

Stepper.propTypes = {
  /** `id` for Stepper  */
  id: PropTypes.string.isRequired,
  /** step number to highlight the step  */
  activeStep: PropTypes.number.isRequired,
  /** aria-label of the step  */
  label: PropTypes.string,
  /** children components for each step  */
  children: PropTypes.node.isRequired,
};

Stepper.defaultProps = {
  activeStep: 0,
};

Stepper.displayName = 'Stepper';

export default memo(Stepper);
