export const ERROR_TYPES: any = {
  inCapableError: 'inCapable',
  noDevicesError: 'noDevices',
  OverconstrainedError: 'constraints',
  SecurityError: 'disabledSupport',
  NotReadableError: 'hardwareIssue',
  AbortError: 'hardwareIssue',
  NotAllowedError: 'accessDenied',
  NotFoundError: 'accessDenied',
};

export const ERROR_MESSAGES: any = {
  inCapable:
    'Unable to complete request. The browser you are using is below minimum requirements. Please use a different browser to complete identity verification with ANZ.',
  noDevices:
    'Unable to connect to your camera. You will need to enable your camera on your device to complete identity verification with ANZ. Please try again. If this error persists you may want to use another device.',
  constraints:
    'Unable to complete request. The camera on this device is below minimum requirements. Please use a different device to complete identity verification with ANZ.',
  disabledSupport:
    'Your browser access to camera has been disabled. Please enable access to camera.',
  hardwareIssue: 'Unable to access the camera!',
  unknownError: 'An unexpected error occured!',
  accessDenied: `You have denied access to your camera. To continue, please allow camera access to ${window.location.origin} and select the 'Try Again' option. If you are unable to proceed, please exit and log in again.`,
};

export const MIN_SCREEN_HEIGHT_EXPECTED = 568;
export const ORIENTATION_WARNING_MESSAGE =
  'Please rotate your screen for better experience.';
