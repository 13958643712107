/* eslint-disable react/jsx-filename-extension */
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { components } from 'react-select';

const generateKey = (content, index) => `${content}-${index}`;

const defaultOptionRenderer = (option) => {
  const { inputValue } = option.selectProps;
  const label = option.label || option.value;

  const renderContent = (content, isMatching) =>
    isMatching ? <strong>{content}</strong> : <span>{content}</span>;

  // If inputValue is empty or not present, return the label as is
  if (!inputValue || inputValue.trim() === '') {
    return (
      <components.Option {...option}>
        <div role="button" aria-label={label}>
          <span aria-hidden="true">{label}</span>
        </div>
      </components.Option>
    );
  }

  // Use a regular expression to split the label into parts based on the search string
  const regex = new RegExp(`(${inputValue})`, 'gi');
  const parts = label.split(regex);

  return (
    <components.Option {...option}>
      <div role="button" aria-label={label}>
        {parts.map((part, index) => (
          <React.Fragment key={generateKey(part, index)}>
            {renderContent(part, regex.test(part))}
          </React.Fragment>
        ))}
      </div>
    </components.Option>
  );
};

export default defaultOptionRenderer;
