/* eslint-disable no-unsafe-optional-chaining */
import { DialogNotification, DialogConfirmation } from '@omni/dialog';
import { WarningNotification } from '@omni/notification';
import { isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';

import styles from './Camera.module.css';
import cameraSnapAudio from './cameraSnap.mp3';
import {
  ERROR_TYPES,
  ERROR_MESSAGES,
  MIN_SCREEN_HEIGHT_EXPECTED,
  ORIENTATION_WARNING_MESSAGE,
} from './constants';
import documentSampleVideo from './documentSample.mp4';
import placeHolderImage from './placeHolder.png';
import selfieSampleVideo from './selfieSample.mp4';
import selfieSampleVideo2 from './selfieSample2.mp4';
import { Spinner } from '../Spinner';

// eslint-disable-next-line no-shadow
export enum Context {
  document = 'Document',
  selfie = 'Selfie',
}

// eslint-disable-next-line no-shadow
export enum Status {
  focus = 'Focus',
  capture = 'Capture',
  preview = 'Preview',
}

interface Props {
  isActive: boolean;
  width?: number;
  height?: number;
  context: Context;
  documentTitle?: string;
  documentDescription?: string;
  selfieTimer?: number;
  isDeviceErrorToBeShown: boolean;
  isErrorToBeShown: boolean;
  isConstraintsWarningToBeShown: boolean;
  isOrientationWarningToBeShown: boolean;
  isBackNavigationToBeShown: boolean;
  containerClassName?: string;
  onStatusChange: (status: string) => void;
  onSwitchCamera: () => void;
  onBackNavigation: () => void;
  onRetake: () => void;
  onSubmit: (image: string) => void;
  onDeviceError: (error: string, type: string) => void;
  onError: (error: any) => void;
  onErrorClose: () => void;
  onExit: () => void;
  isInTestMode: boolean;
}

const Camera = ({
  isActive = false,
  width = 960,
  height = 720,
  context = Context.selfie,
  documentTitle = '',
  documentDescription = '',
  selfieTimer = 3,
  isDeviceErrorToBeShown = true,
  isErrorToBeShown = true,
  isConstraintsWarningToBeShown = true,
  isOrientationWarningToBeShown = true,
  isBackNavigationToBeShown = true,
  containerClassName = '',
  onStatusChange,
  onSwitchCamera,
  onBackNavigation,
  onRetake,
  onSubmit,
  onDeviceError,
  onError,
  onErrorClose,
  onExit,
  isInTestMode = false,
}: Props) => {
  const [isCompatible, setIsCompatible]: [boolean, any] = useState(false);
  const [isInitialized, setIsInitialized]: [boolean, any] = useState(false);
  const [cameraStatus, setCameraStatus]: [string, any] = useState(Status.focus);
  const [deviceIds, setDeviceIds]: [string[], any] = useState([]);
  const [currentDeviceId, setCurrentDeviceId]: [string, any] = useState('');
  const [currentStream, setCurrentStream]: [any, any] = useState(null);
  const [currentConstraints, setCurrentConstraints]: [any, any] = useState({});
  const [isFocusBoxShown, setIsFocusBoxShown]: [boolean, any] = useState(false);
  const [selfieCounter, setSelfieCounter]: [number, any] =
    useState(selfieTimer);
  const [currentImg, setCurrentImg]: [any, any] = useState('');
  const [isSpinnerShown, setIsSpinnerShown]: [boolean, any] = useState(false);
  const [errorMessage, setErrorMessage]: [string, any] = useState('');
  const [isRetryableError, setIsRetryableError]: [boolean, any] =
    useState(false);
  const [isOrientationWarningShown, setIsOrientationWarningShown]: [
    boolean,
    any,
  ] = useState(false);
  const [videoDimensions, setVideoDimensions] = useState({});

  const videoEl = useRef<any | HTMLVideoElement>(null);
  const videoElRef = useCallback<any | HTMLVideoElement>(
    (node: HTMLVideoElement) => {
      if (node) {
        node.srcObject = currentStream;
        if (videoEl) {
          videoEl.current = node;
        }
      }
    },
    [currentStream],
  );
  const imgEl = useRef<any | HTMLImageElement>(null);
  const canvasEl = useRef<any | HTMLCanvasElement>(null);
  const prevDeviceId = useRef<string>(currentDeviceId);
  const isLandscapeMode = useRef<boolean>(
    window.innerWidth > window.innerHeight,
  );
  const prevIsLandscapeMode = useRef<boolean>(isLandscapeMode.current);
  const prevWindowWidth = useRef<number>(window.innerWidth);

  const isDocumentMode = context === Context.document;
  const facingMode = isDocumentMode ? 'environment' : 'user';
  const isCaptureButtonShown = cameraStatus === Status.focus && isFocusBoxShown;
  const isSwitchCameraButtonShown =
    cameraStatus === Status.focus && deviceIds.length > 1 && isFocusBoxShown;
  const isBackNavigationShown =
    isBackNavigationToBeShown && isCaptureButtonShown;
  const {
    width: currentWidth,
    height: currentHeight,
    facingMode: currentFacingMode,
  } = currentConstraints;
  const cameraSnap = useMemo(() => new Audio(cameraSnapAudio), []);

  const testVideo = isDocumentMode
    ? documentSampleVideo
    : isLandscapeMode.current
    ? selfieSampleVideo2
    : selfieSampleVideo;
  const testModeProps = useMemo(
    () =>
      isInTestMode
        ? {
            src: isActive ? testVideo : '',
            loop: true,
          }
        : {},
    [isActive, isInTestMode, testVideo],
  );

  const handleError = useCallback(
    (error: any) => {
      setIsSpinnerShown(false);
      const type =
        ERROR_TYPES[error.message] || ERROR_TYPES[error.name] || 'unknownError';
      const message = ERROR_MESSAGES[type];
      if (onDeviceError) {
        onDeviceError(message, error.message);
      }
      if (isDeviceErrorToBeShown) {
        setIsRetryableError(type === 'noDevices' || type === 'accessDenied');
        setErrorMessage(message);
      }
    },
    [isDeviceErrorToBeShown, onDeviceError],
  );

  const stopStream = useCallback(() => {
    if (currentStream) {
      currentStream.getTracks().forEach((track: any) => {
        track.stop();
      });
      setCurrentStream(null);
      setIsFocusBoxShown(false);
    }
  }, [currentStream, setCurrentStream]);

  useEffect(() => {
    if (!isInTestMode && isActive) {
      if (
        !navigator.mediaDevices ||
        !navigator.mediaDevices.enumerateDevices ||
        !navigator.mediaDevices.getUserMedia
      ) {
        handleError(new Error('inCapableError'));
      } else {
        setIsCompatible(true);
      }
    }
    return () => stopStream();
  }, [handleError, isActive, isInTestMode, stopStream]);

  const getDevices = () => navigator.mediaDevices.enumerateDevices();

  const verifyCameraDevicesExists = (deviceInfos: any[]) => {
    const videoDevices = deviceInfos.filter(
      ({ kind }: { kind: string }) => kind === 'videoinput',
    );
    if (videoDevices.length === 0) {
      return Promise.reject(new Error('noDevicesError'));
    }
    return deviceInfos;
  };

  /* 
    FIXME -- This logic is used to detect the default camera set by Chrome based browsers in Android devices.
    This is to avoid issues when wide angle camera is selected as default.
    This logic may need a revisit and update if Chrome based browsers changes generated camera label pattern.
  */
  const getDefaultDeviceIdIfAny = useCallback(
    (deviceInfos: any[]) => {
      if (isDocumentMode) {
        const videoDevices = deviceInfos.filter(
          ({ kind }: { kind: string }) => kind === 'videoinput',
        );
        const isAndroidDeviceLabelsDetected =
          videoDevices.filter(({ label }: { label: string }) =>
            /^camera[0-9]\s[0-9]/i.test(label),
          ).length === videoDevices.length;
        if (isAndroidDeviceLabelsDetected) {
          const { deviceId: defaultDeviceId } = orderBy(
            videoDevices,
            ['label'],
            ['asc'],
          )[0];
          return Promise.resolve(defaultDeviceId);
        }
      }
      return Promise.resolve('');
    },
    [isDocumentMode],
  );

  const getDeviceIds = (deviceInfos: any[]) => {
    const videoDevices = deviceInfos.filter(
      ({ kind }: { kind: string }) => kind === 'videoinput',
    );
    const extractedDeviceIds = videoDevices
      .map(({ deviceId }) => deviceId)
      .filter((deviceId: string) => deviceId !== '');
    setDeviceIds(extractedDeviceIds);
  };

  const getStream = useCallback(
    (defaultDeviceId?: string) => {
      stopStream();
      const deviceId = defaultDeviceId || currentDeviceId;
      const constraints = {
        video: {
          deviceId: deviceId ? { exact: deviceId } : undefined,
          width:
            isDocumentMode && !(window.innerWidth > window.innerHeight)
              ? height
              : width,
          height:
            isDocumentMode && !(window.innerWidth > window.innerHeight)
              ? width
              : height,
          facingMode,
        },
      };
      return navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          setCurrentStream(stream);
          setCurrentConstraints(stream.getVideoTracks()[0].getSettings());
          if (!currentDeviceId) {
            const { deviceId: streamDeviceId = '' } = stream
              .getVideoTracks()[0]
              .getSettings();
            setCurrentDeviceId(streamDeviceId);
          }
        })
        .catch((error) => handleError(error));
    },
    [
      stopStream,
      currentDeviceId,
      isDocumentMode,
      height,
      width,
      facingMode,
      handleError,
    ],
  );

  useEffect(() => {
    if (!isInTestMode && isCompatible && !isInitialized) {
      setIsInitialized(true);
      getDevices()
        .then(verifyCameraDevicesExists)
        .then(getDefaultDeviceIdIfAny)
        .then(getStream)
        .then(getDevices)
        .then(getDeviceIds)
        .catch((error) => handleError(error));
    }
  }, [
    isInTestMode,
    isCompatible,
    isInitialized,
    handleError,
    getStream,
    getDefaultDeviceIdIfAny,
  ]);

  useEffect(() => {
    if (
      !isInTestMode &&
      deviceIds.length &&
      !isEmpty(prevDeviceId) &&
      prevDeviceId.current !== currentDeviceId
    ) {
      prevDeviceId.current = currentDeviceId;
      getStream();
    }
  }, [
    isInTestMode,
    deviceIds.length,
    prevDeviceId,
    currentDeviceId,
    getStream,
  ]);

  useEffect(() => {
    if (cameraStatus === Status.preview) {
      stopStream();
    }
  }, [cameraStatus, stopStream]);

  useEffect(() => {
    if (
      isActive &&
      isCompatible &&
      !isInTestMode &&
      !currentStream &&
      cameraStatus !== Status.preview
    ) {
      setIsSpinnerShown(true);
    }
  }, [cameraStatus, currentStream, isActive, isCompatible, isInTestMode]);
  // Debounce function to delay updating state
  const debounce = <T extends (...args: any[]) => void>(
    func: T,
    wait: number,
  ): ((...args: Parameters<T>) => void) => {
    let timeout: ReturnType<typeof setTimeout> | null = null;
    return (...args: Parameters<T>): void => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => func(...args), wait);
    };
  };
  // Debounced function to update video dimensions
  const updateVideoDimensions = useCallback(
    debounce(() => {
      const { width: offsetWidth = 0, height: offsetHeight = 0 } =
        videoEl?.current?.getBoundingClientRect() || {};
      if (videoEl) {
        setVideoDimensions({
          width: `${offsetWidth}px`,
          height: `${offsetHeight}px`,
          marginTop: `-${offsetHeight}px`,
        });
        setIsFocusBoxShown(true); // Show the focus box when video dimensions are stable
        setIsSpinnerShown(false); // Hide the spinner when video dimensions are stable
      }
    }, 300),
    [],
  );

  useEffect(() => {
    const resizeFocusBox = () => {
      if (window.innerWidth !== prevWindowWidth.current) {
        prevWindowWidth.current = window.innerWidth;
        if (isFocusBoxShown) {
          setIsFocusBoxShown(false);
          setTimeout(() => {
            setIsFocusBoxShown(true);
          }, 1000);
        }
      }
      isLandscapeMode.current = window.innerWidth > window.innerHeight;
    };
    window.addEventListener('resize', resizeFocusBox);
    return () => window.removeEventListener('resize', resizeFocusBox);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocusBoxShown, prevWindowWidth, isLandscapeMode.current]);

  useEffect(() => {
    const isWarningToBeShown =
      isOrientationWarningToBeShown &&
      isLandscapeMode.current &&
      window.innerHeight < MIN_SCREEN_HEIGHT_EXPECTED;
    setIsOrientationWarningShown(isWarningToBeShown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isOrientationWarningToBeShown,
    isLandscapeMode.current,
    window.innerHeight,
  ]);

  useEffect(() => {
    if (
      !isInTestMode &&
      currentStream &&
      prevIsLandscapeMode &&
      prevIsLandscapeMode.current !== isLandscapeMode.current
    ) {
      prevIsLandscapeMode.current = isLandscapeMode.current;
      getStream();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isInTestMode,
    currentStream,
    prevIsLandscapeMode.current,
    isLandscapeMode.current,
    getStream,
  ]);

  useEffect(() => {
    if (onStatusChange) {
      onStatusChange(cameraStatus);
    }
  }, [onStatusChange, cameraStatus]);

  const closeErrorMessage = () => {
    setErrorMessage('');
    setIsRetryableError(false);
    if (onErrorClose) {
      onErrorClose();
    }
  };

  const handleExit = () => {
    closeErrorMessage();
    if (onExit) {
      onExit();
    }
  };

  const handleRetry = () => {
    closeErrorMessage();
    setIsInitialized(false);
  };

  const handleOrientationWarningClose = () =>
    setIsOrientationWarningShown(false);

  const captureImage = useCallback(() => {
    cameraSnap.volume = 1;
    cameraSnap.play();
    canvasEl.current.width = videoEl.current.videoWidth;
    canvasEl.current.height = videoEl.current.videoHeight;
    const aspectRatioDerived = canvasEl.current.width / canvasEl.current.height;
    canvasEl.current.height = canvasEl.current.width / aspectRatioDerived;

    const embedImage = (image: any) => {
      const canvasContext = canvasEl.current.getContext('2d');
      if (currentFacingMode !== 'environment' && !isDocumentMode) {
        canvasContext.translate(canvasEl.current.width, 0);
        canvasContext.scale(-1, 1);
      }
      canvasContext.imageSmoothingEnabled = false;
      canvasContext.drawImage(
        image,
        0,
        0,
        canvasEl.current.width,
        canvasEl.current.height,
      );
      setCurrentImg(canvasEl.current.toDataURL('image/jpg', 1));
    };
    embedImage(videoEl.current);
  }, [cameraSnap, currentFacingMode, isDocumentMode]);

  const handleCapture = useCallback(() => {
    try {
      if (isDocumentMode) {
        captureImage();
        setCameraStatus(Status.preview);
      } else {
        setCameraStatus(Status.capture);
        cameraSnap.volume = 0;
        cameraSnap.play();
        let counter = selfieTimer;
        const counterRef = setInterval(() => {
          counter -= 1;
          if (counter === 0) {
            captureImage();
            setCameraStatus(Status.preview);
            clearInterval(counterRef);
            setSelfieCounter(selfieTimer);
          } else {
            setSelfieCounter(counter);
          }
        }, 1000);
      }
    } catch (error: any) {
      if (onError) {
        onError(`${ERROR_MESSAGES.unknownError} (${error})`);
      }
      if (isErrorToBeShown) {
        setErrorMessage(error);
      }
    }
  }, [
    cameraSnap,
    captureImage,
    isDocumentMode,
    isErrorToBeShown,
    onError,
    selfieTimer,
  ]);

  const handleRetake = useCallback(() => {
    setCameraStatus(Status.focus);
    setCurrentImg('');
    if (!isInTestMode) {
      getStream();
    } else {
      // FIXME -- Find a better way to redraw focus box in test mode
      setIsFocusBoxShown(true);
      setIsFocusBoxShown(false);
    }
    if (onRetake) {
      onRetake();
    }
  }, [getStream, isInTestMode, onRetake]);

  const handleSubmit = useCallback(() => {
    if (onSubmit) {
      onSubmit(currentImg);
    }
  }, [currentImg, onSubmit]);

  const handleSwitchCamera = useCallback(() => {
    let deviceIdIndex = deviceIds.indexOf(currentDeviceId);
    deviceIdIndex =
      deviceIdIndex === deviceIds.length - 1 ? 0 : deviceIdIndex + 1;
    setCurrentDeviceId(deviceIds[deviceIdIndex]);
    if (onSwitchCamera) {
      onSwitchCamera();
    }
  }, [currentDeviceId, deviceIds, onSwitchCamera]);

  const handleBackNavigation = useCallback(() => {
    stopStream();
    if (onBackNavigation) {
      onBackNavigation();
    }
  }, [onBackNavigation, stopStream]);

  let rootClassName =
    cameraStatus === Status.preview
      ? `${styles.root__container} ${styles.preview__mode}`
      : styles.root__container;
  rootClassName = containerClassName
    ? `${rootClassName} ${containerClassName}`
    : rootClassName;

  const videoClassName = isDocumentMode
    ? styles.video__document
    : styles.video__selfie;

  const imgClassName = isDocumentMode
    ? styles['capture-preview__document']
    : styles['capture-preview__selfie'];

  const contextWidthClassName = isDocumentMode
    ? styles['context-width__document']
    : styles['context-width__selfie'];
  const isVideoConstraintsMismatch =
    !isEmpty(currentConstraints) &&
    (isDocumentMode || isLandscapeMode.current
      ? currentWidth < width || currentHeight < height
      : currentWidth < height || currentHeight < width);
  const isFacingModeConstraintMismatch =
    !isEmpty(currentConstraints) &&
    currentFacingMode &&
    currentFacingMode !== facingMode;
  const cameraType = currentFacingMode === 'user' ? 'front' : 'back';
  const isConstraintsWarningShown =
    isConstraintsWarningToBeShown &&
    (isVideoConstraintsMismatch || isFacingModeConstraintMismatch) &&
    cameraStatus === Status.focus &&
    isFocusBoxShown;

  const getVideoStyle = useCallback(() => {
    const transform =
      isInTestMode ||
      (currentFacingMode && currentFacingMode === 'environment') ||
      isDocumentMode
        ? 'none'
        : 'scaleX(-1)';
    return {
      transform,
    };
  }, [currentFacingMode, isDocumentMode, isInTestMode]);

  const handleOnPlaying = useCallback(() => {
    updateVideoDimensions();
  }, [updateVideoDimensions]);

  const DocumentHeadingContainer = useCallback(
    () => (
      <div
        className={styles.document__heading__container}
        data-testid="documentHeadingContainer"
      >
        <h1 className={styles.document__heading} data-testid="documentHeading">
          Scan Identity Document
        </h1>
      </div>
    ),
    [],
  );

  const SelfieContainer = useCallback(
    () => (
      <div className={styles.selfie__container} data-testid="selfieContainer">
        <h1 className={styles.selfie__title}>
          {cameraStatus === Status.preview ? 'Confirm Photo' : 'Take a Photo'}
        </h1>
        <div
          className={`${styles.selfie__description} ${contextWidthClassName}`}
          data-testid="selfieDescription"
        >
          {isCaptureButtonShown && (
            <span aria-label="Position your face within the frame">
              Position your face within the frame.
            </span>
          )}
          {cameraStatus === Status.capture && (
            <div className={styles.selfie__timer__container}>
              <div>Counting down...</div>
              <div className={styles.selfie__timer__count}>
                <span>.</span>
                <span>
                  [
                  <span aria-hidden>
                    {selfieCounter < 10 ? `0${selfieCounter}` : selfieCounter}
                  </span>{' '}
                  Sec]
                </span>
                <div
                  aria-label={`${
                    (selfieCounter === selfieTimer &&
                      `Selfie Timer ${selfieCounter}`) ||
                    selfieCounter
                  }`}
                  aria-live="assertive"
                  style={{ display: 'none' }}
                  aria-hidden={false}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    ),
    [
      cameraStatus,
      contextWidthClassName,
      isCaptureButtonShown,
      selfieCounter,
      selfieTimer,
    ],
  );

  const DocumentTitle = useCallback(
    () => (
      <div
        className={styles['document-title__text']}
        data-testid="documentTitle"
      >
        {documentTitle}
      </div>
    ),
    [documentTitle],
  );

  const BoxArea = useCallback(() => {
    const { width: offsetWidth = 0, height: offsetHeight = 0 } =
      videoEl?.current?.getBoundingClientRect() || {};
    return (
      <svg
        viewBox={`0 0 ${offsetWidth} ${offsetHeight}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <mask id="cutOffRectangle">
            <rect
              width={offsetWidth}
              height={offsetHeight}
              x="0"
              y="0"
              rx="0"
              ry="0"
              fill="white"
              stroke="white"
            />
            <rect
              width={offsetWidth - 50}
              height={offsetHeight - 50}
              x="25"
              y="25"
              rx="16"
              ry="16"
            />
          </mask>
        </defs>
        <rect
          mask="url(#cutOffRectangle)"
          width={offsetWidth}
          height={offsetHeight}
          x="0"
          y="0"
          rx="10"
          ry="10"
          fill="black"
          stroke="none"
          fillOpacity="0.8"
        />
        <rect
          width={offsetWidth - 50}
          height={offsetHeight - 50}
          x="25"
          y="25"
          rx="16"
          ry="16"
          strokeLinejoin="round"
          fill="none"
          stroke="white"
          strokeLinecap="round"
          strokeWidth="1"
        />
      </svg>
    );
  }, []);

  const OvalArea = useCallback(() => {
    const { width: offsetWidth = 0, height: offsetHeight = 0 } =
      videoEl?.current?.getBoundingClientRect() || {};
    const rectWidth = offsetHeight / 1.8 > 300 ? 300 : offsetHeight / 1.8;
    const rectHeight = offsetHeight / 1.3;
    return (
      <svg
        viewBox={`0 0 ${offsetWidth} ${offsetHeight}`}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <mask id="cutOffEllipse">
            <rect
              width={offsetWidth}
              height={offsetHeight}
              x="0"
              y="0"
              rx="0"
              ry="0"
              fill="white"
              stroke="white"
            />
            <rect
              width={rectWidth}
              height={rectHeight}
              x={(offsetWidth - rectWidth) / 2}
              y={(offsetHeight - rectHeight) / 2}
              rx={offsetHeight / 3.5}
              ry={offsetHeight / 3.5}
            />
          </mask>
        </defs>
        <rect
          mask="url(#cutOffEllipse)"
          width={offsetWidth}
          height={offsetHeight}
          x="0"
          y="0"
          rx="10"
          ry="10"
          fill="black"
          stroke="none"
          fillOpacity="0.8"
        />
        <rect
          width={rectWidth}
          height={rectHeight}
          x={(offsetWidth - rectWidth) / 2}
          y={(offsetHeight - rectHeight) / 2}
          rx={offsetHeight / 3.5}
          ry={offsetHeight / 3.5}
          fill="none"
          stroke="white"
          strokeWidth="1"
        />
      </svg>
    );
  }, []);

  const CameraStream = useCallback(
    () => (
      <div className={styles.video__container}>
        <video
          title="Camera stream"
          autoPlay
          muted
          playsInline
          className={`${styles.video} ${videoClassName}`}
          onPlaying={handleOnPlaying}
          poster={placeHolderImage}
          {...testModeProps}
          ref={videoElRef}
          style={getVideoStyle()}
        />
        {isFocusBoxShown && (
          <div className={styles['video__focus-box']} style={videoDimensions}>
            {isDocumentMode ? <BoxArea /> : <OvalArea />}
          </div>
        )}
      </div>
    ),
    [
      videoClassName,
      testModeProps,
      videoElRef,
      getVideoStyle,
      isFocusBoxShown,
      isDocumentMode,
      BoxArea,
      OvalArea,
      handleOnPlaying,
    ],
  );

  const CapturePreview = useCallback(
    () => (
      <>
        <img
          alt="Capture preview"
          className={`${styles['capture-preview']} ${
            cameraStatus === Status.preview ? imgClassName : ''
          }`}
          ref={imgEl}
          src={currentImg}
          style={isInTestMode ? { transform: 'scaleX(-1)' } : {}}
        />
        <div
          aria-label="Photo is taken"
          aria-live="polite"
          style={{ display: 'none' }}
          aria-hidden={cameraStatus !== Status.preview}
        />
      </>
    ),
    [cameraStatus, imgClassName, currentImg, isInTestMode],
  );

  const DocumentDescription = useCallback(
    () => (
      <div
        className={`${styles['document-description__text']} ${contextWidthClassName}`}
        data-testid="documentDescription"
      >
        {documentDescription.split(' ').map((str, index) =>
          str.includes('-') ? (
            // eslint-disable-next-line react/no-array-index-key
            <b key={index}>{` ${str.replace(/-/gi, '')}`}</b>
          ) : (
            <span key={str}>{` ${str}`}</span>
          ),
        )}
      </div>
    ),
    [contextWidthClassName, documentDescription],
  );

  const CameraControls = useCallback(
    () => (
      <div className={`${styles.button__container} ${contextWidthClassName}`}>
        {isCaptureButtonShown && (
          <button
            className={`${styles.button} ${styles['capture-button']}`}
            onClick={handleCapture}
            onTouchEnd={handleCapture}
            title="Capture"
            type="button"
            tabIndex={0}
            aria-label={isDocumentMode ? 'image capture' : `selfie capture`}
          >
            <span className={styles['capture-button__text']}>.</span>
          </button>
        )}
        {cameraStatus === Status.preview && (
          <div>
            <button
              id="retakeButton"
              className={`${styles.button} ${styles['retake-button']}`}
              onClick={handleRetake}
              title="Retake"
              type="button"
            >
              <span className={styles['retake-button__text']}>Retake</span>
            </button>
            <button
              id="submitButton"
              className={`${styles.button} ${styles['submit-button']}`}
              onClick={handleSubmit}
              title="Submit"
              type="button"
            >
              <span className={styles.button__text}>Submit</span>
            </button>
          </div>
        )}
      </div>
    ),
    [
      cameraStatus,
      contextWidthClassName,
      handleCapture,
      handleRetake,
      handleSubmit,
      isCaptureButtonShown,
      isDocumentMode,
      selfieTimer,
    ],
  );

  const ConstraintsWarning = useCallback(
    () => (
      <div
        className={styles['constraints-description__text']}
        data-testid="constraintsDescription"
      >
        {isVideoConstraintsMismatch && (
          <span>
            Selected camera does not meet resolution requirements.{' '}
            {isSwitchCameraButtonShown && <>Please switch camera.</>}
          </span>
        )}
        {!isVideoConstraintsMismatch && isFacingModeConstraintMismatch && (
          <span>
            You have selected {cameraType} facing camera which is not ideal for
            the process.{' '}
            {isSwitchCameraButtonShown && <>Please switch camera.</>}
          </span>
        )}
      </div>
    ),
    [
      isVideoConstraintsMismatch,
      isFacingModeConstraintMismatch,
      cameraType,
      isSwitchCameraButtonShown,
    ],
  );

  const BottomControlBar = useCallback(
    () => (
      <div className={`${styles.bottom__container} ${contextWidthClassName}`}>
        {isBackNavigationShown && (
          <button
            id="backNavigationButton"
            className={`${styles.button} ${styles['back-navigation-button']}`}
            onClick={handleBackNavigation}
            title="Back"
            type="button"
          >
            <span className={styles.button__text}>Back</span>
          </button>
        )}
        {isSwitchCameraButtonShown && (
          <button
            id="switchCameraButton"
            className={`${styles.button} ${styles['switch-camera-button']}`}
            onClick={handleSwitchCamera}
            title="Switch Camera"
            type="button"
          >
            <span className={styles.button__text}>Switch Camera</span>
          </button>
        )}
      </div>
    ),
    [
      contextWidthClassName,
      handleSwitchCamera,
      isSwitchCameraButtonShown,
      isBackNavigationShown,
      handleBackNavigation,
    ],
  );

  return (
    <div className={rootClassName}>
      <div className={styles.camera__container}>
        {isDocumentMode && cameraStatus === Status.focus && (
          <DocumentHeadingContainer />
        )}
        {!isDocumentMode && <SelfieContainer />}
        {isDocumentMode && <DocumentTitle />}
        {(cameraStatus === Status.focus || cameraStatus === Status.capture) && (
          <CameraStream />
        )}
        <CapturePreview />
        {isDocumentMode && <DocumentDescription />}
        <CameraControls />
        {isConstraintsWarningShown && <ConstraintsWarning />}
        <BottomControlBar />
        <DialogNotification
          open={!isEmpty(errorMessage) && !isRetryableError}
          size="normal"
          messages={[errorMessage]}
          buttonText="Exit"
          buttonIcon="fa-times"
          buttonAction={handleExit}
          overlayStyle={{ background: '#494949', opacity: '0.85' }}
          dialogStyle={{ width: '90%', maxWidth: '50em' }}
        />
        <DialogConfirmation
          open={!isEmpty(errorMessage) && isRetryableError}
          size="normal"
          onConfirm={handleRetry}
          onCancel={handleExit}
          confirmText="Try Again"
          confirmIcon="fa-rotate-right"
          cancelText="Exit"
          cancelIcon="fa-times"
          messages={[errorMessage]}
          overlayStyle={{ background: '#494949', opacity: '0.85' }}
          dialogStyle={{ width: '90%', maxWidth: '50em' }}
        />
        <WarningNotification
          onClose={handleOrientationWarningClose}
          message={ORIENTATION_WARNING_MESSAGE}
          autoClose={false}
          open={isOrientationWarningShown}
        />
        <canvas style={{ display: 'none' }} ref={canvasEl} />
        {isSpinnerShown && <Spinner />}
      </div>
    </div>
  );
};

Camera.propTypes = {
  /** to be set when the stream needs to be started */
  isActive: PropTypes.bool,
  /** video constraint height in align with the configured aspect ratio */
  height: PropTypes.number,
  /** video constraint width in align with the configured aspect ratio */
  width: PropTypes.number,
  /** camera to be opened in 'Selfie' or 'Document' mode */
  context: PropTypes.string,
  /** title of document */
  documentTitle: PropTypes.string,
  /** description of document where the words shown in bold to be embedded within '-' */
  documentDescription: PropTypes.string,
  /** delay in seconds to capture selfie image  */
  selfieTimer: PropTypes.number,
  /** to be set when device errors need to be shown in a popup */
  isDeviceErrorToBeShown: PropTypes.bool,
  /** to be set when generic errors need to be shown in a popup */
  isErrorToBeShown: PropTypes.bool,
  /** to be set if constraints mismatch warning to be shown */
  isConstraintsWarningToBeShown: PropTypes.bool,
  /** to be set when device dimensions warning to be shown */
  isOrientationWarningToBeShown: PropTypes.bool,
  /** to be set when back navigation button to be shown */
  isBackNavigationToBeShown: PropTypes.bool,
  /** class primarily meant to set avaialble viewport height for camera container based on other elements occupancy in the screen */
  containerClassName: PropTypes.string,
  /** callback function invoked while camera status value changes to 'Focus', 'Capture' or 'Preview' */
  onStatusChange: PropTypes.func,
  /** callback function invoked while switching camera */
  onSwitchCamera: PropTypes.func,
  /** callback function invoked when back navigation button is pressed */
  onBackNavigation: PropTypes.func,
  /** callback function invoked while performing re-capture of image */
  onRetake: PropTypes.func,
  /** callback function invoked on submitting captured image as argument */
  onSubmit: PropTypes.func,
  /** callback function invoked with error while accessing device */
  onDeviceError: PropTypes.func,
  /** callback function invoked in case of any generic error */
  onError: PropTypes.func,
  /** callback function invoked when a generic error or device error popup is closed */
  onErrorClose: PropTypes.func,
  /** callback function invoked when exit button is pressed on error popup */
  onExit: PropTypes.func,
  /** to be set while running unit / autmation tests which disables hardware interactions */
  isInTestMode: PropTypes.bool,
};

Camera.defaultProps = {
  isActive: false,
  width: 960,
  height: 720,
  context: Context.selfie,
  documentTitle: '',
  documentDescription: '',
  selfieTimer: 3,
  isDeviceErrorToBeShown: true,
  isErrorToBeShown: true,
  isConstraintsWarningToBeShown: true,
  isOrientationWarningToBeShown: true,
  isBackNavigationToBeShown: true,
  containerClassName: '',
  onStatusChange: undefined,
  onSwitchCamera: undefined,
  onBackNavigation: undefined,
  onRetake: undefined,
  onSubmit: undefined,
  onDeviceError: undefined,
  onError: undefined,
  onErrorClose: undefined,
  onExit: undefined,
  isInTestMode: false,
};

export default Camera;
