import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';

import styles from './Drawer.module.css';

interface Props {
  id: string;
  isOpen: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  headingText: string;
  subHeadingText?: string;
  closeButtonText: string;
  onCloseButtonClick?: () => void;
  containerClassName?: string;
  contentClassName?: string;
  children?: React.ReactNode;
}

function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const Drawer = ({
  id,
  isOpen = false,
  onOpen,
  onClose,
  headingText,
  subHeadingText,
  closeButtonText,
  onCloseButtonClick,
  containerClassName = '',
  contentClassName = '',
  children,
}: Props) => {
  const [domRef, setDomRef] = useState(document.createElement('div'));

  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    document.body.appendChild(domRef);
    setDomRef(domRef);
  }, []);

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen();
    }
    if (prevIsOpen && !isOpen && onClose) {
      onClose();
    }
  }, [prevIsOpen, isOpen]);

  useEffect(
    () => () => {
      if (domRef) {
        document.body.removeChild(domRef);
      }
    },
    [],
  );

  const handleClick = () => {
    if (onCloseButtonClick) {
      onCloseButtonClick();
      const bannerElement = document.getElementById('eKYCBannerLink');
      if (bannerElement) {
        bannerElement.focus();
      }
    }
  };

  let overlayClass = '';
  let containerClass = '';

  if (isOpen) {
    overlayClass = styles['drawer__overlay--visible'];
    containerClass = styles['drawer__container--open'];
  }
  if (prevIsOpen && !isOpen) {
    overlayClass = styles['drawer__overlay--hidden'];
    containerClass = styles['drawer__container--closed'];
  }

  return ReactDOM.createPortal(
    <>
      <div
        id={`${id}Overlay`}
        className={`${styles.drawer__overlay} ${overlayClass}`}
        aria-hidden="false"
        data-testid="drawerOverlay"
      />
      <div
        id={`${id}Container`}
        className={`${styles.drawer__container} ${containerClassName} ${containerClass}`}
        role="complementary"
        aria-hidden={!isOpen}
        data-testid="drawerContainer"
      >
        <div className={styles.heading__container}>
          <h1 className={styles.heading__text} data-testid="headingText">
            {headingText}
          </h1>
          {subHeadingText && (
            <div
              className={styles['sub-heading__text']}
              data-testid="subHeadingText"
            >
              {subHeadingText}
            </div>
          )}
        </div>
        <div className={styles.content__container}>
          <div className={`${styles.drawer__content} ${contentClassName}`}>
            {children}
          </div>
          <div className={styles['close-button__container']}>
            <button
              id={`${id}Button`}
              className={styles['close-button']}
              onClick={handleClick}
              title={closeButtonText}
              type="button"
            >
              <span className={styles['close-button__text']}>
                {closeButtonText}
              </span>
            </button>
          </div>
        </div>
      </div>
    </>,
    domRef,
  );
};

Drawer.propTypes = {
  /** unique identifier for the drawer */
  id: PropTypes.string.isRequired,
  /** determines whether to display the drawer or not */
  isOpen: PropTypes.bool,
  /** callback function invoked when drawer is open */
  onOpen: PropTypes.func,
  /** callback function invoked when drawer is closed */
  onClose: PropTypes.func,
  /** heading text */
  headingText: PropTypes.string,
  /** subheading text */
  subHeadingText: PropTypes.string,
  /** close button text */
  closeButtonText: PropTypes.string,
  /** callback function invoked when close button is clicked */
  onCloseButtonClick: PropTypes.func,
  /** class to override the default drawer container styles */
  containerClassName: PropTypes.string,
  /** class to override the default drawer content styles */
  contentClassName: PropTypes.string,
  /** the children to be rendered in the drawer */
  children: PropTypes.node,
};

export default Drawer;
