import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import {
  cleanSession,
  refreshSession,
  resetSessionState,
  setSessionExpiredError,
  setSessionDialogState,
  resetSessionDialogState,
} from './sessionSlice';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { DialogNotification } from '../Dialog';

const Session = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const expirationTime = useAppSelector(
    (state) => state.session.session.expirationTime,
  );
  const error = useAppSelector((state) => state.session.error);

  const [warnSessionExpiry, setWarnSessionExpiry] = useState(false);

  /* 
    This stores if the session has been expired
    Ref is required because the "cleanup" function of
    useEffect cannot see state variables 
  */
  const sessionExpired = useRef<boolean>();

  useEffect(() => {
    if (sessionExpired.current) {
      navigate('/', { replace: true });
    }
  }, [sessionExpired.current]);

  useEffect(
    () => () => {
      tearDown();
    },
    [],
  );

  /* 
    Required to separate due to async dispatch
    tearDown will check if we should dispatch expiry error on unmount or
    dispatch a reset of session state
  */
  const tearDown = async () => {
    await dispatch(cleanSession());
    if (sessionExpired.current) {
      dispatch(setSessionExpiredError());
    } else {
      dispatch(resetSessionState());
    }
  };

  useEffect(() => {
    const currTimeInSeconds = Math.round(new Date().getTime() / 1000);
    const expirationPeriod = (expirationTime - currTimeInSeconds) * 1000;
    sessionExpired.current = false;

    const warnSessionExpiryTimer = setTimeout(() => {
      setWarnSessionExpiry(true);
      dispatch(setSessionDialogState());
    }, expirationPeriod - 60 * 1000);

    const warnRedirectTimer = setTimeout(() => {
      setWarnSessionExpiry(false);
      sessionExpired.current = true;
    }, expirationPeriod);

    return () => {
      clearTimeout(warnSessionExpiryTimer);
      clearTimeout(warnRedirectTimer);
    };
  }, [expirationTime]);

  const handleWarnSessionExpiryConfirm = () => {
    setWarnSessionExpiry(false);
    dispatch(resetSessionDialogState());
    dispatch(refreshSession());
  };

  const handleErrorDialogConfirm = () => {
    navigate('/', { replace: true });
  };

  return (
    <>
      <DialogNotification
        open={warnSessionExpiry}
        messages={['Your session is about to expire in 1 minute.']}
        buttonAction={handleWarnSessionExpiryConfirm}
        buttonText="Ok"
        buttonIcon="fa-check"
      />
      <DialogNotification
        open={!isEmpty(error.message)}
        messages={[error.message]}
        buttonAction={handleErrorDialogConfirm}
        buttonText="Exit"
        buttonIcon="fa-check"
      />
      <Outlet />
    </>
  );
};

export default Session;
