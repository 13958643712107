import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useRefresh } from './useRefresh';
import { Banner } from '../common/components/Banner';

const App = () => {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [onFirstMount, setOnFirstMount] = useState(true);

  useRefresh(search);
  useEffect(() => {
    if (pathname !== '/') {
      navigate('/', { replace: true });
    }
    setOnFirstMount(false);
  }, []);

  return (
    <div>
      <Banner />
      {!onFirstMount && <Outlet />}
    </div>
  );
};
export default App;
