/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-filename-extension */
import { injectValidate } from '@omni/validator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import stylePropType from 'react-style-proptype';

// eslint-disable-next-line import/no-named-as-default
import { Field, Textarea } from '.';

export class TextareaField extends PureComponent {
  render() {
    const {
      id,
      label,
      hideLabel,
      mandatory,
      prefixMandatorySymbol,
      mode,
      note,
      notePosition,
      fieldStyle,
      errors,
      labelPosition,
      labelIcon,
      disabled,
      labelClassName,
      fieldClassName,
      fieldLabelClassName,
      fieldElementsClassName,
      ...props
    } = this.props;
    const hasError = !_.isEmpty(errors);
    const fieldProps = {
      id,
      label,
      hideLabel,
      mandatory,
      prefixMandatorySymbol,
      mode,
      hasError,
      disabled,
      labelPosition,
      labelIcon,
      style: fieldStyle,
      labelClassName,
      fieldClassName,
      fieldLabelClassName,
      fieldElementsClassName,
    };
    return (
      <Field {...fieldProps}>
        <Textarea
          id={id}
          setRef={(ref) => (this.input = ref)}
          mode={mode}
          mandatory={mandatory}
          note={note}
          notePosition={notePosition}
          disabled={disabled}
          errors={errors}
          aria-invalid={!!hasError}
          {...props}
        />
      </Field>
    );
  }
}

TextareaField.propTypes = {
  /** Component ID   */
  id: PropTypes.string.isRequired,
  /** Used to identify the purpose of the input element  */
  label: PropTypes.string.isRequired,
  /** hide label */
  hideLabel: PropTypes.bool,
  /** To make the component disabled */
  disabled: PropTypes.bool,
  /** set the field is mandatory or not */
  mandatory: PropTypes.bool,
  /** set mandatory '\*' symbol before or after field label  */
  prefixMandatorySymbol: PropTypes.bool,
  /**  Will decide the mode of the component ENTRY, EDIT or VIEW  */
  mode: PropTypes.string,
  /**  */
  note: PropTypes.string,
  /** Array of error messages   */
  errors: PropTypes.array,
  /** labelPosition can me one of top, inline, right and left */
  labelPosition: PropTypes.oneOf(['top', 'inline', 'right', 'left']),
  /** This dictates child components for TextareaField  */
  children: PropTypes.node,
  /** labelIcon in Field  */
  labelIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  /** Note Position */
  notePosition: PropTypes.oneOf(['inline', 'block']),
  /** optional classname for the label element */
  labelClassName: PropTypes.string,
  /** Field level CSS style */
  fieldStyle: stylePropType,
  /** optional classname for the field div */
  fieldClassName: PropTypes.string,
  /** optional classname for the field__label div */
  fieldLabelClassName: PropTypes.string,
  /** optional classname for the field__elements div */
  fieldElementsClassName: PropTypes.string,
};
TextareaField.displayName = 'TextareaField';
export default injectValidate(TextareaField);
