/* eslint-disable no-restricted-syntax */
import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  isRejected,
  isPending,
  isFulfilled,
} from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import {
  defaultGovtScanError,
  ErrorPayload,
  initialError,
} from '../../../types/errors';
import { isClientErrorStatus } from '../../../utils/helper';
import { API_ERRORS, ERROR_DEFAULT } from '../ScanDocument/constants';

export interface DocumentExtractRequest {
  type: string;
  imageBase64: string;
  countryCode: string;
}

export interface DocumentExtractResponse {
  type: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  documentNumber: string;
  documentExpiry: string;
  licenceCardNumber: string;
  licenceVersionNumber: string;
  residentialAddress: string;
  residentialCountry: string;
  gender: string;
  nationality: string;
  documentIssuedPlace: string;
}

export type NonExtractFields = {
  occupation: string;
  residentialCountry: string;
};

export interface DocumentSubmitRequest {
  details: DocumentExtractResponse & NonExtractFields;
  frontDocument: DocumentExtractRequest;
  backDocument?: DocumentExtractRequest;
}
export interface AddressListRequest {
  address: string;
  countryIso: string;
}

export const initialState: DocumentExtractResponse & {
  error: ErrorPayload;
  isOCVAddressSearchProcessing: boolean;
  ocvError: boolean;
  ocvConfidence: string;
} = {
  type: '',
  firstName: '',
  middleName: '',
  lastName: '',
  dateOfBirth: '',
  residentialAddress: '',
  documentNumber: '',
  documentExpiry: '',
  licenceCardNumber: '',
  licenceVersionNumber: '',
  gender: '',
  residentialCountry: '',
  nationality: '',
  documentIssuedPlace: '',
  error: initialError,
  isOCVAddressSearchProcessing: false,
  ocvError: false,
  ocvConfidence: '',
};

export const extractDocument = createAsyncThunk<
  any,
  DocumentExtractRequest,
  { rejectValue: ErrorPayload }
>('document/extract', async (reqBody, { rejectWithValue }) => {
  try {
    const image = reqBody.imageBase64;
    const formData = new FormData();
    const blob = await (await fetch(image)).blob();
    formData.append('imageBase64', blob);
    formData.append('type', reqBody.type);
    formData.append('countryCode', reqBody.countryCode);

    const response = await axios.post(`v1/document/extract`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return {
      data: response.data,
      expirationTime: parseInt(response.headers['expiration-time'], 10),
    };
  } catch (error: any) {
    if (
      error instanceof AxiosError &&
      typeof error?.response?.data === 'object'
    ) {
      return rejectWithValue({ ...error?.response?.data });
    }
    return rejectWithValue({
      code: ERROR_DEFAULT,
      message: API_ERRORS[ERROR_DEFAULT],
    });
  }
});
export const documentSubmit = createAsyncThunk<
  any,
  any,
  { rejectValue: ErrorPayload }
>('document/submit', async (reqBody, { rejectWithValue }) => {
  try {
    const formData = new FormData();
    const frontImage = reqBody.frontDocument.imageBase64;
    const frontBlob = await (await fetch(frontImage)).blob();

    formData.append('frontDocument', frontBlob);
    formData.append('frontImageType', reqBody.frontDocument.type);
    formData.append('frontCountryCode', reqBody.frontDocument.countryCode);
    formData.append('details', JSON.stringify(reqBody.details));

    let backImage;
    if ('backDocument' in reqBody && 'imageBase64' in reqBody.backDocument) {
      backImage = reqBody.backDocument.imageBase64;
      const backBlob = await (await fetch(backImage)).blob();
      formData.append('backImageType', reqBody.backDocument.type);
      formData.append('backCountryCode', reqBody.backDocument.countryCode);
      formData.append('backDocument', backBlob);
    }

    const response = await axios.post(`v1/document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return {
      data: response.data,
      expirationTime: parseInt(response.headers['expiration-time'], 10),
    };
  } catch (error) {
    if (
      error instanceof AxiosError &&
      isClientErrorStatus(error?.response?.status)
    ) {
      return rejectWithValue(error?.response?.data);
    }
    return rejectWithValue(defaultGovtScanError);
  }
});

export const ocvAddressList = createAsyncThunk<
  any,
  AddressListRequest,
  { rejectValue: ErrorPayload }
>('/address', async (reqBody, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `v1/address-search`,
      JSON.stringify(reqBody),
      {
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
        },
      },
    );
    return {
      data: response.data,
      expirationTime: parseInt(response.headers['expiration-time'], 10),
    };
  } catch (error: any) {
    if (
      error instanceof AxiosError &&
      typeof error?.response?.data === 'object'
    ) {
      return rejectWithValue({ ...error?.response?.data });
    }
    return rejectWithValue({
      code: ERROR_DEFAULT,
      message: API_ERRORS[ERROR_DEFAULT],
    });
  }
});

const isOcrThunkRejected = isRejected(extractDocument, documentSubmit);
const isOCVAddressThunkPending = isPending(ocvAddressList);
const isOCVAddressThunkFulfilled = isFulfilled(ocvAddressList);
const isOCVAddressThunkRejected = isRejected(ocvAddressList);

const verifyDetailsSlice = createSlice({
  name: 'verifyDetails',
  initialState,
  reducers: {
    resetOcrState: () => initialState,
    resetErrorState: (state) => {
      state.error = initialError;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        extractDocument.fulfilled,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          ...payload.data,
        }),
      )
      .addMatcher(isOcrThunkRejected, (state, action: any) => {
        state.error = action.payload;
      })
      .addMatcher(isOCVAddressThunkRejected, (state, action: any) => {
        state.ocvError = action.payload;
        state.isOCVAddressSearchProcessing = false;
        state.ocvConfidence = '';
      })
      .addMatcher(isOCVAddressThunkPending, (state) => {
        state.isOCVAddressSearchProcessing = true;
      })
      .addMatcher(isOCVAddressThunkFulfilled, (state, action: any) => {
        state.isOCVAddressSearchProcessing = false;
        state.ocvError = false;
        state.ocvConfidence = action.payload.data.ocvConfidence;
      });
  },
});

export const { resetErrorState, resetOcrState } = verifyDetailsSlice.actions;
export default verifyDetailsSlice.reducer;
