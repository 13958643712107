// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Standard Button */

.btn__src-common-components-Omni-Button-styles-module__ODhvl {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  min-height: 2.5em;
  line-height: 1.4em;
  -webkit-user-drag: none;
  background-color: #0072ac;
  color: #ffffff;
  border: 1px solid transparent;
  border-radius: 4px;
  font-weight: 600;
  padding: 0.75em 1.5em;
  transition: background-color 0.2s ease 0s, border-color 0.2s ease 0s,
    color 0.2s ease 0s;
  font-size: 1em;
  margin-bottom: 1.25em;
}

.btn__icon__src-common-components-Omni-Button-styles-module__wNv5u,
.btn__text__src-common-components-Omni-Button-styles-module__SHxd3 {
  vertical-align: unset !important;
}

.btn__icon__src-common-components-Omni-Button-styles-module__wNv5u + .btn__text__src-common-components-Omni-Button-styles-module__SHxd3,
.btn__text__src-common-components-Omni-Button-styles-module__SHxd3 + .btn__icon__src-common-components-Omni-Button-styles-module__wNv5u {
  margin-left: 0.357em;
}

.btn__icon__src-common-components-Omni-Button-styles-module__wNv5u {
  transform: translate3d(0px, 0.125em, 0px);
  margin: -0.125em 0px 0px;
  margin-right: 0.125em;
  font-size: 1.4em !important;
}

.btn__src-common-components-Omni-Button-styles-module__ODhvl:hover {
  color: #fff;
  outline: none;
  background-color: #0093d8;
}

.btn__src-common-components-Omni-Button-styles-module__ODhvl:focus {
  outline: none;
  background-color: #0093d8;
  box-shadow: 0 0 0 0.142em rgba(1, 125, 186, 0.25);
}

.btn__src-common-components-Omni-Button-styles-module__ODhvl[disabled] {
  cursor: not-allowed;
  color: #494949;
  background-color: #e0e0e0;
}

.btn__src-common-components-Omni-Button-styles-module__ODhvl[disabled]:hover,
.btn__src-common-components-Omni-Button-styles-module__ODhvl[disabled]:focus {
  box-shadow: none;
}

/* Submit Button */
.btn--style-submit__src-common-components-Omni-Button-styles-module__kF09j[disabled] {
  color: #fff;
  outline: none;
  cursor: none;
  background-color: #0093d8;
}

.btn--style-submit__src-common-components-Omni-Button-styles-module__kF09j[disabled]:hover {
  color: #fff;
  outline: none;
  background-color: #0093d8;
}

.btn--style-submit__src-common-components-Omni-Button-styles-module__kF09j[disabled]:focus {
  outline: none;
  background-color: #0093d8;
  box-shadow: 0 0 0 0.142em rgba(1, 125, 186, 0.25);
}

/* Primary Button */
.btn--style-primary__src-common-components-Omni-Button-styles-module__VCiqt {
  background: #fff;
  color: #0072ac;
  border: 1px solid #0072ac;
}

.btn--style-primary__src-common-components-Omni-Button-styles-module__VCiqt:hover .btn--style-primary__src-common-components-Omni-Button-styles-module__VCiqt:focus {
  background: #0093d8;
  color: #fff;
}

.btn--style-primary__src-common-components-Omni-Button-styles-module__VCiqt[disabled]:hover,
.btn--style-primary__src-common-components-Omni-Button-styles-module__VCiqt[disabled]:focus {
  background: #007dba;
  color: #fff;
  box-shadow: none;
}

/* Action Button */

.btn--style-action__src-common-components-Omni-Button-styles-module__xdnZX {
  color: #fff;
  background: #008a02;
  border-color: #007002;
}

.btn--style-action__src-common-components-Omni-Button-styles-module__xdnZX:hover {
  background: #007002;
}

.btn--style-action__src-common-components-Omni-Button-styles-module__xdnZX[disabled]:hover,
.btn--style-action__src-common-components-Omni-Button-styles-module__xdnZX[disabled]:focus {
  background: #008a02;
  border-color: #007002;
  color: #fff;
  box-shadow: none;
}

/* Warning Button */

.btn--style-warning__src-common-components-Omni-Button-styles-module__vlySj {
  color: #fff;
  background: #eb8c2d;
  border-color: #e27f1b;
}

.btn--style-warning__src-common-components-Omni-Button-styles-module__vlySj:hover {
  background: #e27f1b;
}

.btn--style-warning__src-common-components-Omni-Button-styles-module__vlySj[disabled]:hover,
.btn--style-warning__src-common-components-Omni-Button-styles-module__vlySj[disabled]:focus {
  background: #eb8c2d;
  border-color: #e27f1b;
  color: #fff;
  box-shadow: none;
}

/* Danger Button */

.btn--style-danger__src-common-components-Omni-Button-styles-module__qSIds {
  color: #fff;
  background: #c91b01;
  border-color: #b31c06;
}

.btn--style-danger__src-common-components-Omni-Button-styles-module__qSIds:hover {
  background: #b31c06;
}

.btn--style-danger__src-common-components-Omni-Button-styles-module__qSIds[disabled]:hover,
.btn--style-danger__src-common-components-Omni-Button-styles-module__qSIds[disabled]:focus {
  background: #c91b01;
  border-color: #b31c06;
  color: #fff;
  box-shadow: none;
}

/* Clear Button */

.btn--style-clear__src-common-components-Omni-Button-styles-module__w5Uwm {
  background: transparent;
  border-color: transparent;
  color: #007dba;
  padding: 0.5em;
  margin: 0;
}

.btn--style-clear__src-common-components-Omni-Button-styles-module__w5Uwm:hover,
.btn--style-clear__src-common-components-Omni-Button-styles-module__w5Uwm:focus {
  text-decoration: underline;
  background: transparent;
  border-color: transparent;
  color: #007dba;
  box-shadow: none;
}

.btn--style-clear__src-common-components-Omni-Button-styles-module__w5Uwm[disabled]:hover,
.btn--style-clear__src-common-components-Omni-Button-styles-module__w5Uwm[disabled]:focus {
  text-decoration: none;
}

/* Sizes */

.btn--size-large__src-common-components-Omni-Button-styles-module__qwWnu {
  font-size: 1.6rem;
}

/* Button Bar */

.btn-bar__src-common-components-Omni-Button-styles-module__JWHf_ {
  display: -ms-flexbox;
  display: flex;
  padding: 1em 0;
}

.btn-bar__src-common-components-Omni-Button-styles-module__JWHf_.right__src-common-components-Omni-Button-styles-module__A5H9k {
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.btn-bar__src-common-components-Omni-Button-styles-module__JWHf_.left__src-common-components-Omni-Button-styles-module__jerFC {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.btn-bar__src-common-components-Omni-Button-styles-module__JWHf_.center__src-common-components-Omni-Button-styles-module__QlWV1 {
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-bar__src-common-components-Omni-Button-styles-module__JWHf_ .btn__src-common-components-Omni-Button-styles-module__ODhvl:last-of-type {
  margin-right: 0;
}

/* Button Form Groups */

.btn--in-inlinegroup__src-common-components-Omni-Button-styles-module__dhxGn {
  display: inline-block;
}

.btn--in-stackedgroup__src-common-components-Omni-Button-styles-module___0s43 {
  display: block;
  margin: 0.714em 0.714em 0 0;
}

/* IE9 CSS HACK */

@media all and (min-width: 0\\0) and (min-resolution: 0.001dpcm) {
  .btn-bar__src-common-components-Omni-Button-styles-module__JWHf_ {
    display: block;
  }
  .btn-bar__src-common-components-Omni-Button-styles-module__JWHf_.right__src-common-components-Omni-Button-styles-module__A5H9k {
    text-align: right;
  }
  .btn-bar__src-common-components-Omni-Button-styles-module__JWHf_.left__src-common-components-Omni-Button-styles-module__jerFC {
    text-align: left;
  }
  .btn-bar__src-common-components-Omni-Button-styles-module__JWHf_.center__src-common-components-Omni-Button-styles-module__QlWV1 {
    text-align: center;
  }
}

@keyframes spin__src-common-components-Omni-Button-styles-module__Y0hem {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.svg__src-common-components-Omni-Button-styles-module__dJrX0 {
  animation: spin__src-common-components-Omni-Button-styles-module__Y0hem 1s linear infinite;
  transform-origin: center center 0px;
  width: 1.5em;
  height: 1.5em;
  margin: 0 0.5em -0.3em 0;
}

.transform__src-common-components-Omni-Button-styles-module__uidHD {
  transform-origin: center center 0px;
  transform: scale(0.9375);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": `btn__src-common-components-Omni-Button-styles-module__ODhvl`,
	"btn__icon": `btn__icon__src-common-components-Omni-Button-styles-module__wNv5u`,
	"btn__text": `btn__text__src-common-components-Omni-Button-styles-module__SHxd3`,
	"btn--style-submit": `btn--style-submit__src-common-components-Omni-Button-styles-module__kF09j`,
	"btn--style-primary": `btn--style-primary__src-common-components-Omni-Button-styles-module__VCiqt`,
	"btn--style-action": `btn--style-action__src-common-components-Omni-Button-styles-module__xdnZX`,
	"btn--style-warning": `btn--style-warning__src-common-components-Omni-Button-styles-module__vlySj`,
	"btn--style-danger": `btn--style-danger__src-common-components-Omni-Button-styles-module__qSIds`,
	"btn--style-clear": `btn--style-clear__src-common-components-Omni-Button-styles-module__w5Uwm`,
	"btn--size-large": `btn--size-large__src-common-components-Omni-Button-styles-module__qwWnu`,
	"btn-bar": `btn-bar__src-common-components-Omni-Button-styles-module__JWHf_`,
	"right": `right__src-common-components-Omni-Button-styles-module__A5H9k`,
	"left": `left__src-common-components-Omni-Button-styles-module__jerFC`,
	"center": `center__src-common-components-Omni-Button-styles-module__QlWV1`,
	"btn--in-inlinegroup": `btn--in-inlinegroup__src-common-components-Omni-Button-styles-module__dhxGn`,
	"btn--in-stackedgroup": `btn--in-stackedgroup__src-common-components-Omni-Button-styles-module___0s43`,
	"svg": `svg__src-common-components-Omni-Button-styles-module__dJrX0`,
	"spin": `spin__src-common-components-Omni-Button-styles-module__Y0hem`,
	"transform": `transform__src-common-components-Omni-Button-styles-module__uidHD`
};
module.exports = ___CSS_LOADER_EXPORT___;
