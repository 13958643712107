/* eslint-disable react/jsx-filename-extension */
import React, { useCallback } from 'react';

export default (WrappedComponent) => {
  const RefForward = ({ setRef, ...props }) => {
    const refForward = useCallback(
      (ref) => {
        if (setRef) {
          setRef(ref);
        }
      },
      [setRef],
    );

    return <WrappedComponent {...props} ref={refForward} />;
  };
  RefForward.displayName = WrappedComponent.displayName;
  RefForward.propTypes = WrappedComponent.propTypes;

  return RefForward;
};
