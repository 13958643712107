/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-filename-extension */
import { Field, RadioGroup } from '@omni/form';
import { injectValidate } from '@omni/validator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import stylePropType from 'react-style-proptype';

export class RadioField extends PureComponent {
  render() {
    const {
      id,
      label,
      legend,
      note,
      hideLabel,
      mandatory,
      prefixMandatorySymbol,
      mode,
      onChange,
      value,
      errors,
      disabled,
      fieldsetType,
      labelPosition,
      labelIcon,
      children,
      childrenRender,
      labelClassName,
      fieldStyle,
      fieldClassName,
      fieldLabelClassName,
      fieldElementsClassName,
    } = this.props;
    const hasError = !_.isEmpty(errors);
    const fieldProps = {
      id,
      label,
      hideLabel,
      mandatory,
      prefixMandatorySymbol,
      mode,
      hasError,
      disabled,
      labelPosition,
      labelIcon,
      labelClassName,
      style: fieldStyle,
      fieldClassName,
      fieldLabelClassName,
      fieldElementsClassName,
    };
    const inputProps = {
      id,
      label,
      legend,
      note,
      mode,
      value,
      errors,
      onChange,
      disabled,
      fieldsetType,
      children,
      'aria-invalid': !!hasError,
    };
    return (
      <Field {...fieldProps}>
        <RadioGroup {...inputProps} />
        {childrenRender && childrenRender()}
      </Field>
    );
  }
}

RadioField.propTypes = {
  /** Component ID  */
  id: PropTypes.string.isRequired,
  /** Used to identify the purpose of the input element  */
  label: PropTypes.string.isRequired,
  /** Hide Label */
  hideLabel: PropTypes.bool,
  /** Legend */
  legend: PropTypes.string,
  /** Note */
  note: PropTypes.string,
  /** set the field is mandatory or not  */
  mandatory: PropTypes.bool,
  /** Will decide the mode of the component ENTRY, EDIT or VIEW */
  mode: PropTypes.string,
  /** Values of the selected radio button  */
  value: PropTypes.string,
  /** Array of error messages   */
  errors: PropTypes.array,
  /** This dictates child components for RadioField  */
  children: PropTypes.node.isRequired,
  /** Custom function to make specific child render  */
  childrenRender: PropTypes.func,
  /** Trigger this callback function when there is a change in radio button status  */
  onChange: PropTypes.func,
  /** Will set the field disabled  */
  disabled: PropTypes.bool,
  /** set mandatory '\*' symbol before or after field label  */
  prefixMandatorySymbol: PropTypes.bool,
  /** To arrange a group of radio buttons in inlinegroup or stackedgroup */
  fieldsetType: PropTypes.oneOf(['stacked', 'inline']),
  /** labelPosition can me one of top, inline, right and left */
  labelPosition: PropTypes.oneOf(['top', 'inline', 'right', 'left']),
  /** labelIcon in Field  */
  labelIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  /** optional classname for the label element */
  labelClassName: PropTypes.string,
  /** Field level CSS style */
  fieldStyle: stylePropType,
  /** optional classname for the field div */
  fieldClassName: PropTypes.string,
  /** optional classname for the field__label div */
  fieldLabelClassName: PropTypes.string,
  /** optional classname for the field__elements div */
  fieldElementsClassName: PropTypes.string,
};

RadioField.displayName = 'RadioField';
// export default injectValidate(RadioField);
export default injectValidate(RadioField);
