/* eslint-disable no-restricted-syntax */
/* eslint-disable import/no-extraneous-dependencies */
import { isEmpty, isRegExp, trim } from 'lodash';
import moment from 'moment';

export const validateMandatory = (field: string, label: string) => () => {
  const trimmedField = trim(field);
  return isEmpty(trimmedField) ? `Please enter your ${label}.` : undefined;
};

export const validateVerifyDetailsMandatory =
  (field: string, label: string) => () => {
    const trimmedField = trim(field);
    return isEmpty(trimmedField) ? `${label} is mandatory.` : undefined;
  };

export const validateMiddleNameCheck =
  (field: string, isChecked: boolean) => () => {
    const trimmedField = trim(field);
    return isEmpty(trimmedField) && !isChecked
      ? 'Please select if you do not have a middle name.'
      : undefined;
  };

export const validateLicenceCardNumberMandatory =
  (field: string, label: string) => () => {
    const trimmedField = trim(field);
    return isEmpty(trimmedField) ? `${label} is mandatory.` : undefined;
  };
export const validatePattern =
  (field: string, label: string, pattern: RegExp) => () => {
    const trimmedField = trim(field);
    return !isEmpty(trimmedField) &&
      isRegExp(pattern) &&
      !pattern.test(trimmedField)
      ? `Please enter a valid ${label}.`
      : undefined;
  };

export const validateMandatoryDocumentSelect = (field: string) => () => {
  const trimmedField = trim(field);
  return isEmpty(trimmedField)
    ? `Please select your document type.`
    : undefined;
};

export const validateSubSelection = (field: string, label: string) => () => {
  const trimmedField = trim(field);
  return isEmpty(trimmedField) ? `${label} is mandatory.` : undefined;
};

export const validateDateExpiry = (field: string) => () => {
  const today = moment().startOf('day');
  const dateField = moment(field);
  return today.isAfter(dateField)
    ? `The identity document you are using has expired. Please check the expiry date or reselect your document.`
    : undefined;
};
export const validateDate = (field: string, label: string) => () => {
  const dateField = moment(field);
  return !moment(dateField).isValid()
    ? `${label} is not a valid date.`
    : undefined;
};

export const validateDateMandatory = (field: string[], label: string) => () => {
  const [day, month, year] = field.map((value) => value.trim());
  return !day || !month || !year ? `${label} is mandatory.` : undefined;
};

export const validateAge = (field: string, minAge: number) => () => {
  const dateField = moment(field);
  const age = moment().diff(dateField, 'years');
  return age < minAge
    ? `You must be over ${minAge} years old to complete the process.`
    : undefined;
};

// Accepts all characters when multi-byte characters are entered otherwise string format applies.
// The following special characters are not permitted: < >   ; -- (2 hyphens in a row) /*  */  (placed together or in a row)
export const validateMultiStringInput =
  (field: string, label: string) => () => {
    const trimmedField = trim(field);
    const notAllowedStrings = ['<', '>', ';', '--'];
    if (
      notAllowedStrings.some((str) => trimmedField.includes(str)) ||
      (trimmedField.includes('/*') && trimmedField.includes('*/'))
    ) {
      return `${label} contains invalid characters.`;
    }
    return undefined;
  };
