import { ThemeProvider } from '@omni/theme';
import React from 'react';
import 'font-awesome/css/font-awesome.css';
import '@omni/theme/lib/root.css';
import '@omni/theme/lib/reset.css';
import '@omni/theme/lib/myriad.css';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from './app/App';
import { store } from './app/store';
import { ScrollToTop } from './common/components/Scroll/Scroll';
import { Session } from './common/components/Session';
import reportWebVitals from './reportWebVitals';
import './index.module.css';
import { Confirm } from './screens/Confirm';
import { IdentityUpload } from './screens/IdentityUpload';
import { Landing } from './screens/Landing';
import { LivenessSelfie } from './screens/LivenessSelfie';
import { Terms } from './screens/Terms';

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ThemeProvider theme="external">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Landing />} />
            <Route element={<Session />}>
              <Route path="terms" element={<Terms />} />
              <Route path="identity-upload" element={<IdentityUpload />} />
              <Route path="selfie" element={<LivenessSelfie />} />
              <Route path="confirm" element={<Confirm />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </Provider>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
