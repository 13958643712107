/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/extensions */
/* eslint-disable react/prop-types */
/* eslint-disable no-redeclare */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles.module.css';

const Spinner = () => (
  <svg
    viewBox="0 0 108 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    baseProfile="full"
    className={styles.svg}
  >
    <circle
      vectorEffect="non-scaling-stroke"
      cx="54"
      cy="54"
      r="50"
      stroke="#F7F7F7"
      strokeWidth="3"
      className={styles.transform}
    />
    <path
      vectorEffect="non-scaling-stroke"
      d="M54 4C26.3858 4 4 26.3858 4 54C4 81.6142 26.3858 104 54 104C59.7362 103.891 68.5249 102.519 77.1193 98.3316"
      stroke="url(#path_ih2p3dzww)"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={styles.transform}
    />
    <defs>
      <radialGradient
        id="path_ih2p3dzww"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(54.6168 6.1856) rotate(26.9626) scale(73.2378 108.871)"
      >
        <stop stopColor="#00C6D7" />
        <stop offset="0.350656" stopColor="#037FBE" />
        <stop offset="0.665068" stopColor="#005682" />
        <stop offset="1" stopColor="#31B9FF" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export const Button = ({
  id,
  icon,
  iconPlacement,
  label,
  type,
  size,
  title,
  onClick,
  disabled,
  className,
  fieldsetType,
  isProcessing,
  ...props
}) => {
  const handleClick = () => {
    !disabled && onClick(id);
  };

  const buttonClass = classNames({
    [styles.btn]: true,
    [styles[`btn--style-${type}`]]: type,
    [styles[`btn--size-${size}`]]: size,
    [styles[`btn--in-${fieldsetType}group`]]: fieldsetType,
    [className]: className,
  });

  const iconDom =
    (isProcessing && <Spinner />) ||
    (icon && (
      <i className={`fa ${icon} ${styles.btn__icon}`} aria-hidden="true" />
    ));

  return (
    <button
      id={id}
      className={buttonClass}
      title={title || label}
      draggable={false}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      {iconPlacement === 'left' && iconDom}
      {label && <span className={styles.btn__text}>{label}</span>}
      {iconPlacement === 'right' && iconDom}
    </button>
  );
};

Button.propTypes = {
  /** `id` of the Button */
  id: PropTypes.string.isRequired,
  /** Icon to be shown */
  icon: PropTypes.string,
  /** Decides where the icon should be placed in the Button */
  iconPlacement: PropTypes.oneOf(['left', 'right']),
  /** This dictates what button will say  */
  label: PropTypes.string,
  /** Types available - clear, standard, primary, action warning danger. Based on each type the Button styles are rendered accordingly */
  type: PropTypes.oneOf([
    'standard',
    'primary',
    'action',
    'warning',
    'danger',
    'clear',
    'submit',
  ]),

  /** Two options to be given, normal/large. Normal and Large options for normal or large sized Buttons */
  size: PropTypes.oneOf(['normal', 'large']),
  /** the text to be displayed when the mouse hovers over the button */
  title: PropTypes.string,
  /** Method called on click of the Button */
  onClick: PropTypes.func.isRequired,
  /** `className` added for the Button */
  className: PropTypes.string,
  /**  To arrange a group of button components in inlinegroup or stackedgroup */
  fieldsetType: PropTypes.oneOf(['stacked', 'inline']),
  /** Spinner shown */
  isProcessing: PropTypes.bool,
};

Button.defaultProps = {
  size: 'normal',
  iconPlacement: 'left',
  isProcessing: false,
};

Button.displayName = 'Button';

export default Button;
