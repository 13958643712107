/* eslint-disable react/prop-types */
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './IdentityUpload.module.css';
import { resetCountryInstructionsState, user } from './identityUploadSlice';
import identity from './images/identity.svg';
import photo from './images/photo.svg';
import { ScanDocument } from './ScanDocument';
import { resetScanState } from './ScanDocument/scanDocumentSlice';
import { SelectDocumentForm } from './SelectDocumentForm';
import { resetDocumentSelectedState } from './SelectDocumentForm/selectDocumentFormSlice';
import { VerifyDetailsForm } from './VerifyDetailsForm';
import { resetOcrState } from './VerifyDetailsForm/verifyDetailsFormSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Status } from '../../common/components/Camera';
import DialogNotification from '../../common/components/Dialog/DialogNotification';
import { Drawer } from '../../common/components/Drawer';
import { Stepper, Step } from '../../common/components/Omni/Stepper';
import { Spinner } from '../../common/components/Spinner';

declare global {
  interface Window {
    isInTestMode: any;
  }
}

const IdentityUpload = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const firstName = useAppSelector((state) => state.identity.firstName);
  const ekycDocuments = useAppSelector((state) => state.identity.ekycDocuments);
  const error = useAppSelector((state) => state.identity.error);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isStepperShown, setIsStepperShown] = useState(true);
  const isProcessing = useAppSelector((state) => state.session.loading);
  const isOCVAddressSearchProcessing = useAppSelector(
    (state) => state.verifyDetails.isOCVAddressSearchProcessing,
  );

  useEffect(() => {
    dispatch(user());
    return () => {
      dispatch(resetOcrState());
      dispatch(resetScanState());
      dispatch(resetDocumentSelectedState());
      dispatch(resetCountryInstructionsState());
    };
  }, []);

  useEffect(() => {
    if (firstName) {
      setIsDrawerOpen(true);
    }
  }, [firstName]);

  const handleDrawer = (isOpen = false) => setIsDrawerOpen(isOpen);

  const handleContinue = () => {
    setActiveStep(activeStep + 1);
  };
  const handleStatusChange = (status: string) => {
    setIsStepperShown(status !== Status.focus);
  };

  const handleBackNavigation = () => {
    setActiveStep(activeStep - 1);
    setIsStepperShown(true);
  };
  const handleReselectDocument = () => {
    dispatch(resetDocumentSelectedState());
    setActiveStep(0);
  };

  const handleErrorDialogConfirm = () => {
    navigate('/', { replace: true });
  };

  const titles = ['Select Document', 'Confirm Scan', 'Verify Details'];

  // eslint-disable-next-line consistent-return
  const renderStep = (step: number) => {
    switch (step) {
      case 0:
        return (
          <SelectDocumentForm
            ekycDocuments={ekycDocuments}
            onContinue={handleContinue}
          />
        );
      case 1:
        return (
          <ScanDocument
            onContinue={handleContinue}
            onBackNavigation={handleBackNavigation}
            isInTestMode={window.isInTestMode}
            onStatusChange={handleStatusChange}
            isStepperShown={isStepperShown}
          />
        );
      case 2:
        return <VerifyDetailsForm onBackNavigation={handleReselectDocument} />;
      default:
        break;
    }
  };

  return (
    <>
      {isProcessing && !isOCVAddressSearchProcessing && (
        <Spinner
          title="Processing..."
          description={
            activeStep !== 0
              ? `We are checking your document. 
        It will only take a moment.`
              : ''
          }
        />
      )}
      {isStepperShown ? (
        <div aria-hidden={isDrawerOpen} className={styles.container}>
          <h1 className={styles.header}>
            <span>{titles[activeStep]}</span>
          </h1>
          <Stepper id="Stepper" activeStep={activeStep} label="stepper">
            <Step text="Select" ariaLabel="Select Document Step" />
            <Step text="Scan" ariaLabel="Scan Document Step" />
            <Step text="Verify" ariaLabel="Verify Document Step" />
          </Stepper>
        </div>
      ) : null}

      {!isDrawerOpen && renderStep(activeStep)}
      <Drawer
        id="countryBasedInstructions"
        isOpen={isDrawerOpen}
        onCloseButtonClick={() => handleDrawer(false)}
        headingText={`Welcome,\n${firstName}!`}
        subHeadingText="Here's the process overview."
        closeButtonText="Let's Begin"
        contentClassName={styles.drawer__content}
      >
        <div className={styles.drawer__container}>
          <div className={styles.identityinstructions__container}>
            <span>
              <img src={identity} alt="" />
            </span>
            <span className={styles.bullet}>1</span>
            <div className={styles.identitydocuments}>
              <span className={styles.identity__header}>
                Provide your ID Document
              </span>
              {ekycDocuments &&
                ekycDocuments.map(({ name }, index) => (
                  <div className={styles.documents} key={name}>
                    {index === ekycDocuments.length - 1 ? name : `${name}, or`}
                  </div>
                ))}
            </div>
          </div>
          <div className={styles.photo__container}>
            <span>
              <img src={photo} alt="" />
            </span>
            <div className={styles.bullet}>2</div>
            <div
              aria-label="Take a photo of yourself"
              className={styles.photo__header}
            >
              Take a photo
              <br />
              of yourself
            </div>
          </div>
        </div>
      </Drawer>
      <DialogNotification
        open={!isEmpty(error.message)}
        messages={[error.message]}
        buttonAction={handleErrorDialogConfirm}
        buttonText="Exit"
        buttonIcon="fa-check"
      />
    </>
  );
};

export default IdentityUpload;
