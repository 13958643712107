import { Button } from '@omni/button';
import { constants } from '@omni/form';
import { injectValidate } from '@omni/validator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import stylePropType from 'react-style-proptype';

import { Field } from '../Form';

import { AsyncAutoComplete } from '.';

export class AsyncAutoCompleteField extends PureComponent {
  render() {
    const {
      id,
      label,
      hideLabel,
      mandatory,
      prefixMandatorySymbol,
      mode,
      fieldStyle,
      errors,
      labelPosition,
      labelIcon,
      disabled,
      placeholder,
      onClick,
      icon,
      showSearchButton,
      searchButtonLabel,
      labelClassName,
      fieldClassName,
      fieldLabelClassName,
      fieldElementsClassName,
      handleBlur,
      ...props
    } = this.props;
    const hasError = !_.isEmpty(errors);
    const fieldProps = {
      id,
      label,
      hideLabel,
      mandatory,
      prefixMandatorySymbol,
      mode,
      hasError,
      disabled,
      labelPosition,
      labelIcon,
      style: fieldStyle,
      labelClassName,
      fieldClassName,
      fieldLabelClassName,
      fieldElementsClassName,
    };
    const isViewMode = mode === constants.VIEW;
    return (
      // eslint-disable-next-line react/jsx-filename-extension
      <Field {...fieldProps}>
        <AsyncAutoComplete
          {...props}
          id={id}
          mode={mode}
          disabled={disabled}
          placeholder={placeholder}
          errors={errors}
          aria-label={label}
          showSearchButton={showSearchButton}
          onBlur={handleBlur}
        />
        {!isViewMode && showSearchButton && (
          <Button
            style={{
              'padding-right': '45px',
              'padding-top': '5px',
              position: 'absolute',
              top: '0',
              right: '0',
            }}
            id={`${id}Search`}
            onClick={onClick}
            icon={icon}
            type="clear"
            aria-label={searchButtonLabel}
            title={searchButtonLabel}
          />
        )}
      </Field>
    );
  }
}

AsyncAutoCompleteField.propTypes = {
  /** Component ID  */
  id: PropTypes.string.isRequired,
  /** Used to identify the purpose of the input element */
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  /** set the field is mandatory or not */
  mandatory: PropTypes.bool,
  /** set mandatory '\*' symbol before or after field label */
  prefixMandatorySymbol: PropTypes.bool,
  /** Will decide the mode of the component ENTRY, EDIT or VIEW */
  mode: PropTypes.string,
  /** Array of error messages   */
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.array,
  /** Will set the field disabled     */
  disabled: PropTypes.bool,
  /** labelPosition can be one of top, inline, right and left */
  labelPosition: PropTypes.oneOf(['top', 'inline', 'right', 'left']),
  /** labelIcon in Field  */
  labelIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  /** Field level CSS style */
  fieldStyle: stylePropType,
  /** Trigger this callback function when there is a change in component */
  onChange: PropTypes.func.isRequired,
  /** pre selected value */
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  /** Option to be shown on auto complete */
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  /** place holder for selected value element */
  placeholder: PropTypes.string,
  /** dropdown options custom render method */
  optionRenderer: PropTypes.func,
  /** Custom function for render selected option */
  valueRenderer: PropTypes.func,
  /** callback function trigger on onclick  */
  onClick: PropTypes.func,
  /** search button icon   */
  icon: PropTypes.string,
  /** Show search button  */
  showSearchButton: PropTypes.bool,
  /** Search Button Label and Title Attribute */
  searchButtonLabel: PropTypes.string,
  /** optional classname for the label element */
  labelClassName: PropTypes.string,
  /** optional classname for the field div */
  fieldClassName: PropTypes.string,
  /** optional classname for the field__label div */
  fieldLabelClassName: PropTypes.string,
  /** optional classname for the field__elements div */
  fieldElementsClassName: PropTypes.string,
  // See react-select for more details of the props below:
  /** Custom method to filter whether an option should be displayed in the menu */
  filterOption: PropTypes.func,
  /** The default set of options to show before the user starts searching. When set to true, the results for loadOptions('') will be autoloaded. */
  defaultOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
  /** If cacheOptions is truthy, then the loaded data will be cached. The cache will remain until cacheOptions changes value. */
  // eslint-disable-next-line react/forbid-prop-types
  cacheOptions: PropTypes.any,
  /** Function that returns a promise, which is the set of options to be used once the promise resolves. */
  loadOptions: PropTypes.func,
  /** Will cause the select to be displayed in the loading state, even if the Async select is not currently waiting for loadOptions to resolve */
  isLoading: PropTypes.bool,
  /** Callback function to handle onBlur */
  handleBlur: PropTypes.func,
};

AsyncAutoCompleteField.defaultProps = {
  icon: 'fa-search',
};

AsyncAutoCompleteField.displayName = 'AsyncAutoCompleteField';
export default injectValidate(AsyncAutoCompleteField);
