/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-filename-extension */
import { injectValidate } from '@omni/validator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import stylePropType from 'react-style-proptype';

import { Field, Select } from '.';

export class SelectField extends PureComponent {
  render() {
    const {
      id,
      label,
      hideLabel,
      mandatory,
      prefixMandatorySymbol,
      mode,
      grouping,
      dataSource,
      note,
      notePosition,
      noteInViewMode,
      fieldStyle,
      errors,
      labelPosition,
      labelIcon,
      fieldsetType,
      disabled,
      labelClassName,
      fieldClassName,
      fieldLabelClassName,
      fieldElementsClassName,
      ...props
    } = this.props;
    const hasError = !_.isEmpty(errors);
    const fieldProps = {
      id,
      label,
      hideLabel,
      mandatory,
      prefixMandatorySymbol,
      mode,
      hasError,
      disabled,
      labelPosition,
      labelIcon,
      style: fieldStyle,
      labelClassName,
      fieldClassName,
      fieldLabelClassName,
      fieldElementsClassName,
    };
    return (
      <Field {...fieldProps}>
        <Select
          id={id}
          setRef={(ref) => (this.input = ref)}
          mode={mode}
          fieldsetType={fieldsetType}
          grouping={grouping}
          dataSource={dataSource}
          mandatory={mandatory}
          note={note}
          notePosition={notePosition}
          noteInViewMode={noteInViewMode}
          disabled={disabled}
          errors={errors}
          aria-invalid={!!hasError}
          {...props}
        />
      </Field>
    );
  }
}

SelectField.propTypes = {
  /** Component ID  */
  id: PropTypes.string.isRequired,
  /** Used to identify the purpose of the input element  */
  label: PropTypes.string.isRequired,
  /** hide label */
  hideLabel: PropTypes.bool,
  /** set the field is mandatory or not  */
  mandatory: PropTypes.bool,
  /**  set mandatory '\*' symbol before or after field label */
  prefixMandatorySymbol: PropTypes.bool,
  /** Will decide the mode of the component ENTRY, EDIT or VIEW  */
  mode: PropTypes.string,
  /** help to create option groups based on the datasource group.  */
  grouping: PropTypes.bool,
  /** List of objects based on this the component renders the dropdown options.  */
  dataSource: PropTypes.array,
  /** description about the input field,will show next to the input element  */
  note: PropTypes.string,
  /** Note Position */
  notePosition: PropTypes.oneOf(['inline', 'block']),
  /** Note in View Mode */
  noteInViewMode: PropTypes.bool,
  /** Array of error messages    */
  errors: PropTypes.array,
  /** labelPosition can me one of top, inline, right and left */
  labelPosition: PropTypes.oneOf(['top', 'inline', 'right', 'left']),
  /** To arrage a group of check boxes in inlinegroup or stackedgroup  */
  fieldsetType: PropTypes.oneOf(['stacked', 'inline']),
  /** This dictates child components for SelectField  */
  children: PropTypes.node,
  /** labelIcon in Field  */
  labelIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  /** Field level CSS style */
  fieldStyle: stylePropType,
  /** optional classname for the label element */
  labelClassName: PropTypes.string,
  /** optional classname for the field div */
  fieldClassName: PropTypes.string,
  /** optional classname for the field__label div */
  fieldLabelClassName: PropTypes.string,
  /** optional classname for the field__elements div */
  fieldElementsClassName: PropTypes.string,
};

SelectField.displayName = 'SelectField';
export default injectValidate(SelectField);
