// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Base Form Control Styling */

.form-control__src-common-components-Omni-Form-styles-external__z0Tir {
  background-color: #fff;
  border: 1px solid #cdcdcd;
  color: #484848;
  padding: 0 0.714em;
  height: 2.5em;
  font-family: 'myriad-pro', Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  vertical-align: middle;
  background-clip: padding-box;
  border-radius: 0.142em;
  box-shadow: none;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out;
}

.form-control__src-common-components-Omni-Form-styles-external__z0Tir:focus {
  border-color: #007dba;
  outline: 0;
  box-shadow: 0 0 0 0.142em rgba(1, 125, 186, 0.25);
}

.form-control__src-common-components-Omni-Form-styles-external__z0Tir[disabled] {
  border-color: #e6e6e6 !important;
  color: #a4a4a4 !important;
}

input.form-control__src-common-components-Omni-Form-styles-external__z0Tir[readonly] {
  border-color: transparent;
  padding: 0;
}

input.form-control__src-common-components-Omni-Form-styles-external__z0Tir[readonly]:focus {
  border-color: transparent;
  box-shadow: none;
}

input[type=text] {
  border: 1px solid #cdcdcd;
  font-family: 'myriad-pro', Helvetica, Arial, sans-serif;
  box-shadow: none !important;
  background-color: initial;
  border-radius: 4px;
  color: #494949;
  font-size: 16px;
  height: 44px;
  outline: none;
  padding: 0 0.5em 0 12px;
  transition: border-color .2s ease 0s,border-radius .2s ease 0s;
  width: 100%;
}

.form-control--has-error__src-common-components-Omni-Form-styles-external__Dsfxb {
  border-color: #c91b01 !important;
}

.form-control--has-error__src-common-components-Omni-Form-styles-external__Dsfxb:focus {
  box-shadow: 0 0 0 0.142em rgba(201, 27, 1, 0.25);
}

.form-control__src-common-components-Omni-Form-styles-external__z0Tir::-webkit-input-placeholder {
  font-style: normal;
  color: #a4a4a4;
}

.form-control__src-common-components-Omni-Form-styles-external__z0Tir:-moz-placeholder {
  font-style: normal;
  color: #a4a4a4;
}

.form-control__src-common-components-Omni-Form-styles-external__z0Tir::-moz-placeholder {
  font-style: normal;
  color: #a4a4a4;
}

.form-control__src-common-components-Omni-Form-styles-external__z0Tir:-ms-input-placeholder {
  font-style: normal;
  color: #a4a4a4;
}

/* Textarea */

textarea.form-control__src-common-components-Omni-Form-styles-external__z0Tir {
  padding: 0.714em;
  resize: none;
}

textarea.form-control__view__src-common-components-Omni-Form-styles-external__J4zqq {
  margin-top: 0.5em;
}

textarea.form-control__view__src-common-components-Omni-Form-styles-external__J4zqq + .label--in-labelgroup-inline__src-common-components-Omni-Form-styles-external__pDhpi {
  margin-top: 1.6em;
}

/* Radio & Checkboxes */

.form-control-radio__src-common-components-Omni-Form-styles-external__azWUH,
.form-control-checkbox__src-common-components-Omni-Form-styles-external__bKI3Z {
  margin: 0.3em;
}

.form-control-radio__src-common-components-Omni-Form-styles-external__azWUH:focus,
.form-control-checkbox__src-common-components-Omni-Form-styles-external__bKI3Z:focus {
  outline-color: #007dba;
}

/* Radio Button Element */

input[type='radio'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: #004165;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid #747678;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
}

input[type='radio']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #004165;
}

input[type='radio']:checked::before {
  transform: scale(1);
}

.radio-element__src-common-components-Omni-Form-styles-external__iE9MN {
  display: inline-block;
  vertical-align: middle;
  padding: 0.73em 0;
}

.radio-element__label__src-common-components-Omni-Form-styles-external__jlVrg {
  margin-left: 0.2em;
}

.radio-element--in-stackedgroup__src-common-components-Omni-Form-styles-external__ua4gH {
  display: block;
  margin: 1em 1em 0 0;
}

.radio-element--in-stackedgroup__src-common-components-Omni-Form-styles-external__ua4gH + .radio-element--in-stackedgroup__src-common-components-Omni-Form-styles-external__ua4gH {
  margin-top: 0;
}

.radio-element--in-inlinegroup__src-common-components-Omni-Form-styles-external__v4GLA {
  display: inline-block;
  margin: 1em 1em 0 0;
}

/* Checkbox Element */

.checkbox-element__src-common-components-Omni-Form-styles-external__WziWX {
  display: inline-block;
  vertical-align: middle;
  padding: 0.73em 0;
}

.checkbox-element__label__src-common-components-Omni-Form-styles-external__etdcZ {
  margin-left: 0.2em;
}

.checkbox-element--in-stackedgroup__src-common-components-Omni-Form-styles-external__c3QdU {
  display: block;
  margin: 1em 1em 0 0;
}

.checkbox-element--in-stackedgroup__src-common-components-Omni-Form-styles-external__c3QdU + .checkbox-element--in-stackedgroup__src-common-components-Omni-Form-styles-external__c3QdU {
  margin-top: 0;
}

.checkbox-element--in-inlinegroup__src-common-components-Omni-Form-styles-external__eCKgf {
  display: inline-block;
  margin: 1em 1em 0 0;
}

.checkbox-column-group__src-common-components-Omni-Form-styles-external__xf92j {
  margin-top: 0.5em;
  column-gap: 2em;
}

.checkbox-column-group__src-common-components-Omni-Form-styles-external__xf92j .checkbox-element--in-stackedgroup__src-common-components-Omni-Form-styles-external__c3QdU {
  vertical-align: top;
  page-break-inside: avoid;
  break-inside: avoid;
}

.checkbox-column-group__src-common-components-Omni-Form-styles-external__xf92j .checkbox-element--in-stackedgroup__src-common-components-Omni-Form-styles-external__c3QdU:first-child {
  margin-top: 0;
}

/* CURRENCY AMOUNT INPUT */

.amount-field__src-common-components-Omni-Form-styles-external__m1SRW {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  width: 100%;
  max-width: 400px;
}

.amount-field__ccy__src-common-components-Omni-Form-styles-external__pplng {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.4rem;
  line-height: 2.5em;
  padding: 0 0.714em;
  background: #007dba;
  color: #fff;
  border-radius: 0.142em 0 0 0.142em;
}

.amount-field__input__src-common-components-Omni-Form-styles-external__EJrUJ {
  width: 100%;
}

.amount-field__ccy__src-common-components-Omni-Form-styles-external__pplng + .amount-field__input__src-common-components-Omni-Form-styles-external__EJrUJ {
  padding-left: 3.9em;
}

.amount-field--is-disabled__src-common-components-Omni-Form-styles-external__s8V4U > .amount-field__ccy__src-common-components-Omni-Form-styles-external__pplng {
  background: #6fabd3;
}

.amount-field--in-labelgroup__src-common-components-Omni-Form-styles-external__ppu3w {
  vertical-align: top;
  margin: 0 1em 0 0;
  width: auto;
}

.amount-field--in-inlinegroup__src-common-components-Omni-Form-styles-external__qhk2C,
.amount-field--in-stackedgroup__src-common-components-Omni-Form-styles-external__m9ofO {
  margin: 1em 1em 0 0;
}

/* Custom Select Dropdown */

.custom-select__src-common-components-Omni-Form-styles-external__Q5gl0 {
  display: inline-block;
  position: relative;
  background: #fff;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 0.4em;
  z-index: 1;
  width: 100%;
  border-radius: .25em;
  font-size: 16px;
}

.custom-select__arrow__src-common-components-Omni-Form-styles-external__kyv7C {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  color: #747678;
  font-size: 1rem;
  text-align: center;
  float: right;
  height: 3.5em;
  line-height: 3.5em;
  width: 3.3em;
  border-radius: 0 0.2em 0.2em 0;
}

.custom-select--is-disabled__src-common-components-Omni-Form-styles-external__QWMKn > .custom-select__arrow__src-common-components-Omni-Form-styles-external__kyv7C {
  border-left-color: #e6e6e6 !important;
  color: #7ab1d7 !important;
}

.custom-select__select__src-common-components-Omni-Form-styles-external__TZ6Ws {
  margin: 0;
  width: 100%;
  padding: 0 2.857em 0 0.714em;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0.25em;
  font-size: 16px;
}

.custom-select__select__src-common-components-Omni-Form-styles-external__TZ6Ws::-ms-expand {
  display: none;
}

.custom-select__select__src-common-components-Omni-Form-styles-external__TZ6Ws::-ms-value {
  background: transparent;
  color: #484848;
}

.custom-select--in-labelgroup__src-common-components-Omni-Form-styles-external__lTHOZ {
  vertical-align: top;
  margin: 0 1em 0 0;
  width: auto;
}

.custom-select--in-inlinegroup__src-common-components-Omni-Form-styles-external__wqLjU,
.custom-select--in-stackedgroup__src-common-components-Omni-Form-styles-external__InZq8 {
  margin: 1em 1em 0 0;
}

.custom-select--in-inlinegroup__src-common-components-Omni-Form-styles-external__wqLjU {
  width: calc(100% - 1em);
}

/* Multiple Select */

.custom-select__select__src-common-components-Omni-Form-styles-external__TZ6Ws[multiple] {
  height: 100%;
  padding: 0.357em 0.714em;
}

.custom-select__select__src-common-components-Omni-Form-styles-external__TZ6Ws[multiple] + .custom-select__arrow__src-common-components-Omni-Form-styles-external__kyv7C {
  display: none;
}

/* Form Text & Notes */

.form-text__src-common-components-Omni-Form-styles-external__yan0Q {
  font-size: 1.4rem;
  vertical-align: middle;
  display: inline-block;
  padding: 0.608em 0;
}

.form-text--is-note__src-common-components-Omni-Form-styles-external__bIMGC {
  display: block;
  margin: 0.1em 0.714em 0 0;
}

.form-text--has-error__src-common-components-Omni-Form-styles-external__ThSBi {
  color: #c91b01 !important;
}

/* Labels */

.label__src-common-components-Omni-Form-styles-external__vvcTs {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 600;
  color: #004165;
}

.label--is-description__src-common-components-Omni-Form-styles-external__F84p5 {
  display: inline;
  font-weight: 400;
  color: #004165;
}

.label--is-disabled__src-common-components-Omni-Form-styles-external__COaxX {
  color: #a4a4a4 !important;
}

.label--has-error__src-common-components-Omni-Form-styles-external__GZIUY {
  color: #c91b01 !important;
}

.label__required__src-common-components-Omni-Form-styles-external__riGDA {
  font-size: 0.8rem;
  color: #007dba;
  vertical-align: top;
  display: inline-block;
  line-height: unset;
}

.label__required--has-error__src-common-components-Omni-Form-styles-external__OzfYa {
  color: #c91b01;
}

.label__required__src-common-components-Omni-Form-styles-external__riGDA + .label__src-common-components-Omni-Form-styles-external__vvcTs {
  margin-left: 0.2em;
}

.label__src-common-components-Omni-Form-styles-external__vvcTs + .label__required__src-common-components-Omni-Form-styles-external__riGDA {
  margin-left: 0.175em;
}

.label__icon__src-common-components-Omni-Form-styles-external__LRzCJ {
  color: #007dba;
  font-size: 1.4rem;
  margin-left: 0.357em;
  vertical-align: unset;
  line-height: 1;
}

.label__icon__src-common-components-Omni-Form-styles-external__LRzCJ:focus {
  outline: 1px solid #007dba;
}

/* Form Element Groups */

.label-group__src-common-components-Omni-Form-styles-external___U7Te {
  vertical-align: middle;
}

.inline-group__src-common-components-Omni-Form-styles-external__g_5M7,
.stacked-group__src-common-components-Omni-Form-styles-external__iXfkT {
  border: 0;
  padding: 0;
  margin: -1em 0 0 0;
}

.inline-group__src-common-components-Omni-Form-styles-external__g_5M7 + .inline-group__src-common-components-Omni-Form-styles-external__g_5M7,
.inline-group__src-common-components-Omni-Form-styles-external__g_5M7 + .stacked-group__src-common-components-Omni-Form-styles-external__iXfkT,
.inline-group__src-common-components-Omni-Form-styles-external__g_5M7 > .stacked-group__src-common-components-Omni-Form-styles-external__iXfkT,
.stacked-group__src-common-components-Omni-Form-styles-external__iXfkT + .inline-group__src-common-components-Omni-Form-styles-external__g_5M7,
.stacked-group__src-common-components-Omni-Form-styles-external__iXfkT + .stacked-group__src-common-components-Omni-Form-styles-external__iXfkT,
.stacked-group__src-common-components-Omni-Form-styles-external__iXfkT > .inline-group__src-common-components-Omni-Form-styles-external__g_5M7 {
  margin-top: 0;
}

.inline-group__src-common-components-Omni-Form-styles-external__g_5M7 > * {
  display: inline-block;
  vertical-align: top;
}

.stacked-group__src-common-components-Omni-Form-styles-external__iXfkT > * {
  display: block;
}

.legend--hidden__src-common-components-Omni-Form-styles-external__Knwvj {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

/* Form Controls in Groups */

.form-control--in-stackedgroup__src-common-components-Omni-Form-styles-external__BIFuQ,
.form-text--in-stackedgroup__src-common-components-Omni-Form-styles-external__YQ4x5 {
  display: block;
  margin: 0.714em 0.714em 0 0;
}

.form-text--in-stackedgroup__src-common-components-Omni-Form-styles-external__YQ4x5 + .form-text--in-stackedgroup__src-common-components-Omni-Form-styles-external__YQ4x5 {
  margin-top: 0;
}

.form-control--in-inlinegroup__src-common-components-Omni-Form-styles-external__WVouL,
.form-text--in-inlinegroup__src-common-components-Omni-Form-styles-external__Iswaj {
  display: inline-block;
  margin: 0.714em 0.714em 0 0;
}

.form-control--in-labelgroup__src-common-components-Omni-Form-styles-external__ja6AZ {
  vertical-align: top;
  margin: 0 0.714em 0 0;
}

/* Labels in Groups */

.label--in-labelgroup-inline__src-common-components-Omni-Form-styles-external__pDhpi {
  display: inline-block;
  vertical-align: middle;
  margin: 0.6em 0 0 0;
}

.label--in-labelgroup-block__src-common-components-Omni-Form-styles-external__Yvrvf {
  display: block;
  margin: 0.6em 0 0 0;
}

.label-group--in-inlinegroup__src-common-components-Omni-Form-styles-external__UHSk5 {
  display: inline-block;
  margin: 1em 1em 0 0;
}

.label-group--in-stackedgroup__src-common-components-Omni-Form-styles-external__dStaw {
  display: block;
  margin: 1em 1em 0 0;
}

/* Fields */

.field__src-common-components-Omni-Form-styles-external__gwLUQ {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -ms-flex: 0 1 100%;
  flex: 0 1 100%;
}

.field__label__src-common-components-Omni-Form-styles-external__Y1v5u,
.field__elements__src-common-components-Omni-Form-styles-external__kojTd {
  position: relative;
  white-space: normal;
  -ms-word-break: keep-all;
  -ms-word-wrap: normal;
  word-wrap: normal;
  word-break: keep-all;
  overflow-wrap: normal;
}

.field__label--hidden__src-common-components-Omni-Form-styles-external__V1FwI {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}

/* Left & Right Aligned Labels */
.field--style-right__src-common-components-Omni-Form-styles-external__AA8kv,
.field--style-left__src-common-components-Omni-Form-styles-external__SIiLf {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.field--style-right__src-common-components-Omni-Form-styles-external__AA8kv > .field__label__src-common-components-Omni-Form-styles-external__Y1v5u,
.field--style-left__src-common-components-Omni-Form-styles-external__SIiLf > .field__label__src-common-components-Omni-Form-styles-external__Y1v5u {
  -ms-flex: 0 1 25%;
  flex: 0 1 25%;
  min-width: 5em;
  margin: 2em;
  padding-top: 0.8em;
  vertical-align: top;
}

.field--style-right__src-common-components-Omni-Form-styles-external__AA8kv > .field__label__src-common-components-Omni-Form-styles-external__Y1v5u {
  text-align: right;
}

.field--style-left__src-common-components-Omni-Form-styles-external__SIiLf > .field__label__src-common-components-Omni-Form-styles-external__Y1v5u {
  text-align: left;
}

.field--style-right__src-common-components-Omni-Form-styles-external__AA8kv > .field__elements__src-common-components-Omni-Form-styles-external__kojTd,
.field--style-left__src-common-components-Omni-Form-styles-external__SIiLf > .field__elements__src-common-components-Omni-Form-styles-external__kojTd {
  -ms-flex: 1 1;
  flex: 1 1;
  margin: 2em;
  vertical-align: top;
}

/* Inline Labels */

.field--style-inline__src-common-components-Omni-Form-styles-external__KZxGL {
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0 0em 1.5em 0;
}

.field--style-inline__src-common-components-Omni-Form-styles-external__KZxGL > .field__label__src-common-components-Omni-Form-styles-external__Y1v5u {
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding-top: 0.8em;
}

.field--style-inline__src-common-components-Omni-Form-styles-external__KZxGL > .field__elements__src-common-components-Omni-Form-styles-external__kojTd {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.field--style-inline__src-common-components-Omni-Form-styles-external__KZxGL > .field__label__src-common-components-Omni-Form-styles-external__Y1v5u + .field__elements__src-common-components-Omni-Form-styles-external__kojTd {
  margin-left: 1.5em;
}

.field--style-inline__src-common-components-Omni-Form-styles-external__KZxGL > .field__label--hidden__src-common-components-Omni-Form-styles-external__V1FwI + .field__elements__src-common-components-Omni-Form-styles-external__kojTd {
  margin-left: 0 !important;
}

/* Top Aligned Labels */

.field--style-top__src-common-components-Omni-Form-styles-external__OOQ5x {
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 0em 1.5em 0;
}

.field--style-top__src-common-components-Omni-Form-styles-external__OOQ5x > .field__label__src-common-components-Omni-Form-styles-external__Y1v5u + .field__elements__src-common-components-Omni-Form-styles-external__kojTd {
  margin-top: 0.5em;
}

.field--style-top__src-common-components-Omni-Form-styles-external__OOQ5x > .field__label__src-common-components-Omni-Form-styles-external__Y1v5u + .field__elements__view__src-common-components-Omni-Form-styles-external__XEfgg {
  margin-top: -0.5em !important;
}

.field--style-top__src-common-components-Omni-Form-styles-external__OOQ5x > .field__label--hidden__src-common-components-Omni-Form-styles-external__V1FwI + .field__elements__src-common-components-Omni-Form-styles-external__kojTd,
.field--style-top__src-common-components-Omni-Form-styles-external__OOQ5x > .field__label--hidden__src-common-components-Omni-Form-styles-external__V1FwI + .field__elements__view__src-common-components-Omni-Form-styles-external__XEfgg {
  margin-top: 0 !important;
}

/* Form Notes */

.form-text__src-common-components-Omni-Form-styles-external__yan0Q + .label--in-labelgroup-inline__src-common-components-Omni-Form-styles-external__pDhpi {
  margin-top: 0 !important;
  margin-left: 0.357em;
}

.form-text__src-common-components-Omni-Form-styles-external__yan0Q + .label--in-labelgroup-block__src-common-components-Omni-Form-styles-external__Yvrvf {
  margin-top: -0.5em !important;
}

/* Global Overrides */

.container__content--global
  .row--global:last-child
  .field--style--global-top:last-child, .container__content--global
  .row--global:last-child
  .field--style--global-inline:last-child {
  padding-bottom: 0;
}

.container__content--global
  .row--global:last-child
  .field--style--global-right:first-child, .container__content--global
  .row--global:last-child
  .field--style--global-left:first-child {
  margin-top: -2em;
}

.container__content--global
  .row--global:last-child
  .field--style--global-right:last-child, .container__content--global
  .row--global:last-child
  .field--style--global-left:last-child {
  border-bottom: 0;
  margin-bottom: -2em;
}

/* IE9 CSS HACK */

@media all and (min-width: 0\\0) and (min-resolution: 0.001dpcm) {
  .field__src-common-components-Omni-Form-styles-external__gwLUQ {
    display: table;
    white-space: nowrap;
  }

  .field--style-right__src-common-components-Omni-Form-styles-external__AA8kv,
  .field--style-left__src-common-components-Omni-Form-styles-external__SIiLf {
    width: 100%;
  }

  .field--style-right__src-common-components-Omni-Form-styles-external__AA8kv > .field__label__src-common-components-Omni-Form-styles-external__Y1v5u,
  .field--style-left__src-common-components-Omni-Form-styles-external__SIiLf {
    display: table-cell;
    vertical-align: top;
    margin: 0;
    padding: 2em;
    width: 25%;
  }

  .field--style-right__src-common-components-Omni-Form-styles-external__AA8kv > .field__label__src-common-components-Omni-Form-styles-external__Y1v5u::after,
  .field--style-left__src-common-components-Omni-Form-styles-external__SIiLf {
    display: none;
  }

  .field--style-right__src-common-components-Omni-Form-styles-external__AA8kv > .field__elements__src-common-components-Omni-Form-styles-external__kojTd,
  .field--style-left__src-common-components-Omni-Form-styles-external__SIiLf {
    display: table-cell;
    vertical-align: top;
    margin: 0;
    padding: 2em;
  }

  .field--style-top__src-common-components-Omni-Form-styles-external__OOQ5x {
    width: 100%;
  }
  .field--style-inline__src-common-components-Omni-Form-styles-external__KZxGL > .field__label__src-common-components-Omni-Form-styles-external__Y1v5u {
    display: table-cell;
    vertical-align: top;
  }
  .field--style-inline__src-common-components-Omni-Form-styles-external__KZxGL > .field__elements__src-common-components-Omni-Form-styles-external__kojTd {
    display: table-cell;
    vertical-align: top;
  }
  .field--style-inline__src-common-components-Omni-Form-styles-external__KZxGL > .field__label__src-common-components-Omni-Form-styles-external__Y1v5u + .field__elements__src-common-components-Omni-Form-styles-external__kojTd {
    margin-left: 0;
    padding-left: 1.5em;
  }

  .custom-select__arrow__src-common-components-Omni-Form-styles-external__kyv7C {
    display: none;
  }
  .custom-select__select__src-common-components-Omni-Form-styles-external__TZ6Ws {
    padding: 0;
  }
}

/* toggle */
.omni-switch__src-common-components-Omni-Form-styles-external__LkOuC {
  position: relative;
  display: inline-block;
  width: 5em;
  height: 2.5em;
}

.omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD {
  opacity: 0;
  height: 0;
  width: 0;
}

.omni-switch__slider__src-common-components-Omni-Form-styles-external__J5hvq {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-color: #cdcdcd;
  border-radius: 2.5em;
  -webkit-transition: 0.25s ease-out;
  -moz-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}

.omni-switch__slider__src-common-components-Omni-Form-styles-external__J5hvq:before {
  position: absolute;
  content: '';
  height: 1.5em;
  width: 1.5em;
  left: 0.5em;
  bottom: 0.5em;
  background-color: #ffffff;
  border-radius: 50%;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
  box-shadow: 0px 1px 2px -1px rgba(0, 0, 0, 0.5);
}

.omni-switch__slider__src-common-components-Omni-Form-styles-external__J5hvq:hover,
.omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD:focus + .omni-switch__slider__src-common-components-Omni-Form-styles-external__J5hvq {
  background-color: #9e9e9e;
}

.omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD:checked + .omni-switch__slider__src-common-components-Omni-Form-styles-external__J5hvq {
  background-color: #24bf5a;
}

.omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD:checked + .omni-switch__slider__src-common-components-Omni-Form-styles-external__J5hvq:hover,
.omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD:checked:focus + .omni-switch__slider__src-common-components-Omni-Form-styles-external__J5hvq {
  background-color: #2f9c54;
}

.omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD:disabled + .omni-switch__slider__src-common-components-Omni-Form-styles-external__J5hvq {
  opacity: 0.5;
  cursor: default;
}

.omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD:disabled:focus,
.omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD:disabled + .omni-switch__slider__src-common-components-Omni-Form-styles-external__J5hvq:hover {
  background: #cdcdcd;
}

.omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD:checked:disabled:focus,
.omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD:checked:disabled + .omni-switch__slider__src-common-components-Omni-Form-styles-external__J5hvq:hover:hover {
  background: #24bf5a;
}

.omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD:checked + .omni-switch__slider__src-common-components-Omni-Form-styles-external__J5hvq:before {
  -webkit-transform: translateX(2.5em);
  -ms-transform: translateX(2.5em);
  transform: translateX(2.5em);
}

.omni-switch__label__src-common-components-Omni-Form-styles-external__EY8Ey {
  position: absolute;
  top: 0;
  font-size: 1.2em;
  font-weight: bold;
  color: #fff;
  line-height: 2em;
  opacity: 0;
  transition: opacity 0.25s ease-out 0s;
  cursor: pointer;
  pointer-events: none;
  user-select: none;
}

.omni-switch__label--on__src-common-components-Omni-Form-styles-external__yYE2f {
  left: 0.75em;
}

.omni-switch__label--off__src-common-components-Omni-Form-styles-external___a4Ds {
  right: 0.75em;
}

.omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD:checked ~ .omni-switch__label--on__src-common-components-Omni-Form-styles-external__yYE2f,
.omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD:not(:checked) ~ .omni-switch__label--off__src-common-components-Omni-Form-styles-external___a4Ds {
  opacity: 1;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-control": `form-control__src-common-components-Omni-Form-styles-external__z0Tir`,
	"form-control--has-error": `form-control--has-error__src-common-components-Omni-Form-styles-external__Dsfxb`,
	"form-control__view": `form-control__view__src-common-components-Omni-Form-styles-external__J4zqq`,
	"label--in-labelgroup-inline": `label--in-labelgroup-inline__src-common-components-Omni-Form-styles-external__pDhpi`,
	"form-control-radio": `form-control-radio__src-common-components-Omni-Form-styles-external__azWUH`,
	"form-control-checkbox": `form-control-checkbox__src-common-components-Omni-Form-styles-external__bKI3Z`,
	"radio-element": `radio-element__src-common-components-Omni-Form-styles-external__iE9MN`,
	"radio-element__label": `radio-element__label__src-common-components-Omni-Form-styles-external__jlVrg`,
	"radio-element--in-stackedgroup": `radio-element--in-stackedgroup__src-common-components-Omni-Form-styles-external__ua4gH`,
	"radio-element--in-inlinegroup": `radio-element--in-inlinegroup__src-common-components-Omni-Form-styles-external__v4GLA`,
	"checkbox-element": `checkbox-element__src-common-components-Omni-Form-styles-external__WziWX`,
	"checkbox-element__label": `checkbox-element__label__src-common-components-Omni-Form-styles-external__etdcZ`,
	"checkbox-element--in-stackedgroup": `checkbox-element--in-stackedgroup__src-common-components-Omni-Form-styles-external__c3QdU`,
	"checkbox-element--in-inlinegroup": `checkbox-element--in-inlinegroup__src-common-components-Omni-Form-styles-external__eCKgf`,
	"checkbox-column-group": `checkbox-column-group__src-common-components-Omni-Form-styles-external__xf92j`,
	"amount-field": `amount-field__src-common-components-Omni-Form-styles-external__m1SRW`,
	"amount-field__ccy": `amount-field__ccy__src-common-components-Omni-Form-styles-external__pplng`,
	"amount-field__input": `amount-field__input__src-common-components-Omni-Form-styles-external__EJrUJ`,
	"amount-field--is-disabled": `amount-field--is-disabled__src-common-components-Omni-Form-styles-external__s8V4U`,
	"amount-field--in-labelgroup": `amount-field--in-labelgroup__src-common-components-Omni-Form-styles-external__ppu3w`,
	"amount-field--in-inlinegroup": `amount-field--in-inlinegroup__src-common-components-Omni-Form-styles-external__qhk2C`,
	"amount-field--in-stackedgroup": `amount-field--in-stackedgroup__src-common-components-Omni-Form-styles-external__m9ofO`,
	"custom-select": `custom-select__src-common-components-Omni-Form-styles-external__Q5gl0`,
	"custom-select__arrow": `custom-select__arrow__src-common-components-Omni-Form-styles-external__kyv7C`,
	"custom-select--is-disabled": `custom-select--is-disabled__src-common-components-Omni-Form-styles-external__QWMKn`,
	"custom-select__select": `custom-select__select__src-common-components-Omni-Form-styles-external__TZ6Ws`,
	"custom-select--in-labelgroup": `custom-select--in-labelgroup__src-common-components-Omni-Form-styles-external__lTHOZ`,
	"custom-select--in-inlinegroup": `custom-select--in-inlinegroup__src-common-components-Omni-Form-styles-external__wqLjU`,
	"custom-select--in-stackedgroup": `custom-select--in-stackedgroup__src-common-components-Omni-Form-styles-external__InZq8`,
	"form-text": `form-text__src-common-components-Omni-Form-styles-external__yan0Q`,
	"form-text--is-note": `form-text--is-note__src-common-components-Omni-Form-styles-external__bIMGC`,
	"form-text--has-error": `form-text--has-error__src-common-components-Omni-Form-styles-external__ThSBi`,
	"label": `label__src-common-components-Omni-Form-styles-external__vvcTs`,
	"label--is-description": `label--is-description__src-common-components-Omni-Form-styles-external__F84p5`,
	"label--is-disabled": `label--is-disabled__src-common-components-Omni-Form-styles-external__COaxX`,
	"label--has-error": `label--has-error__src-common-components-Omni-Form-styles-external__GZIUY`,
	"label__required": `label__required__src-common-components-Omni-Form-styles-external__riGDA`,
	"label__required--has-error": `label__required--has-error__src-common-components-Omni-Form-styles-external__OzfYa`,
	"label__icon": `label__icon__src-common-components-Omni-Form-styles-external__LRzCJ`,
	"label-group": `label-group__src-common-components-Omni-Form-styles-external___U7Te`,
	"inline-group": `inline-group__src-common-components-Omni-Form-styles-external__g_5M7`,
	"stacked-group": `stacked-group__src-common-components-Omni-Form-styles-external__iXfkT`,
	"legend--hidden": `legend--hidden__src-common-components-Omni-Form-styles-external__Knwvj`,
	"form-control--in-stackedgroup": `form-control--in-stackedgroup__src-common-components-Omni-Form-styles-external__BIFuQ`,
	"form-text--in-stackedgroup": `form-text--in-stackedgroup__src-common-components-Omni-Form-styles-external__YQ4x5`,
	"form-control--in-inlinegroup": `form-control--in-inlinegroup__src-common-components-Omni-Form-styles-external__WVouL`,
	"form-text--in-inlinegroup": `form-text--in-inlinegroup__src-common-components-Omni-Form-styles-external__Iswaj`,
	"form-control--in-labelgroup": `form-control--in-labelgroup__src-common-components-Omni-Form-styles-external__ja6AZ`,
	"label--in-labelgroup-block": `label--in-labelgroup-block__src-common-components-Omni-Form-styles-external__Yvrvf`,
	"label-group--in-inlinegroup": `label-group--in-inlinegroup__src-common-components-Omni-Form-styles-external__UHSk5`,
	"label-group--in-stackedgroup": `label-group--in-stackedgroup__src-common-components-Omni-Form-styles-external__dStaw`,
	"field": `field__src-common-components-Omni-Form-styles-external__gwLUQ`,
	"field__label": `field__label__src-common-components-Omni-Form-styles-external__Y1v5u`,
	"field__elements": `field__elements__src-common-components-Omni-Form-styles-external__kojTd`,
	"field__label--hidden": `field__label--hidden__src-common-components-Omni-Form-styles-external__V1FwI`,
	"field--style-right": `field--style-right__src-common-components-Omni-Form-styles-external__AA8kv`,
	"field--style-left": `field--style-left__src-common-components-Omni-Form-styles-external__SIiLf`,
	"field--style-inline": `field--style-inline__src-common-components-Omni-Form-styles-external__KZxGL`,
	"field--style-top": `field--style-top__src-common-components-Omni-Form-styles-external__OOQ5x`,
	"field__elements__view": `field__elements__view__src-common-components-Omni-Form-styles-external__XEfgg`,
	"omni-switch": `omni-switch__src-common-components-Omni-Form-styles-external__LkOuC`,
	"omni-switch__input": `omni-switch__input__src-common-components-Omni-Form-styles-external__gm1OD`,
	"omni-switch__slider": `omni-switch__slider__src-common-components-Omni-Form-styles-external__J5hvq`,
	"omni-switch__label": `omni-switch__label__src-common-components-Omni-Form-styles-external__EY8Ey`,
	"omni-switch__label--on": `omni-switch__label--on__src-common-components-Omni-Form-styles-external__yYE2f`,
	"omni-switch__label--off": `omni-switch__label--off__src-common-components-Omni-Form-styles-external___a4Ds`
};
module.exports = ___CSS_LOADER_EXPORT___;
