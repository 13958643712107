import { FormValidator } from '@omni/validator';
import { isEmpty, mapValues } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { MAX_LENGTH_REFERENCE } from './constants';
import styles from './LandingForm.module.css';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import DialogNotification from '../../../common/components/Dialog/DialogNotification';
import { InputField } from '../../../common/components/InputField';
import { Button } from '../../../common/components/Omni/Button';
import {
  resetErrorState,
  startSession,
} from '../../../common/components/Session/sessionSlice';
import { patterns } from '../../../utils/validations/patterns';
import {
  validateMandatory,
  validatePattern,
} from '../../../utils/validations/validations';

const LandingForm = ({ validatorContext }: { validatorContext: any }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const error = useAppSelector((state) => state.session.error);
  const isProcessing = useAppSelector((state) => state.session.loading);
  const [isValidForm, setIsValidForm] = useState(true);
  const [form, setForm] = useState({ email: '', reference: '' });

  const handleChange = (name: string, value: string) => {
    if (
      name === 'reference' &&
      (value.length > MAX_LENGTH_REFERENCE || !patterns.digit.test(value))
    ) {
      return;
    }
    setForm({ ...form, ...{ [name]: value } });
  };

  const handleErrorDialogConfirm = () => {
    dispatch(resetErrorState());
  };

  const trimForm = () => mapValues(form, (field) => field.trim());

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { validate } = validatorContext;

    const trimmedForm = trimForm();
    if (!validate(trimmedForm)) {
      setIsValidForm(!isValidForm);
      return;
    }
    const resultAction = await dispatch(startSession(trimmedForm));
    if (startSession.fulfilled.match(resultAction)) {
      navigate('/terms', { replace: true });
    }
  };

  return (
    <>
      {!isValidForm && (
        <div
          className={styles.visually__hidden}
          aria-hidden="false"
          aria-label="Form has errors, fill required mandatory inputs correctly to proceed further"
          role="alert"
          aria-live="assertive"
        />
      )}
      <div className={styles.landingform__container}>
        <h1
          aria-label="E K Y C Identity Verification"
          className={styles.form__header}
        >
          <div aria-hidden="true">eKYC Identity</div>
          <div aria-hidden="true">Verification</div>
        </h1>
        <form action="" method="post" onSubmit={handleSubmit}>
          <span hidden aria-hidden id="prefixPlaceholderText">
            For an example
          </span>
          <InputField
            id="email"
            data-testid="Email"
            label="Email"
            aria-labelledby="prefixPlaceholderText"
            onChange={handleChange}
            placeholder="John.Smith@email.com"
            value={form.email}
            validate={[
              validateMandatory(form.email, 'Email Address'),
              validatePattern(form.email, 'Email Address', patterns.validEmail),
            ]}
          />
          <InputField
            id="reference"
            data-testid="Reference Number"
            label="Reference Number"
            aria-labelledby="prefixPlaceholderText"
            onChange={handleChange}
            maxLength={MAX_LENGTH_REFERENCE}
            placeholder="123456789"
            value={form.reference}
            validate={[validateMandatory(form.reference, 'Reference Number')]}
          />
          <div className={styles.tip}>
            <span>
              This can be found in your identity verification email from ANZ.
            </span>
          </div>
          <div className={styles.button__container}>
            <Button
              id="start"
              label="Start"
              aria-label="Start"
              onClick={() => {}}
              icon="fa-regular fa-lock fa-lg"
              iconPlacement="left"
              title="id"
              fieldsetType="inline"
              type="submit"
              size="normal"
              disabled={isProcessing}
              className=""
              style={{ width: '100%' }}
              isProcessing={isProcessing}
            />
          </div>
          <hr />
          <div className={styles.privacy}>
            Read ANZ{' '}
            <span data-testid="privacyLink">
              <a
                href="https://www.anz.com.au/privacy/centre"
                className={styles.privacy__link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{' '}
            </span>
          </div>
        </form>
      </div>
      <DialogNotification
        open={!isEmpty(error.message)}
        messages={[error.message]}
        buttonAction={handleErrorDialogConfirm}
        buttonText="Ok"
        buttonIcon="fa-check"
      />
    </>
  );
};

export default FormValidator(LandingForm);
