import { createSlice } from '@reduxjs/toolkit';

export interface Document {
  subSelection: string;
  documentName: string;
  documentType: string[];
}

const initialState: Document = {
  documentName: '',
  documentType: [],
  subSelection: '',
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    documentSelected(state, action) {
      return { ...action.payload };
    },

    documentSubSelectionSelected(state, action) {
      state.subSelection = action.payload.subSelection;
      state.documentType = action.payload.documentType;
    },

    resetDocumentSelectedState: () => initialState,
  },
});

export const {
  documentSelected,
  documentSubSelectionSelected,
  resetDocumentSelectedState,
} = documentSlice.actions;
export default documentSlice.reducer;
