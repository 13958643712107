import { createAsyncThunk, PayloadAction, createSlice } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

import { ErrorPayload, initialError } from '../../types/errors';
import { API_ERRORS, ERROR_DEFAULT } from '../LivenessSelfie/constants';

export interface LivenessResponse {
  reference: string;
  email: string;
  creditHeaderFailures: string[];
}

export const initialState: LivenessResponse & {
  error: ErrorPayload;
} = {
  reference: '',
  email: '',
  creditHeaderFailures: [],
  error: initialError,
};

export const livenessSubmit = createAsyncThunk<
  any,
  any,
  { rejectValue: ErrorPayload }
>('liveness/submit', async (reqBody, { rejectWithValue }) => {
  try {
    const image = reqBody.imageBase64;
    const formData = new FormData();
    const blob = await (await fetch(image)).blob();
    formData.append('imageBase64', blob);

    const response = await axios.post(`v1/liveness`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return {
      data: response.data,
      expirationTime: parseInt(response.headers['expiration-time'], 10),
    };
  } catch (error) {
    if (
      error instanceof AxiosError &&
      typeof error?.response?.data === 'object'
    ) {
      return rejectWithValue({ ...error?.response?.data });
    }
    return rejectWithValue({
      code: ERROR_DEFAULT,
      message: API_ERRORS[ERROR_DEFAULT],
    });
  }
});

const livenessSelfieSlice = createSlice({
  name: 'livenessSelfie',
  initialState,
  reducers: {
    resetLivenessState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        livenessSubmit.fulfilled,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          ...payload.data,
        }),
      )
      .addCase(livenessSubmit.rejected, (state, action: any) => {
        state.error = action.payload;
      });
  },
});

export const { resetLivenessState } = livenessSelfieSlice.actions;
export default livenessSelfieSlice.reducer;
